import {
  Address,
  beginCell,
  Cell,
  Dictionary,
  fromNano,
  Message,
  Slice,
  Transaction,
  TransactionDescriptionGeneric,
} from '@ton/core';
import { TonClient } from '@ton/ton';
import { TxType } from './tradingMeme.schema';
import { Meme } from './tradingMeme.getters';
import { Env, stage } from '../game/game.config';
import { Ticker } from '../../../data/TonProvider/types';
import { Administrator } from './tact_Administrator';
import { sha256_sync } from '@ton/crypto';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';

// Put them in order they were create and revert to show them latest to oldest
export const adminContractAddresses = [
  'EQD78QRViePVIBhd3Ma2HkfmfyzhJ57y1SbtO_tb_Lrw9p87',
  'EQD_GoAgmR_ZALZ30JXGM_jNiEAW7uCCnO-pynGgKuyYRXv7',
  'EQDZ6ABNEUVT9rtHn-hvHeW-lkyz50lR8GOHDAeLydNV_ZqV',
  'EQCK_yeGF7sG_gBHnW6Ja-HjZRoLc0tNo4_KJx6UWGR6LSWi',
  'EQDwVTiBsO4JVCxH5_6xoRReAZG0dSm_kdCS9zyULJPUmzQM',
  'EQAFMw6KPs0pBMRb-Ic7xld2qFxOIBVxy8NNNmaYij3WK7dW',
  'EQArcoIqXsntua5c0ZUyTzQP-jasXv7klYNUYvmPWcPsXNc1',
  'EQCm_9kyAJDdo4cEgGreg0vmOylcIB_W0LdLvAhSmHyMuLTe',
  'EQCXfLSZlSAO9gWkUKfgGWq_JThsx4vdmQlsqsFHF2wtF6MB',
  'EQBuSgPBTYjpUE6pGCocnfYcNH5Vaoa-733IJA51CX9L8_A7',
  'EQAI1jsvC-TnJ1PR7hRl5Xf-3ZzIyFMhRTv75ChTPDTsHOfE',
  'EQDAVc2DW2k8fmCTdT_Cku5sdwuGzxqlm4Ahp8p8C13oArqr',
  'EQBj_DpUcBT-EbU4NSUYqhIAHxzp6DzK__32o-eF6i2WPoxl',
  'EQByzH1UVOGgGiLOwkFpQ0d87OKcXvyad8wAfJHq5bISvHIu',
  'EQAeftGlPvuefq-sOamzWqF7-qK2inYMoMrFmPSTbTII_IUs',
  'EQC1NO_Nq0nQWqXX21Mx4PdfPKtlFqX81QiZ5AAajnMF4ia_',
  'EQBT6QiCrriVAehwSqiX0_bTpYxs317QJbphvma4fUIAphfI',
  'EQAJZeYk5nsyuKDHr0Wlg-QKzYdb1gAskP59xgOugR3OE-bp',
  'EQA8qICYLHTx4-bM-MnfrNItCr18SFiFdcu4SRdFWrI-U3Ye',
  'EQAZLXvYIy25932YARyGIpawDXFx1beIwzMQ7_UBHxy62kyM',
].reverse();

export type PickMetadaFromMeme = Pick<
  Meme,
  'name' | 'ticker' | 'id' | 'image'
> & {
  description: string;
};

export interface ContractMetadata {
  memeId: string;
  name: string;
  description: string;
  symbol: Ticker;
  code: string; // EnvCode
  decimals: string;
  image: string;
}

export enum EnvCode {
  PROD = 0,
  DEV = 1,
  // For internal env testing do negative
  ALPHA = -1,
  BRAVO = -2,
  CHARLIE = -3,
  LOCAL = -999,
}

const envToCode: Record<Env, EnvCode> = {
  prod: EnvCode.PROD,
  dev: EnvCode.DEV,
  alpha: EnvCode.ALPHA,
  bravo: EnvCode.BRAVO,
  charlie: EnvCode.CHARLIE,
  local: EnvCode.LOCAL,
};

export const getEnvCode = (forcedEnv?: Env): EnvCode => {
  return envToCode[forcedEnv ?? stage ?? 'local'];
};

export const getContractMetadataFromMeme = (
  meme: PickMetadaFromMeme,
  replicantEnv?: Env,
  withPrefix = true,
): ContractMetadata => {
  return {
    name: meme.name,
    description: meme.description,
    symbol: withPrefix ? `gemz_${meme.ticker}` : meme.ticker,
    memeId: meme.id,
    code: getEnvCode(replicantEnv).toString(),
    decimals: '0',
    image: meme.image,
  };
};

export const sha256 = (str: string) => {
  return sha256_sync(Buffer.from(str, 'utf-8'));
};

const toKey = (key: string) => {
  return BigInt(`0x${sha256(key).toString('hex')}`);
};

function bufferToChunks(buff: Buffer, chunkSize: number) {
  let chunks: Buffer[] = [];
  while (buff.byteLength > 0) {
    chunks.push(buff.slice(0, chunkSize));
    buff = buff.slice(chunkSize);
  }
  return chunks;
}

export const SNAKE_PREFIX = 0x00;
const ONCHAIN_CONTENT_PREFIX = 0x00;
const CELL_MAX_SIZE_BYTES = Math.floor((1023 - 8) / 8);

export function makeSnakeCell(data: Buffer) {
  // Create a cell that package the data
  let chunks = bufferToChunks(data, CELL_MAX_SIZE_BYTES);

  const b = chunks.reduceRight((curCell, chunk, index) => {
    if (index === 0) {
      curCell.storeInt(SNAKE_PREFIX, 8);
    }
    curCell.storeBuffer(chunk);
    if (index > 0) {
      const cell = curCell.endCell();
      return beginCell().storeRef(cell);
    } else {
      return curCell;
    }
  }, beginCell());
  return b.endCell();
}

export function buildOnchainMetadata(data: ContractMetadata): Cell {
  console.log('buildOnchainMetadata', { data });
  let dict = Dictionary.empty(
    Dictionary.Keys.BigUint(256),
    Dictionary.Values.Cell(),
  );

  // Store the on-chain metadata in the dictionary
  Object.entries(data).forEach(([key, value]) => {
    dict.set(toKey(key), makeSnakeCell(Buffer.from(value, 'utf8')));
  });

  return beginCell()
    .storeInt(ONCHAIN_CONTENT_PREFIX, 8)
    .storeDict(dict)
    .endCell();
}

export const getJettonContractAddressFromMetadata = async (
  tokenAddressSeed: {
    id: string;
    name: string;
    description: string;
    ticker: string;
    image: string;
  },
  forcedEnv?: Env,
) => {
  const client = new TonClient({
    endpoint: 'https://toncenter.com/api/v2/jsonRPC',
    apiKey: '170d64f612e0e84b147c309ffad9d973d5a37869614c212eb59a981302ba2c11',
  });

  // get admin contract
  const adminContractAddress = Address.parse(adminContractAddresses[0]);
  const adminContract = client.open(
    Administrator.fromAddress(adminContractAddress),
  );

  const metadata = getContractMetadataFromMeme(tokenAddressSeed, forcedEnv);
  const contractAddress = await adminContract.getGetJettonContractAddress(
    buildOnchainMetadata(metadata),
  );

  return Address.normalize(contractAddress);
};

interface Response {
  events: [
    {
      is_incomplete: boolean;
      trace_info?: {
        trace_state: 'complete' | 'incomplete';
      };
      transactions?: Record<
        string,
        {
          description?: {
            action?: {
              result_code?: number;
            };
          };
        }
      >;
    },
  ];
}

export function getTxResponseStatus(data: Response) {
  const traceEvent = data?.events?.[0];
  if (!traceEvent) {
    return { retry: true };
  }

  if (traceEvent.is_incomplete) {
    return { retry: true };
  }

  if (traceEvent.trace_info?.trace_state !== 'complete') {
    return { retry: true };
  }

  if (!traceEvent.transactions) {
    return { retry: true };
  }

  const failedAction = Object.values(traceEvent.transactions).find(
    (transaction) => {
      const resultCode = transaction.description?.action?.result_code ?? 0;
      return resultCode !== 0;
    },
  );

  return {
    success: !failedAction,
  };
}

function loadMintJetton(slice: Slice) {
  let sc_0 = slice;
  if (sc_0.loadUint(32) !== OP_MINT_JETTON) {
    throw Error('Invalid prefix');
  }
  let _queryId = sc_0.loadUintBig(64);
  let _amount = sc_0.loadIntBig(257);
  let _receiver = sc_0.loadAddress();
  return {
    $$type: 'MintJetton' as const,
    queryId: _queryId,
    amount: _amount,
    receiver: _receiver,
  };
}

function loadMintJettonPublic(slice: Slice) {
  let sc_0 = slice;
  if (sc_0.loadUint(32) !== OP_MINT_JETTON_PUBLIC) {
    throw Error('Invalid prefix');
  }
  let _queryId = sc_0.loadUintBig(64);
  let _amount = sc_0.loadIntBig(257);
  let _sender = sc_0.loadAddress();
  return {
    $$type: 'MintJettonPublic' as const,
    queryId: _queryId,
    amount: _amount,
    sender: _sender,
  };
}

function loadSellJettonPublic(slice: Slice) {
  let sc_0 = slice;
  if (sc_0.loadUint(32) !== OP_SELL_JETTON_PUBLIC) {
    throw Error('Invalid prefix');
  }
  let _queryId = sc_0.loadUintBig(64);
  let _amount = sc_0.loadIntBig(257);
  let _sender = sc_0.loadAddress();
  return {
    $$type: 'SellJettonPublic' as const,
    queryId: _queryId,
    amount: _amount,
    sender: _sender,
  };
}

function loadTokensBurnt(slice: Slice) {
  let sc_0 = slice;
  if (sc_0.loadUint(32) !== OP_TOKENS_BURNT) {
    throw Error('Invalid prefix');
  }
  let _queryId = sc_0.loadUintBig(64);
  let _amount = sc_0.loadCoins();
  let _tokenOwner = sc_0.loadAddress();
  return {
    $$type: 'TokensBurnt' as const,
    queryId: _queryId,
    amount: _amount,
    tokenOwner: _tokenOwner,
  };
}

const HTTP_RPC_URL = 'https://toncenter.com/api/v2/jsonRPC';
const TEMP_API_KEY =
  '170d64f612e0e84b147c309ffad9d973d5a37869614c212eb59a981302ba2c11';

const SIMPLE_MSG_WITHOUT_OP = 32;
const OP_SIMPLE_MSG_WITH_COMMENT = 0;
const OP_JETTON_TRANSFER_NOTIFICATION = 0x7362d09c;
const OP_NFT = 0x05138d91;
const OP_MINT_JETTON_PUBLIC = 2570012896; // Buy Token
const OP_MINT_JETTON = 2928867052; // Token deploy
const OP_SELL_JETTON_PUBLIC = 3871468253; // Sell Token
const OP_TOKENS_BURNT = 2401739733; // Tokens burnt
const OP_TOKEN_CLAIM = 2570012897; // Daily point converted to tokens or Graduation claim
const OP_TOKEN_CLAIM_NOTIFICATION = 1430958165; // only triggers after a graduation claim

const OP_STONFI_SWAP = 1717886506;
const OP_STONFI_BURN_NOTIF_EXT = 695482319;
const OP_STONFI_BURN_NOTIF = 116184359;
const OP_STONFI_PROVIDE_LP = 935368415;
const OP_JETTON_TRANSFER = 0x0f8a7ea5;
const OP_STONFI_PAY_TO = 0x657b54f5;
const OP_STONFI_PTON_TON_TRANSFER = 0x01f3835d;

/**
 * sell first tx is OP_JETTON_TRANSFER
 * buy first tx is OP_STONFI_PTON_TON_TRANSFER
 */

export enum TxStatus {
  Success = 'success',
  Failure = 'failure',
  Processing = 'processing',
}

export interface ParsedTxData {
  createdAt: number;
  coins: bigint; // @note: this is the total including fees and before refund (not really usable)
  ton: string;
  infoSrc: string;
  infoDest: string;
  tokenAddress: string;
  raw: Transaction;
  opCode?: number;
}

type Subset<T, U extends T> = U;

export type JettonTxData = {
  createdAt: number;
  tokenAddress: string;
  queryId: bigint;
  type: Subset<TxType, 'deploy' | 'buy' | 'sell'>;
  status: TxStatus;
  hash: string;
  logicalTime: string;
  tokenAmount: bigint;
  sender?: string;
  receiver?: string;
  owner?: string;
};

export type DexTxData = {
  createdAt: number;
  tokenAddress: string;
  queryId: bigint;
  type: Subset<
    TxType,
    'dexDeploy' | 'dexBuy' | 'dexSell' | 'pointClaim' | 'graduationClaim'
  >;
  status: TxStatus;
  hash: string;
  logicalTime: string;
  tokenAmount: bigint;
  tonAmount?: bigint;
  receiver?: string;
  buyer?: string;
  seller?: string;
  owner?: string;
};

export class TransactionWatcher {
  public client = new TonClient({
    endpoint: HTTP_RPC_URL,
    apiKey: TEMP_API_KEY,
  });

  get address() {
    return Address.parse(this.contractAddress);
  }

  get opts() {
    return {
      limit: 1000,
      inclusive: false,
      archival: false,
    };
  }

  constructor(private contractAddress: string, private isDex: boolean) {}

  run = async ({ toLogicalTime }: { toLogicalTime?: string }) => {
    const txs = await this.client.getTransactions(this.address, {
      ...this.opts,
      to_lt: toLogicalTime,
    });

    console.log({ txs, isDex: this.isDex });

    if (this.isDex) {
      const gradClaimQueryIds: Record<string, boolean> = {};
      let index = 0;
      const response: (ParsedTxData | DexTxData)[] = [];
      for (const tx of txs) {
        try {
          const dexTxs = await this.parseDexTx(tx, gradClaimQueryIds, index);
          console.log({ dexTxs });
          dexTxs.forEach((dexTx) => {
            response.push(dexTx);
          });
        } catch (error) {
          console.error(
            `Failed to parse dex tx. tx hash: ${
              tx.hash
            }, meme contract address: ${this.address.toRawString()}, error:`,
            error,
          );
        }
        index++;
      }

      return response;
    }

    return txs.map((tx) => {
      try {
        return this.parseJettonTx(tx);
      } catch (error) {
        console.error(
          `Failed to parse jetton tx. tx hash: ${
            tx.hash
          }, meme contract address: ${this.address.toRawString()}, error:`,
          error,
        );
      }
    });
  };

  private getTxStatus = (tx: Transaction): TxStatus => {
    // 1) If aborted => failed
    // 2) If there was a bounce phase => failed (usually means a rejected message)
    // 3) If computePhase is success and actionPhase is success => successful
    // 4) Otherwise => in process

    // Check for bounce or aborted
    const txDescription = tx.description as TransactionDescriptionGeneric;
    if (txDescription.aborted || txDescription.bouncePhase) {
      return TxStatus.Failure;
    }

    // Check for success in compute & action
    if (
      txDescription.computePhase &&
      txDescription.computePhase.type === 'vm' &&
      txDescription.computePhase.success &&
      txDescription.actionPhase &&
      txDescription.actionPhase.success
    ) {
      return TxStatus.Success;
    }

    // If we haven't explicitly concluded success or failure => "processing"
    return TxStatus.Processing;
  };

  private parseCoreTxData = (tx: Transaction) => {
    const { inMessage } = tx;
    if (inMessage?.info.type !== 'internal') {
      return undefined;
    }

    const coins = inMessage.info.value.coins;

    const data: ParsedTxData = {
      coins,
      ton: fromNano(coins),
      createdAt: inMessage.info.createdAt * 1000,
      tokenAddress: Address.parse(
        '0:' + tx.address.toString(16).padStart(64, '0'),
      ).toString({
        bounceable: true,
      }),
      raw: tx,
      // what are those??
      infoSrc: inMessage.info.src.toString({ bounceable: true }),
      infoDest: inMessage.info.dest.toString({ bounceable: true }),
    };

    return {
      data,
      originalBody: inMessage.body,
    };
  };

  private parseJettonTx = (
    tx: Transaction,
  ): ParsedTxData | JettonTxData | undefined => {
    const coreTxData = this.parseCoreTxData(tx);
    if (!coreTxData) {
      return;
    }

    const { data, originalBody } = coreTxData;
    const body = originalBody.beginParse().clone();
    if (!body) {
      return data;
    }

    // if body doesn't have opcode: it's a simple message without comment
    if (body.remainingBits < SIMPLE_MSG_WITHOUT_OP) {
      return data;
    }

    const op = body.loadUint(32);

    data.opCode = op;

    if (op === OP_SIMPLE_MSG_WITH_COMMENT) {
      return data;
    }
    if (op === OP_JETTON_TRANSFER_NOTIFICATION) {
      return data;
    }
    if (op === OP_NFT) {
      return data;
    }
    if (op === OP_TOKENS_BURNT) {
      return data;
    }
    if (op === OP_MINT_JETTON_PUBLIC) {
      const parsedBody = loadMintJettonPublic(originalBody.beginParse());
      const txOfInterest: JettonTxData = {
        ...data,
        hash: tx.hash().toString('hex'),
        logicalTime: tx.lt.toString(),
        status: this.getTxStatus(tx),
        type: 'buy',
        // sender: parsedBody.sender.toString({ bounceable: true }),
        sender: parsedBody.sender.toRawString(),
        queryId: parsedBody.queryId,
        tokenAmount: parsedBody.amount,
      };
      return txOfInterest;
    }
    if (op === OP_MINT_JETTON) {
      const parsedBody = loadMintJetton(originalBody.beginParse());
      const txOfInterest: JettonTxData = {
        ...data,
        hash: tx.hash().toString('hex'),
        logicalTime: tx.lt.toString(),
        status: this.getTxStatus(tx),
        type: 'deploy',
        // receiver: parsedBody.receiver.toString({ bounceable: true }),
        receiver: parsedBody.receiver.toRawString(),
        queryId: parsedBody.queryId,
        tokenAmount: parsedBody.amount,
      };
      return txOfInterest;
    }

    if (op === OP_SELL_JETTON_PUBLIC) {
      const parsedBody = loadSellJettonPublic(originalBody.beginParse());
      const txOfInterest: JettonTxData = {
        ...data,
        hash: tx.hash().toString('hex'),
        logicalTime: tx.lt.toString(),
        status: this.getTxStatus(tx),
        type: 'sell',
        // sender: parsedBody.sender.toString({ bounceable: true }),
        sender: parsedBody.sender.toRawString(),
        queryId: parsedBody.queryId,
        tokenAmount: parsedBody.amount,
      };
      return txOfInterest;
    }

    // @todo: does this need to be parse?
    // if (op === OP_TOKENS_BURNT) {
    //   const parsedBody = loadTokensBurnt(originalBody);
    //   const txOfInterest: JettonTxData = {
    //     ...data,
    //     parsedBody: parsedBody,
    //     hash: tx.hash().toString('hex'),
    //     logicalTime: tx.lt.toString(),
    //     status: this.getTxStatus(tx),
    //     type: 'burn',
    //     owner: parsedBody.tokenOwner.toString({ bounceable: true }),
    //     queryId: parsedBody.queryId,
    //     tokenAmount: parsedBody.amount,
    //   };
    //   return txOfInterest;
    // }
  };

  private parseDexTx = async (
    tx: Transaction,
    gradClaimQueryIds: Record<string, boolean>,
    index: number, // used for debugging
  ): Promise<(ParsedTxData | DexTxData)[]> => {
    const coreTxData = this.parseCoreTxData(tx);
    if (!coreTxData) {
      return [];
    }

    const { data, originalBody } = coreTxData;
    const body = originalBody.beginParse().clone();
    if (!body) {
      return [data];
    }

    // if body doesn't have opcode: it's a simple message without comment
    if (body.remainingBits < SIMPLE_MSG_WITHOUT_OP) {
      return [data];
    }

    // console.error('ALL OP CODES =', parseAllOpCodes(tx))
    const op = body.loadUint(32);
    data.opCode = op;

    if (op === OP_TOKEN_CLAIM_NOTIFICATION) {
      const queryId = body.loadUintBig(64);
      gradClaimQueryIds[queryId.toString()] = true;
      return [data];
    }

    if (op === OP_MINT_JETTON) {
      const parsedBody = loadMintJetton(originalBody.beginParse());
      return [
        {
          createdAt: coreTxData.data.createdAt,
          tokenAddress: coreTxData.data.tokenAddress,
          type: 'dexDeploy',
          status: this.getTxStatus(tx),
          hash: tx.hash().toString('hex'),
          logicalTime: tx.lt.toString(),
          queryId: parsedBody.queryId,
          tokenAmount: parsedBody.amount,
          tonAmount: BigInt(0),
          receiver: parsedBody.receiver.toRawString(),
        },
      ];
    }

    if (op === OP_TOKEN_CLAIM) {
      const queryId = body.loadUintBig(64);
      const tokenAmount = body.loadIntBig(257);
      const receiver = body.loadAddress();
      // determine if tx is a graduaction claim
      const txType = gradClaimQueryIds[queryId.toString()]
        ? 'graduationClaim'
        : 'pointClaim';

      return [
        {
          createdAt: coreTxData.data.createdAt,
          tokenAddress: coreTxData.data.tokenAddress,
          type: txType,
          status: this.getTxStatus(tx),
          hash: tx.hash().toString('hex'),
          logicalTime: tx.lt.toString(),
          queryId: queryId,
          tokenAmount,
          tonAmount: BigInt(0),
          receiver: receiver.toRawString(),
        },
      ];
    }

    if (op === OP_STONFI_SWAP) {
      // SEE STONFI SWAP EXAMPLE EOF
      const queryId = body.loadUintBig(64);
      const fromUser = body.loadAddress().toRawString();
      let tokenAmount = body.loadCoins();
      let tonAmount = body.loadCoins();

      const stonfiPayV2Op = findStonfiPayV2Op(tx);
      if (!stonfiPayV2Op) {
        throw new Error(`Failed to fetch StonfiPayV2 operation`);
      }

      const txStatus = this.getTxStatus(tx);
      const txHash = tx.hash().toString('hex');
      const logicalTime = tx.lt.toString();

      const createdAt = coreTxData.data.createdAt;
      const tokenAddress = coreTxData.data.tokenAddress;

      const userWallet = fromUser;
      if (tonAmount === BigInt(0)) {
        tonAmount = stonfiPayV2Op.tonAmount;
      } else {
        tokenAmount = stonfiPayV2Op.tokenAmount;
      }
      // @TODO: Make request with API
      const initialTxOpcode = await fetch(
        `https://preview.toncenter.com/api/v3/events?tx_hash=${txHash}`,
      ).then(async (res) => {
        const data = await res.json();
        const event = data?.events?.[0];
        if (!event) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // api has rate limiting of 1s
          return undefined;
        }
        const { transactions, transactions_order } = event;
        const [txKey] = transactions_order;
        const firstTx = transactions[txKey];
        const opcode = firstTx?.out_msgs?.[0]?.opcode;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // api has rate limiting of 1s
        return Number(opcode);
      });

      const isSell = initialTxOpcode === OP_JETTON_TRANSFER;
      const isBuy = initialTxOpcode === OP_STONFI_PTON_TON_TRANSFER;
      const isNeither = !isSell && !isBuy;

      console.log({ tx, initialTxOpcode, isSell, isBuy, isNeither });

      if (isNeither) {
        return [];
      }

      if (isBuy) {
        const buyTx: DexTxData = {
          createdAt,
          tokenAddress,
          queryId,
          type: 'dexBuy',
          status: txStatus,
          hash: txHash,
          logicalTime,
          tokenAmount,
          tonAmount,
          buyer: userWallet,
        };
        return [buyTx];
      }

      const sellTx: DexTxData = {
        createdAt,
        tokenAddress,
        queryId,
        type: 'dexSell',
        status: txStatus,
        hash: txHash,
        logicalTime,
        tokenAmount,
        tonAmount,
        seller: userWallet,
      };

      return [sellTx];
    }

    return [];
  };
}

function parseOpCodesFromCell(cell: Cell): number[] {
  const opcodes: number[] = [];
  const slice: Slice = cell.beginParse();

  try {
    // Read 32 bits as an unsigned integer
    if (slice.remainingBits >= 32) {
      const opcode = slice.loadUint(32);
      opcodes.push(opcode);
    }
  } catch (error) {
    console.error('Error while parsing opcodes:', error);
  }

  while (slice.remainingRefs > 0) {
    const nestedOpCodes = parseOpCodesFromCell(slice.loadRef());
    opcodes.push(...nestedOpCodes);
  }

  return opcodes;
}

function parseAllOpCodes(transaction: Transaction): number[] {
  const opcodes: number[] = [];

  // Parse opcodes from the raw Cell of the transaction
  const rawOpCodes = parseOpCodesFromCell(transaction.raw);
  opcodes.push(...rawOpCodes);

  // Parse opcodes from the inMessage, if present
  if (transaction.inMessage) {
    const inOpCodes = parseOpCodesFromCell(transaction.inMessage.body);
    opcodes.push(...inOpCodes);
  }

  // Parse opcodes from all outMessages
  for (const [key, message] of transaction.outMessages) {
    if (Object.prototype.hasOwnProperty.call(transaction.outMessages, key)) {
      const outOpCodes = parseOpCodesFromCell(message.body);
      opcodes.push(...outOpCodes);
    }
  }

  return opcodes;
}

const findStonfiPayV2Op = (tx: Transaction) => {
  // Inspect inMessage
  if (tx.inMessage) {
    if (tx.inMessage.body) {
      const stonfiPayV2Op = inspectBodyForStonfiPayV2(
        tx.inMessage.body.beginParse(),
      );
      if (stonfiPayV2Op) {
        return stonfiPayV2Op;
      }
    }
  }

  // Inspect outMessages
  if (tx.outMessages) {
    for (const [_key, message] of tx.outMessages) {
      const stonfiPayV2Op = inspectBodyForStonfiPayV2(
        message.body.beginParse(),
      );
      if (stonfiPayV2Op) {
        return stonfiPayV2Op;
      }
    }
  }
};

// Helper function to inspect a body recursively
const inspectBodyForStonfiPayV2 = (
  body: Slice,
):
  | {
      tokenAmount: bigint;
      tokenReceiverWallet: string;
      tonAmount: bigint;
      tonReceiverWallet: string;
    }
  | undefined => {
  const opCode = body.loadUint(32);
  if (opCode === 0x657b54f5) {
    // @format sample - Stonfi Pay To V2
    // query_id: 1735342077740
    // to_address: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
    // excesses_address: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
    // original_caller: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
    // exit_code: 3326308581
    // custom_payload: null
    // additional_info:
    //   fwd_ton_amount: "0"
    //   amount0_out: "8459"
    //   token0_address: 0:4057699d3fe63e193a7493ae9bdd15fe4830ec9351a1eaa66389b9be30aa91fd
    //   amount1_out: "0"
    //   token1_address: 0:f03b20e57b01b0f0c91e5e067fa0e98f4c8849225689c212667f87df20f2affb

    const _queryId = body.loadUintBig(64);
    const _toAddress = body.loadAddress();
    const _excessAddress = body.loadAddress();
    const _originalCaller = body.loadAddress();
    const _exitCode = body.loadUint(32);

    const _hasCustomPayload = body.loadBit();
    // const customPayload = hasCustomPayload ? body.loadRef().beginParse().loadBits(body.remainingBits).toString() : null;

    const additionalInfoRef = body.loadRef();
    const additionalInfo = additionalInfoRef.beginParse();
    const _fwdTonAmount = additionalInfo.loadCoins();

    return {
      tokenAmount: additionalInfo.loadCoins(),
      tokenReceiverWallet: additionalInfo.loadAddress().toRawString(),
      tonAmount: additionalInfo.loadCoins(),
      tonReceiverWallet: additionalInfo.loadAddress().toRawString(),
    };
  }

  while (body.remainingRefs > 0) {
    const ref = body.loadRef();
    const stonfiPayV2Op = inspectBodyForStonfiPayV2(ref.beginParse());
    if (stonfiPayV2Op) {
      return stonfiPayV2Op;
    }
  }
};

// STONFI SWAP EXAMPLE
// @tx sample at
// https://tonviewer.com/transaction/5a1eb579e8b191a9ce61fc64eae7f0f1dbd1c9b8c08daa257204e0fdf0bc9441
// on meme contract address
// - hex: 0:c264234c697cd9d7e71f175f64ebefc9e99186ccd54f34f8b8d3171c468fb858
// - friendly: EQDCZCNMaXzZ1-cfF19k6-_J6ZGGzNVPNPi40xccRo-4WAH8

// This transaction body contains 2 operations of interest:
// - Stonfi Swap V2
// - Stonfi Pay To V2

// @format sample - Stonfi Swap V2
// query_id: 1735342077740
// from_user: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
// left_amount: "0"
// right_amount: "100000000"
// dex_payload:
//   transferred_op: 1717886506
//   token_wallet1: 0:4057699d3fe63e193a7493ae9bdd15fe4830ec9351a1eaa66389b9be30aa91fd
//   refund_address: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
//   excesses_address: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
//   tx_deadline: 1735342977
//   swap_body:
//     min_out: "1"
//     receiver: 0:ae6049563a9374ca1f98fef2ef922247837725e8b207da0cb4e89e3e015ba72e
//     fwd_gas: "0"
//     custom_payload: null
//     refund_fwd_gas: "0"
//     refund_payload: null
//     ref_fee: 10
//     ref_address: ""
