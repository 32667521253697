import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/Controllers/AppController';
import { largeNumberToLetter } from '../../../../replicant/utils/numbers';
import './ProfileItem.scss';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import { HP } from '../../../../replicant/lib/HighPrecision';
import { WalletJetton } from '../../../../replicant/features/tradingMeme/types';
import { ElementUIState } from '../../../../data/Controllers/UIController/UITypes';
import { getJettonBalance } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';

interface Props {
  swappableToken: WalletJetton;
}

// =================================================================
// #region Item

export const ProfileSwappableToken = ({ swappableToken }: Props) => {
  const { t } = useTranslation();

  // @note: it is not possible to determine when a swap was successfully finalized at the moment
  // useAppUpdates({
  //   id: 'ProfileItemPoints/Claim',
  //   listener: app.memes.trading.attachEventListener(
  //     MemesEvents.TokenClaimUpdate,
  //   ),
  // });

  if (!app.views.ProfilePage.visible) {
    return null;
  }

  const onTapSwap = async (event: any) => {
    // prevent bubbling to item
    event.preventDefault();
    event.stopPropagation();

    app.memes.trading.initiateSwap(swappableToken);
  };

  const { jetton } = swappableToken;
  const tokenAmount = getJettonBalance(swappableToken).toNumber();

  // =================================================================
  // #region Base

  return (
    <div className={`btn item-profile ${ElementUIState.Normal}`}>
      <div className="item-profile-container">
        <MemeImage src={jetton.image} size={48} />

        <div className="item-profile-content">
          <div className="left">
            {/* token name */}
            <div className="name-container">{jetton.name}</div>

            {/* token market cap */}
            <div className="owned-container">
              {largeNumberToLetter(tokenAmount, 3)} {`$${jetton.symbol}`}
            </div>
          </div>

          <div className="right">
            {/* claim button  - 'Claim' caption */}
            <div
              className={`btn btn-normal button-claim ${
                true ? '' : 'disabled'
              }`}
              onClick={onTapSwap}
            >
              {t('player_profile_swap_token')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
