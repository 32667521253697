import './DrawerTradingTransactionConfirm.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TradingTokenFooterFeeMessage } from '../TradingTokenFooter/components/TTFFeeMessage';
import { TradingTokenFooterButton } from '../TradingTokenFooter/components/TTFBuySellBtn';
import { useState, useEffect } from 'react';
import {
  getCurrencyIcon,
  getCurrencyName,
  getFormattedTxAmount,
} from '../TradingTokenFooter/helpers';

const TransactionRow = (opts: {
  title?: string;
  icon?: string;
  value: string;
}) => {
  const { title, icon, value } = opts;
  return (
    <div className="row">
      <div className="left">{title}</div>
      <div className="right">
        {icon && <img className="icon" src={icon} />}
        <div
          className="value"
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      </div>
    </div>
  );
};

export const DrawerTradingTransactionConfirm = () => {
  const { t } = useTranslation();
  const { trading, currentMeme } = app.memes;
  const offchainToken = currentMeme.meme;
  const { isNonGemzSwap, swappableToken } = trading;
  const [txSummary, setTxSummary] = useState<Awaited<
    ReturnType<typeof trading.getTxSummary>
  > | null>(null);

  useEffect(() => {
    const loadTxSummary = async () => {
      const summary = await trading.getTxSummary();
      setTxSummary(summary);
    };
    loadTxSummary();
  }, []);

  if (!txSummary || (!isNonGemzSwap && !offchainToken)) {
    // @TODO: If this happens we actually have to close the drawer
    return null;
  }

  const { shareOfTheSupply, sending, receiving, txType } = txSummary;

  const memeName = isNonGemzSwap
    ? swappableToken.jetton.name
    : offchainToken!.name;

  // const isBuying = txType === 'buy';

  const sendRowCurrencyName = getCurrencyName(
    trading.txCurrency,
    trading.txMemeTicker,
    txType,
    'send',
  );
  const sendRowCurrencyIcon = getCurrencyIcon(
    trading.txCurrency,
    trading.txMemeImage,
    txType,
    'send',
  );

  const sendRowValue = getFormattedTxAmount(
    Number(sending),
    txType,
    trading.txCurrency,
    'send',
  );

  const sendRow = {
    title: t('trading_transaction_confirm_send', {
      tokenName: sendRowCurrencyName,
    }),
    icon: sendRowCurrencyIcon,
    value: sendRowValue,
  };

  const receiveRowCurrencyName = getCurrencyName(
    trading.txCurrency,
    trading.txMemeTicker,
    txType,
    'receive',
  );
  const receiveRowCurrencyIcon = getCurrencyIcon(
    trading.txCurrency,
    trading.txMemeImage,
    txType,
    'receive',
  );

  const receiveRowValue = getFormattedTxAmount(
    Number(receiving),
    txType,
    trading.txCurrency,
    'receive',
  );

  const receiveRow = {
    title: t('trading_transaction_confirm_receive', {
      tokenName: receiveRowCurrencyName,
    }),
    icon: receiveRowCurrencyIcon,
    value: receiveRowValue,
  };

  return (
    <div className="drawer-trading-transaction-confirm">
      <div className="drawer-trading-transaction-confirm-header">
        <div className="title">{t('trade_token_confirm_transaction')}</div>

        <div className="rows">
          <TransactionRow title={'Name'} icon={undefined} value={memeName} />
          <TransactionRow
            title={sendRow.title}
            icon={sendRow.icon}
            value={sendRow.value}
          />
          <TransactionRow
            title={receiveRow.title}
            icon={receiveRow.icon}
            value={receiveRow.value}
          />
          <TransactionRow
            title={t('trading_transaction_share_of_supply')}
            icon={undefined}
            value={shareOfTheSupply}
          />
        </div>

        <div className="bottom">
          <TradingTokenFooterFeeMessage tx={app.memes.trading.tx} />

          <TradingTokenFooterButton
            footerMode={'normal'}
            txType={txType}
            currencyType={trading.txCurrency}
            onClick={() => {
              // submitTransaction takes a while, so lets close the drawer first for a more fluid experience
              app.ui.drawer.close();

              // show transaction processing drawer
              app.ui.drawer.show({
                id: 'drawerTradingTransactionProcessing',
                props: { meme: offchainToken },
                // hideClose: true,
              });

              trading.submitTransaction();
              // wait for confirm drawer to close and processing drawer to open
              // setTimeout(() => {
              //   // execute buy/sell action
              //   trading.submitTransaction();
              // }, 700);
            }}
          />
        </div>
      </div>
    </div>
  );
};
