import './FeedMemeBottomUI.scss';
import { useState, useEffect } from 'react';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';
import { useManyAppUpdates } from '../../../../data/hooks';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { displayFormattedMarketCapValue } from '../../../../replicant/utils/numbers';
import { FeedMemeFooter } from './FeedMemeFooter';
import { SidebarUI } from './FeedMemeSidebar';
import { t } from 'i18next';
import { ProgressBar } from '../../../shared/ProgressBar/ProgressBar';
import { HP } from '../../../../replicant/lib/HighPrecision';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import { getDexGraduationPct } from '../../../../data/memeUtils';
import { TiktokItemTransactionBubbles } from '../TiktokItemTransactionBubbles/TiktokItemTransactionBubbles';

interface FeedMemeBottomUIProps {
  token: TradingTokenListing;
  uiAlpha: number;
  selected: boolean;
  slideIndex: number;
  canShowTapGame: boolean;
}

export const FeedMemeBottomUI = ({
  token,
  uiAlpha,
  selected,
}: FeedMemeBottomUIProps) => {
  useManyAppUpdates({
    id: 'FeedMemeBottomUI',
    events: [
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingTap),
        dep: selected,
      },
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnPointsUpdate),
        dep: selected,
      },
    ],
  });

  const { offchainTokenId } = token;

  // const holderCount = app.memes.currentMeme.meme?.holderCount || 0;

  const [meme, setMeme] = useState(app.memes.currentMeme.meme);
  const [dexProgress, setDexProgress] = useState(0);
  const [dexProgressColor, setDexProgressColor] = useState('green');
  const [isDexProgressCompleted, setIsDexProgressCompleted] = useState(false);

  const setDexProgressCompleted = () => {
    setDexProgress(100);
    setDexProgressColor('green');
    setIsDexProgressCompleted(true);
  };

  useEffect(() => {
    // setPointAmountOwned(myToken?.pointAmount ?? 0);

    if (offchainTokenId) {
      app.memes.getMeme(offchainTokenId, 'fetch').then((meme) => {
        // ------------------------
        // dex progress calculation
        if (meme) {
          setMeme(meme);
          const isGraduated =
            Boolean(meme.isGraduated || meme.dexContractAddress) ||
            app.ui.getIsGraduating(meme.id);
          if (isGraduated) {
            setDexProgressCompleted();
          } else {
            getDexGraduationPct(meme).then((graduationPct) => {
              if (graduationPct >= 100) {
                setDexProgressCompleted();
              } else {
                setDexProgress(graduationPct);
              }
            });
          }
        }
        // ------------------------
      });
    }
  }, [uiAlpha, offchainTokenId, meme?.id, meme?.pointPrice]);

  // market cap
  const marketCapValue = HP(meme?.marketCapUsd).toNumber();
  const marketCapDisplayValue = displayFormattedMarketCapValue(marketCapValue);

  const onTapDetails = async () => {
    app.nav.goTo('TiktokMemeDetailsPage');
  };

  // decide if normal bottom-ui is going to fade in or out
  const isDisabled = app.tmg.isTapping(); // selected && app.ttg.tap?.showPoints && canShowTapGame;
  let fadeClass = isDisabled ? 'fade-out' : 'fade-in-slow';

  return (
    <div className="feed-meme-ui-bottom" style={{ opacity: uiAlpha }}>
      <div className={`feed-meme-ui-content ${fadeClass}`}>
        <TiktokItemTransactionBubbles memeState={meme} selected={selected} />

        <SidebarUI token={token} />

        <div className="btn bottom-ui-container" onClick={onTapDetails}>
          {/* token info (image, ticker, marketCap) */}
          <div className="bottom-ui-item">
            {/* token image */}
            <MemeImage
              src={token.image}
              size={40}
              badgeSrc={
                isDexProgressCompleted
                  ? assets.icon_graduation_rocket
                  : undefined
              }
            />
            {/* ticker + marketCap */}
            <div className="text-area">
              <div className="label tickerName">${token.ticker}</div>

              <div className="label marketCap">
                {t('trading_token_item_market_cap')} {marketCapDisplayValue}
              </div>

              {/* holders */}
              {/* <div className="item">
                  <div className="info">
                    <div className="row left holders">
                      <MemeImage src={assets.icon_holder} size={10} />
                      <div className="label holders">{holderCount}</div>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>

          {/* progress bar */}
          <div className="progress-bar-area">
            <div className="progress-bar-left">
              <ProgressBar
                progress={dexProgress}
                color={dexProgressColor}
                height={9}
              />
            </div>
            <div className="progress-bar-right">
              {isDexProgressCompleted
                ? t('trading_token_item_progress_complete')
                : t('trading_token_item_progress', {
                    dexProgressPercent: Math.round(dexProgress),
                  })}
            </div>
          </div>
        </div>

        {/* buy/sell buttons */}
        <FeedMemeFooter onTapPurchaseButton={app.ui.onTapPurchaseButton} />
      </div>
    </div>
  );
};
