import './DrawerTiktokSwap.scss';
import '../../TradingTokenPage/TradingTokenFooter/TradingTokenFooter.scss';
import { app } from '../../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { TradingTokenFooterForm } from '../../TradingTokenPage/TradingTokenFooter/components/TTFForm';

export const DrawerTiktokSwap = () => {
  const { t } = useTranslation();

  const onClose = () => {
    app.ui.drawer.close();
  };

  const { swappableToken } = app.memes.trading;
  if (!swappableToken) {
    return null;
  }

  const titleKey = 'buysell_title_sell';
  const title = t(titleKey, { ticker: swappableToken?.jetton.symbol });

  return (
    <div className="drawer-tiktok-buysell">
      {/* header (title + custom close button) */}
      <div className="buysell-header">
        <div className="buysell-header-row">
          <div className="buysell-title">{title}</div>
          <div className="btn button-close-custom" onClick={onClose}>
            <img src={assets.button_arrow_down} />
          </div>
        </div>
      </div>

      <div className="trading-token-footer-container">
        <TradingTokenFooterForm
          footerMode={'normal'}
          txType={'sell'}
          currencyType={'tokens'}
          isNonGemzSwap={true}
        />
      </div>
    </div>
  );
};
