import { Drawers } from '../../../components/modals/Drawer';
import { EarnCheckerKey } from '../../../components/modals/drawers/DrawerEarnCheck';
import { Slideshow } from '../../../components/Tutorial/Slideshows/config';
import { SlideshowItem } from '../../../components/Tutorial/Slideshows/Slideshow';
import { Meme } from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import {
  TxType,
  TradingMemeInput,
  TradingTokenListing,
  CurrencyType,
} from '../../../replicant/features/tradingMeme/types';
import {
  QuestVideoAirtableItem,
  PowerUp,
} from '../../../replicant/features/powerups/types';
import {
  AirtableQuestItem,
  Quest,
} from '../../../replicant/features/quests/types';
import { Booster, Buff, BoosterListing, Gravity } from '../../types';

export const BADGE_VALUES = {
  TRADING_ALERT: '!' as const,
};

// todo carles: season2 - these types will need to change to 'Created' | 'Tokens' | 'Points';
export type ProfileTab = 'Owned' | 'Created' | 'Farming';

export interface PopupOpts {
  title: string;
  description: string;
  image?: string;
  score?: string;
  cta: string;
  onClick?: () => void;
}

export interface Btn {
  cta: string;
  url?: string;
  onClick: () => void;
}

export interface BasicModalOpts {
  image?: string;
  title?: string;
  subtitle?: string;
  subtitle2?: string;
  isSubtitleLeft?: boolean;
  score?: string;
  buttons?: Btn[];
  onClose?: () => void;
  footnote?: string;
}

export interface TransactionSuccess {
  mode: TxType | 'create';
  txCurrency: CurrencyType;
  memeId?: string;
  memeName: string;
  memeTicker: string;
  memeDescription?: string;
  memeImage: string | undefined;
  txAmount: string;
}

/* add here any data that a specific drawer would need */
export interface DrawerSpecificProps {
  earnCheckerKey?: EarnCheckerKey;
  rewardClaimed?: boolean;
  video?: QuestVideoAirtableItem;
  mine?: {
    powerup: PowerUp;
    onDailyFound: (id: PowerUp) => void;
  };
  questConfig?: AirtableQuestItem;
  quest?: Quest;
  trading?: Meme;
  offchainTokenInput?: TradingMemeInput;
  transactionConfirm?: { mode: TxType };
  transactionSuccess?: TransactionSuccess;

  meme?: Meme;
  claimedTokens?: string;

  transactionError?: {
    errorMessage: string;
  };
  tradingWarning?: {
    warningTitle: string;
    warningMessage: string;
    warningCta: string;
    icon?: string;
  };
  followView?: 'following' | 'followers';
  //
  tokenData?: TradingTokenListing;
  //
  booster?: {
    boosterId: Booster | Buff;
    listing: BoosterListing;
    onBuyBooster?: (booster: Booster) => Promise<void>;
  };
}

export interface DrawerOpts {
  id: Drawers;
  opts?: BasicModalOpts;
  hideClose?: boolean;
  noModal?: boolean;
  skipBackdropClose?: boolean;
  props?: DrawerSpecificProps;
  onClose?: () => void;
  style?: React.CSSProperties;
}

export interface ConfettiOpts {
  gravity?: Gravity;
  duration?: number;
}

export interface CSSPosition {
  x: string; // px, %
  y: string; // px, %
}

export type Align = 'top' | 'bottom' | 'right' | 'left';

export interface SlideshowCfg {
  index: number;
  tutorial: Slideshow;
  items: SlideshowItem[];
}

export interface Tooltip {
  text: string;
  origin: CSSPosition; // percentual origin position
  offset: CSSPosition; // pixel offset from percentual origin position
  align?: Align;
  preventAutoHide?: boolean;
}

export interface SwipeTutorial {
  visible: boolean;
}

export enum ElementUIState {
  Hidden = 'Hidden', // makes element invisible (does not remove) [visibility: hidden]
  Mystery = 'Mystery', // makes element to show with question mark
  Highlight = 'Highlight', // makes the element higlighted and pulsing
  Normal = 'Normal', // regular style for element
  Disabled = 'Disabled', // makes element disabled, unclickable and dimmed
  Inactive = 'Inactive', // makes element unclickable
  Remove = 'Remove', // remove the element [display:none]
  Complete = 'Complete', // each button might implement this differently
}

export enum UIEvents {
  OnBalanceUpdate = 'OnBalanceUpdate',
  ClickerUpdate = 'ClickerUpdate',
  MineUpdate = 'MineUpdate',
  EarnUpdate = 'EarnUpdate',
  ShopUpdate = 'ShopUpdate',
  TutorialUpdate = 'TutorialUpdate',
  TradingUpdate = 'TradingUpdate',
  OnSpinnerUpdate = 'OnSpinnerUpdate',
  OnFooterExpanded = 'OnFooterExpanded',
  OnProfileUpdate = 'OnProfileUpdate',
  OnTappingUpdate = 'OnTappingUpdate',
  OnClaimingUpdate = 'OnClaimingUpdate',
  OnGraduateUpdate = 'OnGraduateUpdate',
  OnOnchainUpdate = 'OnOnchainUpdate',
  OnBannedRegion = 'OnBannedRegion',
}
