import { ErrorCode } from '../../replicant/response';
import {
  SendTransactionRequest,
  SendTransactionResponse,
  TonConnectError,
} from '@tonconnect/sdk';
import { TonProvider } from './TonProvider';
import { BusinessController } from '../Controllers/BusinessController';
import { ConnectedWallet, TonConnectUI } from '@tonconnect/ui';
import gameConfig from '../../replicant/features/game/game.config';
import { analytics } from '@play-co/gcinstant';
import {
  BuyTokenInput,
  ClaimType,
  CreateContractInput,
  SwapTokenInput,
  SwapType,
} from './types';
import { cai, waitFor } from '../utils';
import { env, qpConfig, replicantEnv } from '../config';
import { Address, Cell, fromNano, SenderArguments } from '@ton/core';
import { withRetry } from './utils';
import { HighPrecision, HP } from '../../replicant/lib/HighPrecision';
import { DEX, pTON } from '@ston-fi/sdk';
import { TxType } from '../../replicant/features/tradingMeme/types';
import {
  ContractMetadata,
  getContractMetadataFromMeme,
  getEnvCode,
  PickMetadaFromMeme,
} from '../../replicant/features/tradingMeme/tradingMeme.getters.ton';
import { getTxVerificationRetryDelay } from '../../replicant/features/tradingMeme/tradingMeme.getters';
import { ONE_DAY_MS } from '../../replicant/features/game/ruleset/contract';
import { timeLog } from '../utils/timeLog';
import { toNano } from '../../replicant/features/tradingMeme/utils';
import { AppEvents } from '../Controllers/AppController';
import {
  WAIT_WALLET_ADDRESS_TIMEOUT,
  POOL_INTERVAL,
  WALLET_TIMEOUT,
  DEMO_DEX_TOKEN_ADDRESS,
  STONFI_PTON_ADDRESS,
  STONFI_ROUTER_ADDRESS,
  WALLET_TIMEOUT_ERROR,
} from './consts';

if (env !== 'prod') {
  // this is a monkey patch for the Ton connect widget that tries to redefine a custom element when the page auto-refreshes
  const originalDefine = window.customElements.define;

  window.customElements.define = function (name, constructor, options) {
    if (!window.customElements.get(name)) {
      return originalDefine.call(this, name, constructor, options);
    }

    // Optionally, log something here if you want to know it's skipping:
    console.warn(`Skipping re-definition of custom element: ${name}`);
    return;
  };
}

export enum TonEvents {
  OnBalanceUpdate = 'OnBalanceUpdate',
  OnWalletConnectUpdate = 'OnWalletConnectUpdate',
  OnTransactionConfirmed = 'OnTransactionConfirmed',
}

// @TODO: Move to TonProvider
function extractTxHash(transactionResponse: SendTransactionResponse) {
  const cell = Cell.fromBase64(transactionResponse.boc);
  const buffer = cell.hash();
  const txHash = buffer.toString('hex');

  return txHash;
}

export class TonController extends BusinessController<TonEvents> {
  public static TestUIEnabled = qpConfig.testTON;

  public tonConnectUI!: TonConnectUI;

  private provider = new TonProvider();

  private txVerificationTimeout: NodeJS.Timeout | null = null;

  private walletTimeoutInProgress = false;

  public get walletAddress() {
    return this.provider.walletAddress;
  }

  private _balance?: string;

  public get balance() {
    return this._balance ?? '0';
  }

  public get connected() {
    return this.tonConnectUI?.connected ?? false;
  }

  public normalizeAddress(walletAddress?: string) {
    if (!walletAddress) {
      return '';
    }

    return Address.parse(walletAddress).toString({
      urlSafe: true,
      bounceable: false,
    });
  }

  init = async () => {
    const manifest = replicantEnv === 'local' ? 'dev' : replicantEnv;
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${gameConfig.playUrl}/tonconnect/${manifest}/tonconnect-manifest.json`,
    });

    this.tonConnectUI.onStatusChange(
      async (status: ConnectedWallet | null) => {
        await this.app.waitForReplicant();

        if (status) {
          this.app.replicant.invoke.setWalletInfo({
            app_name: status.appName,
            address: status.account.address,
          });

          // console.error('*** price at supply:', getOnchainCurvePrice(HP(1_000_000)).toString())

          this.provider.setWalletAddress(status.account.address);

          this.getBalance().then((balance) => {
            analytics.setUserProperties({
              walletAddress: status.account.address,
              wallet_ton_balance: Number(balance),
            });
          });

          // uncomment to test an iteration of the watcher
          // this.app.invoke.runTxWatcherAsync({
          //   memeId: '8740194237035289',
          //   creator: {
          //     userId: '5796798150',
          //     walletAddress: '0:5e5f006ce110ec218713b8046765715b30c3dd164dac8b382083d7db55891124',
          //   }
          // });

          this.scheduleTxConfirmation();
        } else {
          this._balance = undefined;
          this.provider.setWalletAddress(undefined);
        }
        this.sendEvents(TonEvents.OnWalletConnectUpdate);
      },
      (error: TonConnectError) => {
        // console.error(`Failed to connect Ton Wallet: ${error.message}`);
        analytics.pushError('WalletConnectError', {
          name: error.name,
          message: error.message,
        });
      },
    );

    this.app.maybeFixBrokenWalletConnectQuest();

    // If we leave the app then the wallet opened
    this.app.addEventListener(AppEvents.onPause, () => {
      this.walletTimeoutInProgress = false;
    });
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  public getBalance = async () => {
    const rawBalance = await this.provider.getBalance();
    const prevBalance = this._balance;
    this._balance = fromNano(rawBalance);
    if (this._balance !== prevBalance) {
      this.sendEvents(TonEvents.OnBalanceUpdate);
    }
    return this._balance;
  };

  private waitForWalletAddress = async (retries = 0): Promise<void> => {
    if (retries > WAIT_WALLET_ADDRESS_TIMEOUT) {
      return Promise.reject();
    }
    await waitFor(POOL_INTERVAL);
    if (this.walletAddress) {
      return Promise.resolve();
    }
    return this.waitForWalletAddress(++retries);
  };

  connect = async () => {
    if (!this.tonConnectUI.connected || !this.provider.walletAddress) {
      this.provider.setWalletAddress(this.tonConnectUI.account?.address);
      await this.tonConnectUI.openModal();
      cai('connect', {});
      return this.waitForWalletAddress();
    }
    return Promise.resolve();
  };

  private checkForWalletTimeout = async () => {
    this.walletTimeoutInProgress = true;
    await waitFor(WALLET_TIMEOUT);
    if (this.walletTimeoutInProgress) {
      this.walletTimeoutInProgress = false;
      throw new Error(WALLET_TIMEOUT_ERROR);
    }
  };

  private sendTransaction = async (
    txRequest: SendTransactionRequest,
  ): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      try {
        // Throws an error on timeout
        this.checkForWalletTimeout().catch((err) => {
          reject(err);
        });

        const transactionResponse = await this.tonConnectUI.sendTransaction(
          txRequest,
        );
        this.walletTimeoutInProgress = false;
        const txHash = extractTxHash(transactionResponse);
        console.log('Transaction Hash:', txHash);

        // @TODO: Here is a good place to start an async check for transaction complete
        return resolve(txHash);
      } catch (e) {
        this.walletTimeoutInProgress = false;
        return reject(e);
      }
    });
  };

  disconnect = async () => {
    await this.tonConnectUI.disconnect();
  };

  createContract = async (input: CreateContractInput) => {
    console.log('createContract', { input });

    await this.connect();

    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const { meme, tonAmount, jettonContractAddress } = input;

    const tokenAmount = this.provider.getListingBuyTokens(tonAmount);

    const metadata = getContractMetadataFromMeme(meme, replicantEnv);

    const submit = await this._buyToken({
      ownerAddress: this.walletAddress,
      tokenAmount: HP(tokenAmount.toString()),
      metadata,
      firstBuy: true,
      tonAmount,
      jettonContractAddress,
    });

    return submit();
  };

  private confirmTx = (txHash: string) => {
    cai('confirmTx', { txHash });
    return () =>
      this.provider.getTxWithHash(txHash).then((res) => {
        cai('confirmTx', { res });
        if (res.retry) {
          return undefined; // retry
        }

        if (!res.success) {
          throw new Error(`Tx failed`);
        }

        return 'success';
      });
  };

  private _buyToken = async ({
    ownerAddress,
    tokenAmount,
    metadata,
    firstBuy,
    tonAmount,
    jettonContractAddress,
  }: {
    ownerAddress: string;
    tokenAmount: HighPrecision;
    metadata: ContractMetadata;
    firstBuy: boolean;
    tonAmount: string;
    jettonContractAddress?: string;
  }) => {
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const { memeId } = metadata;
    const buyTime = this.app.now();

    timeLog.start('_buyToken:getBuyTokenTx');
    const buyTokenOrder = await this.provider.getBuyTokenTxOrder(
      {
        memeId,
        ownerAddress,
        amount: tokenAmount,
        metadata,
        firstBuy,
      },
      tonAmount,
      buyTime,
    );
    this.app.ui.setOnchainState({ order: buyTokenOrder });
    timeLog.end('_buyToken:getBuyTokenTx');

    const submit = async () => {
      cai('tonConnectSubmit', { time: Date.now() });
      const txHash = await this.sendTransaction(buyTokenOrder.tx);
      this.setConfirmationWaiter(txHash);

      this.app.invoke.saveUnconfirmedTx({
        txType: 'buy',
        memeId,
        txHash,
        walletAddress,
      });

      this.scheduleTxConfirmation();

      return txHash;
    };

    return submit;
  };

  private _swap = async ({
    tokenAmount,
    tonAmount,
    jettonContractAddress,
    memeId,
    swapType,
    isNonGemz,
  }: {
    tokenAmount: HighPrecision;
    tonAmount: string;
    jettonContractAddress: string;
    memeId?: string;
    swapType: SwapType;
    isNonGemz?: boolean;
  }) => {
    await this.connect();
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    if (!jettonContractAddress) {
      throw new Error('Missing Dex Contract Address');
    }

    let routerAddress = STONFI_ROUTER_ADDRESS;

    if (isNonGemz) {
      // at this point the pool should be cached
      const poolInfo = await this.provider.getTokenPoolData(
        jettonContractAddress,
      );
      routerAddress = Address.parse(poolInfo.router_address);
    }

    const tokenContractAddress =
      jettonContractAddress || DEMO_DEX_TOKEN_ADDRESS; // todo: comes from the UI
    const proxyTon = pTON.v2_1.create(STONFI_PTON_ADDRESS);

    const router = this.provider.client.open(
      DEX.v2_1.Router.create(routerAddress),
    );

    let txParams: SenderArguments;
    if (swapType === 'tonToJetton') {
      txParams = await router.getSwapTonToJettonTxParams({
        userWalletAddress: walletAddress,
        proxyTon,
        askJettonAddress: Address.parse(tokenContractAddress),
        offerAmount: toNano(tonAmount),
        minAskAmount: '1',
        queryId: Date.now(),
      });
    } else {
      txParams = await router.getSwapJettonToTonTxParams({
        userWalletAddress: walletAddress,
        offerJettonAddress: Address.parse(tokenContractAddress),
        offerAmount: tokenAmount.toString(),
        minAskAmount: '1',
        proxyTon,
        queryId: Date.now(),
      });
    }

    const txHash = await this.sendTransaction({
      validUntil: Date.now() + 1000000,
      messages: [
        {
          address: txParams.to.toString(),
          amount: txParams.value.toString(),
          payload: txParams.body?.toBoc().toString('base64'),
        },
      ],
    });

    this.setConfirmationWaiter(txHash);

    if (memeId) {
      this.app.invoke.saveUnconfirmedTx({
        txType: swapType === 'tonToJetton' ? 'dexBuy' : 'dexSell',
        memeId,
        txHash,
        walletAddress,
      });

      cai('saveUnconfirmedTx', {
        txType: swapType === 'tonToJetton' ? 'dexBuy' : 'dexSell',
        memeId,
        txHash,
        walletAddress,
      });

      this.scheduleTxConfirmation();
    }

    return txHash;
  };

  private scheduleTxConfirmation = () => {
    if (this.txVerificationTimeout !== null) {
      clearTimeout(this.txVerificationTimeout);
    }

    const txVerifDelay = getTxVerificationRetryDelay(this.app.state);
    if (!isFinite(txVerifDelay)) {
      return;
    }

    this.txVerificationTimeout = setTimeout(async () => {
      const updatedMemeIds = await this.app.invoke.runTxConfirmationAsync();

      if (updatedMemeIds.length > 0) {
        // will have the effect of refreshing the meme states on the client,
        // including the supply, which can in turn trigger the graduation
        this.app.memes.refreshMemes(updatedMemeIds);

        this.app.profile.refreshUser();
      }

      this.scheduleTxConfirmation();
    }, txVerifDelay);
  };

  private setConfirmationWaiter = async (txHash: string) => {
    // transactions rarely gets confirmed before 20s
    const initialDelay = 20_000;
    await new Promise((resolve) => setTimeout(resolve, initialDelay));

    const confirmationWaiter = withRetry(this.confirmTx(txHash), {
      maxRetries: 100, // 250s (30s avg),
      interval: 2500,
    })
      // .then(() => {
      //   // Do it with a delay so open search has time to propagate
      //   setTimeout(() => {
      //     this.app.profile.refreshUser();
      //   }, 10_000);
      // })
      .catch(() => {
        this.app.ui.showError({
          title: 'Transaction Failed',
          message: `Failed to perform tx.\n${txHash}`,
        });
      });

    return confirmationWaiter;
  };

  buyToken = async (input: BuyTokenInput) => {
    timeLog.start('buyToken:connect');
    await this.connect();
    timeLog.end('buyToken:connect');

    const { meme, tokenAmount, tonAmount } = input;
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const firstBuy = !meme.isMinted;
    if (firstBuy) {
      try {
        // async double check if it's not actually minted but not updated
        this.provider.getJettonBalance(meme.jettonContractAddress).then(() => {
          // If request succeeds that means it has onchain data, therefore is minted
          this.app.invoke.onJettonContractMinted({ memeId: meme.id });
        });
      } catch {}
    }

    const metadata = getContractMetadataFromMeme(
      {
        ...meme,
        description: meme.description.description,
      },
      replicantEnv,
    );

    const submit = this._buyToken({
      ownerAddress: meme.creatorWalletAddress,
      tokenAmount,
      metadata,
      firstBuy,
      tonAmount,
      jettonContractAddress: meme.jettonContractAddress,
    }).then((res) => {
      // todo: optimize this, we don't need to trigger on every buy but may be close to graduation
      if (!meme.isGraduated) {
        this.triggerGraduation(meme.id);
      }
      return res;
    });

    return submit;
  };

  buyDexToken = async (input: SwapTokenInput) => {
    const { memeId, dexContractAddress, tonAmount, isNonGemz } = input;

    return this._swap({
      tokenAmount: HP(0),
      tonAmount: tonAmount,
      jettonContractAddress: dexContractAddress,
      memeId,
      swapType: 'tonToJetton',
      isNonGemz,
    });
  };

  sellToken = async (metadataInput: PickMetadaFromMeme, amount: string) => {
    timeLog.start('sellToken:connect');
    await this.connect();
    timeLog.end('sellToken:connect');

    const metadata = getContractMetadataFromMeme(metadataInput, replicantEnv);

    const { memeId } = metadata;

    timeLog.start('sellToken:getSellTokenTx');
    const sellTokenTx = await this.provider.getSellTokenTx(
      {
        amount: BigInt(amount),
        memeId,
        metadata,
      },
      this.app.now(),
    );
    timeLog.end('sellToken:getSellTokenTx');

    const submit = async () => {
      cai('sellToken:submit');
      const txHash = await this.sendTransaction(sellTokenTx);
      this.setConfirmationWaiter(txHash);

      this.app.invoke.saveUnconfirmedTx({
        txType: 'sell',
        memeId,
        txHash,
        walletAddress: this.walletAddress!,
      });

      this.scheduleTxConfirmation();

      return txHash;
    };

    return submit;
  };

  sellDexToken = async (input: SwapTokenInput) => {
    const { memeId, dexContractAddress, tokenAmount, isNonGemz } = input;

    return this._swap({
      tokenAmount: tokenAmount,
      tonAmount: '',
      jettonContractAddress: dexContractAddress,
      memeId,
      swapType: 'jettonToTon',
      isNonGemz,
    });
  };

  claimDexToken = async (
    metadataInput: PickMetadaFromMeme,
    userId: string,
    claimType: ClaimType = 'dailyClaim',
  ) => {
    await this.connect();

    const metadata = getContractMetadataFromMeme(
      metadataInput,
      replicantEnv,
      false,
    );

    const { memeId } = metadata;

    const claimDexTokenTx = await this.provider.claimDexTokenTx(
      memeId,
      userId,
      claimType,
    );

    console.log(metadata);

    const txHash = await this.sendTransaction(claimDexTokenTx.result);
    // @TODO: see how many transactions we get from claiming
    const asyncConfirm = this.setConfirmationWaiter(txHash);
    return { asyncConfirm };
  };

  getJettonAddress = async (metadataInput: PickMetadaFromMeme) => {
    const metadata = getContractMetadataFromMeme(metadataInput, replicantEnv);
    return this.provider.getJettonContractAddressFromMetadata(metadata);
  };

  getMemeBalance = async (memeId: string) => {
    const meme = await this.app.memes.getMeme(memeId);
    console.log('getMemeBalance', { memeId, meme });
    if (!meme || !meme.jettonContractAddress) {
      return HP(0);
    }

    if (meme.dexContractAddress) {
      return this.getJettonBalance(meme.dexContractAddress);
    }

    return this.getJettonBalance(meme.jettonContractAddress);
  };

  getJettonBalance = async (contractAddress: string) => {
    return HP(await this.provider.getJettonBalance(contractAddress));
  };

  getTx = async (hash: string) => {
    const tx = await this.provider.getTx(hash);
    console.log({ tx });
    return tx?.hash;
  };

  getHolders = async (contractAddress: string) => {
    const holders = await this.provider.getHolders(contractAddress);
    console.log({ holders });
    return holders.total;
  };

  getBuyPrice = async (contractAddress: string, amount: string) => {
    return HP(await this.provider.getJettonBuyPrice(contractAddress, amount));
  };

  getListingTokens = (ton: string) => {
    cai('getListingTokens', { time: Date.now() });
    return this.provider.getListingBuyTokens(ton);
  };

  getEstimatedListingTokens = (ton: string) => {
    return this.provider.getEstimatedListingBuyTokens(ton);
  };

  getBuyTokensPerTon = (contractAddress: string, ton: string) => {
    cai('getBuyTokensPerTon', { time: Date.now() });
    return this.provider.getBuyTokens(contractAddress, ton);
  };

  getEstimatedTonToTokens = (contractAddress: string, ton: string) => {
    return this.provider.getEstimatedBuyTokens(contractAddress, ton);
  };

  getEstimatedTokensToTon = async (contractAddress: string, tokens: bigint) => {
    return this.provider.getSellTokens(contractAddress, tokens);
  };

  getTonToUSDSync = (ton: string) => {
    return this.provider.getTonToUSDSync(ton);
  };

  getTonToUSD = async (ton: string) => {
    return this.provider.getTonToUSD(ton);
  };

  getTokenToTON = (tokens: bigint, supply: bigint, txType: TxType) => {
    return this.provider.getTokenToTON(tokens, supply, txType);
  };

  getJettonSupply = async (contractAddress: string) => {
    return this.provider.getJettonSupply(contractAddress);
  };

  getDexGraduationPct = async (tokenSupply: string) => {
    const graduationPct =
      (100 * HP(tokenSupply).toNumber()) /
      Number(this.provider.curveConfig.graduationSupplyThreshold);
    return Math.min(100, graduationPct);
  };

  triggerGraduation = async (memeId: string) => {
    return this.provider.triggerGraduation({
      memeId: memeId,
      env: getEnvCode(),
    });
  };

  getDexTonToTokens = async (input: BuyTokenInput) => {
    const { meme, tonAmount } = input;
    const dexTokenAddress = meme.dexContractAddress || DEMO_DEX_TOKEN_ADDRESS;
    const tokensReceived = await this.provider.getDexTonToTokens(
      dexTokenAddress,
      tonAmount,
    );
    return tokensReceived;
  };

  getDexTokensToTon = async (input: SwapTokenInput) => {
    cai('getDexTokensToTon', { time: Date.now() });
    const { dexContractAddress, tokenAmount } = input;
    const dexTokenAddress = dexContractAddress || DEMO_DEX_TOKEN_ADDRESS;
    const tokensReceived = await this.provider.getDexTokensToTon(
      dexTokenAddress,
      tokenAmount.toString(),
    );
    return tokensReceived;
  };

  getSeqno = (index: number) => {
    return this.provider.getSeqno(index);
  };

  getTransactions = async (addressFriendly: string) => {
    // return this.provider.getTransactionMetadata(addressFriendly);
    return this.provider.getParseTransactions(addressFriendly);
  };

  getSwappableTokens = async () => {
    return this.provider.getSwappableTokens();
  };

  test = async () => {
    // const transactions = await fetch(
    //   'https://toncenter.com/api/v3/transactions?hash=c2c7abae3347b04ef22772bffdfbb23a17b23e4a8d770b6aa96eb0e7d6b6007e',
    // )
    //   .then((res) => res.json())
    //   .then((v) => {
    //     console.log(v);
    //     return v.transactions;
    //   });
    // this.provider.getParseTransactions(transactions);
    this.provider.getParseTransactions(
      'EQBW8qI2QK88cYFdrGmlmDkcHKU7PDkU3--m2AjP9JwHmbJR',
    );
  };

  checkIn = async () => {
    if (this.app.now() - this.app.state?.dailyContractCheckin < ONE_DAY_MS) {
      console.log('Already checked in today');
      return;
    }

    await this.connect();

    const checkInTx = this.provider.getCheckInTx(this.app.now());

    return this.tonConnectUI.sendTransaction(checkInTx);
  };

  getFriendlyAddress = (jettonContractAddressRaw: string) => {
    return Address.parseRaw(jettonContractAddressRaw).toString();
  };

  getLiquidityPoolData = (jettonContractAddressRaw: string) => {
    const jettonContractAddress = this.getFriendlyAddress(
      jettonContractAddressRaw,
    );
    return this.provider.getLiquidityPoolData(jettonContractAddress);
  };

  // This is used to render the test page
  public ui = {
    connect: this.connect,
    disconnect: this.disconnect,
    createContract: this.createContract,
    buyToken: this.buyToken,
    sellToken: this.sellToken,
    claimDexToken: this.claimDexToken,
    buyStonFi: this.buyDexToken,
    getLiquidityPoolData: this.getLiquidityPoolData,
    getJettonContractAddress:
      this.provider.getJettonContractAddressFromMetadata,
    getMemeBalance: this.getMemeBalance,
    getJettonWalletAddress: this.provider.getJettonWalletAddress,
    getJettonBuyPrice: this.provider.getJettonBuyPrice,
    // getTxs: this.provider.getTransactions,
    getTx: this.getTx,
    getHolders: this.getHolders,
    getSeqno: this.getSeqno,
    test: this.test,
    getDexGraduationPct: this.getDexGraduationPct,
  };
}
