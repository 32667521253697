import { MutableState, State } from '../../schema';
import {
  confirmTransaction,
  getActualTime,
  // getWalletPortfolioValue,
  getTmgTicketTimestamp,
  getTMGTappingMaxTickets,
  getTTGCanClaim,
  getTTGFarmingPoints,
  getTTGFarmingSpotsAvailable,
  getTTGIsFarming,
  getMyOffchainMemeIds,
  getTokenPrice,
  isValidTxType,
  findWalletUser,
  calculateMintTonPrice,
  getOnchainMarketCap,
  getTxVerificationRetryDelay,
  getWallet,
  getDayTime,
  isDailyTokenBeingClaimed,
  getTotalDailyPoints,
} from './tradingMeme.getters';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import {
  FixedSliceTimeWindows,
  fixedSliceTimeWindows,
  maxPortfolioAllTimeSliceCount,
  tmgRuleset,
  portfolioPriceSliceConfigs,
  SliceConfig,
  totalDailyTokenAllocation,
  minTxVerificationDelayMs,
  claimDayDuration,
  totalGradTokenAllocation,
  creatorGraduationTonReward,
  pointEpsilon,
} from './tradingMeme.ruleset';
import {
  OwnedOffchainMeme,
  PriceSlice,
  PriceTrends,
  UnconfirmedTx,
  WalletMemeHoldings,
} from '../game/player.schema';
import {
  getDayMidnightInUTC,
  HOUR_IN_MS,
  isNextDay,
  isSameDay,
  SEC_IN_MS,
} from '../../utils/time';
import { stage } from '../game/game.config';
import { isFromTiktokOnwards as getIsFromTiktokOnwards } from '../game/game.getters';
import { tests } from '../../ruleset';
import { getFeatureAb } from '../game/abtest.getters';
import {
  JettonTx,
  MemeDetails,
  OnchainTx,
  OnchainUserProfile,
  TradingMemeStatus,
  TradingState,
} from './tradingMeme.schema';
import { HighPrecision, HP } from '../../lib/HighPrecision';
import { retry } from '../../lib/async';
import { getOnchainHoldersStateId } from '../onchainHolders/onchainHolders.getters';
import {
  TransactionWatcher,
  JettonTxData,
  DexTxData,
  TxStatus,
  getTxResponseStatus,
} from './tradingMeme.getters.ton';
import { Address, fromNano } from '@ton/core';
import { Holding } from '../onchainHolders/onchainHolders.schema';
import { TradingSearchResult } from './tradingMeme.properties';
import { TxOutcome } from '../onchainTxs/onchainTxs.schema';
import { PointUpdate, WalletJetton } from '../tradingMeme/types';
import { WatcherOpts } from './types';

const BASE_RETRY_DELAY_MS = 3 * SEC_IN_MS;
const MAX_RETRY_DELAY_MS = 1 * HOUR_IN_MS;

type API =
  | ReplicantAsyncActionAPI<ReplicantServer>
  | ReplicantEventHandlerAPI<ReplicantServer>
  | ReplicantSyncActionAPI<ReplicantServer>;

function addPriceToTrend(
  trend: PriceSlice[],
  sliceConfig: SliceConfig,
  price: string,
  time: number,
) {
  let firstPrice = price;
  while (trend.length > 0) {
    firstPrice = trend[0].price;
    if (trend[0].time >= time - sliceConfig.window) {
      break;
    }

    // slice is out of the time window
    // time to kick some butts
    trend.shift();
  }

  const windowStartTime =
    sliceConfig.interval *
    Math.floor((time - sliceConfig.window) / sliceConfig.interval);
  if (trend.length === 0 || trend[0].time !== windowStartTime) {
    trend.unshift({
      time: windowStartTime,
      price: firstPrice,
    });
  }

  // setting the price for the NEXT price slice
  // the reason is that the last transaction of a slice sets the entry price of the price slice coming after it
  const sliceTime =
    sliceConfig.interval * Math.floor(time / sliceConfig.interval);
  const nextSliceTime = sliceTime + sliceConfig.interval;
  if (trend[trend.length - 1].time < nextSliceTime) {
    // create next slice
    trend.push({
      time: nextSliceTime,
      price,
    });
  } else if (trend[trend.length - 1].time < sliceTime) {
    // last slice is already the next slice
    trend[trend.length - 1].price = price;
  } else {
    // ignore price point
  }
}

function addPriceToTrends(trends: PriceTrends, price: string, time: number) {
  for (let i = 0; i < fixedSliceTimeWindows.length; i += 1) {
    const timeWindow = fixedSliceTimeWindows[i];
    const sliceConfig =
      portfolioPriceSliceConfigs[timeWindow as FixedSliceTimeWindows];
    const trend = trends[timeWindow as FixedSliceTimeWindows];
    addPriceToTrend(trend, sliceConfig, price, time);
  }

  // update slices for the allTime/variable time window
  const allTimeTrend = trends.allTime;
  if (
    allTimeTrend.length === 0 ||
    allTimeTrend[allTimeTrend.length - 1].time < time
  ) {
    allTimeTrend.push({ time, price });
  }

  // @note: only one iteration of the loop should happen
  while (allTimeTrend.length > maxPortfolioAllTimeSliceCount) {
    const timeWindow =
      allTimeTrend[allTimeTrend.length - 1].time - allTimeTrend[0].time;

    let smallestInterval = timeWindow;
    let smallestIntervalSliceIdx = -1;
    let previousTime = allTimeTrend[0].time;
    for (let i = 1; i < allTimeTrend.length - 1; i += 1) {
      const slice = allTimeTrend[i];

      // @todo?
      // prioritize merges of distance point in times by multipltying interval by a distance coefficient,
      // it virtually makes distant intervals smaller
      // const distanceCoeff = Math.pow(i / allTimeTrend.length, 0.5);
      // const interval = distanceCoeff * (slice.time - previousTime);

      const interval = slice.time - previousTime;
      if (interval < smallestInterval) {
        smallestInterval = interval;
        smallestIntervalSliceIdx = i;
      }
      previousTime = slice.time;
    }

    trends.allTime.splice(smallestIntervalSliceIdx, 1);
  }
}

// export const savePortfolioPricePoint = async (
//   state: MutableState,
//   api:
//     | ReplicantAsyncActionAPI<ReplicantServer>
//     | ReplicantEventHandlerAPI<ReplicantServer>,
// ) => {
//   const now = api.date.now();

//   for (let walletAddress in state.trading.onchain.wallets) {
//     const portfolioValue = (
//       await getWalletPortfolioValue(state, api, walletAddress)
//     ).toString();

//     const priceTrends =
//       state.trading.onchain.wallets[walletAddress].portfolioTrends;
//     addPriceToTrends(priceTrends, portfolioValue, now);
//   }
// };

export const tmgInitState = (state: MutableState, tokenId: string) => {
  if (!state.trading.miniGames.state[tokenId]) {
    state.trading.miniGames.state[tokenId] = {
      ...tmgRuleset.initialTokenState,
    };
  }
};

export const tmgStartTokenFarming = (
  state: MutableState,
  { tokenId }: { tokenId: string },
  now: number,
) => {
  if (
    getTTGFarmingSpotsAvailable(state) <= 0 ||
    getTTGIsFarming(state, tokenId)
  ) {
    return false;
  }
  tmgInitState(state, tokenId);
  state.trading.miniGames.state[tokenId].miningStart = now;
  return true;
};

const tmgAddDailyTap = (
  state: MutableState,
  { tokenId, taps = 1, now }: { tokenId: string; taps?: number; now: number },
) => {
  tmgInitState(state, tokenId);
  const sameDay = isSameDay(getTmgTicketTimestamp(state), now);
  if (sameDay) {
    state.trading.miniGames.state[tokenId].dailyScore += taps;
  }
};

// !IMPORTANT @TODO: Update logic for farming
export const tmgClaimFarmingReward = (
  state: MutableState,
  { tokenId }: { tokenId: string },
  now: number,
) => {
  if (!getTTGCanClaim(state, tokenId, now)) {
    return -1;
  }
  // give reward
  const reward = getTTGFarmingPoints(state, tokenId, now);
  // tmgAddPoint(state, { tokenId, points: reward });
  // reset farming state
  state.trading.miniGames.state[tokenId].miningStart = undefined;
  return reward;
};

export const tmgConsumeTapTicket = (state: MutableState) => {
  if (state.trading.miniGames.tapping.tickets <= 0) {
    return false;
  }

  state.trading.miniGames.tapping.tickets--;
  return true;
};

export const startGameSession = (
  state: MutableState,
  api: API,
  tokenId: string,
) => {
  tmgInitState(state, tokenId);
  state.trading.miniGames.tapping.sessionTaps = 0;
  return tmgConsumeTapTicket(state);
};

export const tmgRefillTicketsAndWipeSessionCache = (
  state: MutableState,
  api: API,
  fromCheat?: 'useCheat' | 'cheatResetDaily',
) => {
  const now = api.date.now();
  // Reset session cache
  state.trading.miniGames.tapping.sessionTaps = 0;

  const isNotCheat = !Boolean(fromCheat);

  const hasNotBeen1ServerDay = !isNextDay(getTmgTicketTimestamp(state), now);

  const notReadyToRefresh = hasNotBeen1ServerDay && isNotCheat;

  if (notReadyToRefresh) {
    return;
  }

  const resetDaily = isNotCheat || fromCheat === 'cheatResetDaily';

  const tappingMaxTickets = getTMGTappingMaxTickets(state);

  state.trading.miniGames.tapping.tickets = tappingMaxTickets;
  state.trading.miniGames.tapping.ticketTimestamp = now;
};

export const tmgSyncTapsOnSessionComplete = (
  state: MutableState,
  tokenId: string,
  now: number,
) => {
  const taps = Math.min(
    state.trading.miniGames.tapping.sessionTaps,
    tmgRuleset.tappingMaxScore,
  );
  if (taps <= 0) {
    return 0;
  }
  state.trading.miniGames.tapping.sessionTaps = 0;
  tmgAddDailyTap(state, { tokenId, taps, now });
  return taps;
};

export const convertScore = async (
  state: State,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  {
    tokenId,
    score,
  }: {
    tokenId: string;
    score: number;
  },
): Promise<PointUpdate> => {
  const timestamp = getActualTime(api);

  const tokenHolding = state.trading.offchainTokens[tokenId];

  const buyerId = state.id;
  const buyerName = state.profile.name;
  const buyerImage = state.profile.photo;
  const pointsAccumulated = tokenHolding?.pointsAccumulated ?? HP(0).toString();

  const meme = await api.sharedStates.tradingMeme.fetch(tokenId);
  const memeState = meme?.global;
  if (!memeState) {
    throw new Error(
      `Attempting to convert score for meme that doesn't exist '${tokenId}'.`,
    );
  }

  if (stage !== 'prod') {
    api.sendAnalyticsEvents([
      {
        eventType: 'DebugConvertScore1',
        eventProperties: {
          timestamp,
          buyerId,
          buyerName,
          buyerImage,
          score,
          pointsAccumulated,
          expectedTxIdx: memeState.offchainTxs.length,
        },
      },
    ]);
  }

  // We should probably do the R1 and R2 in the same message if we can!!!
  api.sharedStates.tradingMeme.postMessage.exchangeScoreForPoints(tokenId, {
    timestamp,
    isGraduated: memeState.isGraduated,
    buyerId,
    buyerName,
    buyerImage,
    score,
    pointsAccumulated,
    expectedTxIdx: memeState.offchainTxs.length,
  });

  const txConfirmation = await confirmTransaction(
    api as ReplicantAsyncActionAPI<ReplicantServer>,
    buyerId,
    tokenId,
    timestamp,
  );

  if (!txConfirmation) {
    throw new Error(`Failed to confirm score conversion`);
  }

  const transaction = txConfirmation.transaction;
  const txPointAmount = transaction.pointAmount;

  const currencyAmountBig = HP(transaction.currencyAmount);
  const currencyInvested = currencyAmountBig.round();

  return {
    timestamp,
    dailyPoints: memeState.isGraduated ? txPointAmount : undefined,
    pointAmount: txPointAmount,
    pointsAccumulated: txPointAmount,
    currencyInvested: currencyInvested.toString(),
    lastNotifPrice: getTokenPrice(memeState),
  };
};

export function updatePointHolding(
  state: MutableState,
  tokenId: string,
  pointUpdate: PointUpdate,
  priceKnownByUser: boolean = false,
) {
  if (HP(pointUpdate.pointAmount).eq(0)) {
    return;
  }

  let ownedMeme = state.trading.offchainTokens[tokenId];

  if (!ownedMeme) {
    ownedMeme = state.trading.offchainTokens[tokenId] = {
      pointAmount: pointUpdate.pointAmount,
      pointsAccumulated: pointUpdate.pointsAccumulated,
      currencyInvested: pointUpdate.currencyInvested,
      lastNotifPrice: pointUpdate.lastNotifPrice,
    };
  } else {
    const { pointAmount, pointsAccumulated, currencyInvested, lastNotifPrice } =
      pointUpdate;

    ownedMeme.pointAmount = HP(ownedMeme.pointAmount)
      .add(pointAmount)
      .round()
      .toString();

    ownedMeme.currencyInvested = HP(ownedMeme.currencyInvested)
      .add(currencyInvested)
      .round()
      .toString();

    if (getIsFromTiktokOnwards()) {
      ownedMeme.pointsAccumulated = HP(ownedMeme.pointsAccumulated)
        .add(pointsAccumulated)
        .round()
        .toString();
    }

    if (!ownedMeme.lastNotifPrice || priceKnownByUser) {
      ownedMeme.lastNotifPrice = lastNotifPrice;
    }
  }

  const dailyPoints = pointUpdate.dailyPoints;
  if (dailyPoints) {
    const date = getDayTime(pointUpdate.timestamp);

    if (!ownedMeme.dailyPoints) {
      ownedMeme.dailyPoints = {};
    }

    ownedMeme.dailyPoints[date] = HP(ownedMeme.dailyPoints[date])
      .add(dailyPoints)
      .round()
      .toString();
  }
}

export function grantTMGTappingTickets(state: MutableState, amount = 1) {
  state.trading.miniGames.tapping.tickets += amount;
}

export async function grantClaimableTokens(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  // look for daily claimable tokens and/or graduation claimable tokens
  const thisDate = getDayTime(api.date.now());
  if (state.trading.lastPointConversionDate === thisDate) {
    return;
  }

  state.trading.lastPointConversionDate = thisDate;

  const offchainTokens = state.trading.offchainTokens;

  const myOffchainMemeIds = getMyOffchainMemeIds(state);

  const memes = (
    await api.sharedStates.tradingMeme.search({
      where: {
        id: {
          isOneOf: myOffchainMemeIds,
        },
        status: {
          isAnyOf: [
            TradingMemeStatus.Created,
            TradingMemeStatus.Moderated,
            TradingMemeStatus.ModeratedOS,
            TradingMemeStatus.Reported,
          ],
        },
        dexListingTime: {
          // only get tokens that got listed on dex
          greaterThan: 0,
          // is there a time after which daily allocations cannot be claimed?
          // greaterThanOrEqual: state.session_start_time - (90 + MAX_CLAIMABLE_DURATION) * DAY_IN_MS,
        },
      },
      limit: myOffchainMemeIds.length,
    })
  ).results;

  memes.forEach((meme) => {
    const memeId = meme.id;
    const memeHolding = offchainTokens[memeId];

    const totalDailyPoints = getTotalDailyPoints(memeHolding);

    // update graduation tokens
    if (!memeHolding.claimableGradTokens) {
      if (HP(meme.pointSupplyAtGraduation).lte(pointEpsilon)) {
        memeHolding.claimableGradTokens = '0';
      } else {
        const pointAmountHeldBeforeGraduation = HP(
          memeHolding.pointAmount,
        ).minus(totalDailyPoints);
        const graduationPointShare = HP(pointAmountHeldBeforeGraduation).div(
          meme.pointSupplyAtGraduation,
        );
        const allocation = graduationPointShare.gt(1)
          ? // should not happen
            totalGradTokenAllocation
          : totalGradTokenAllocation.mul(graduationPointShare);
        memeHolding.convertedGradPoints =
          pointAmountHeldBeforeGraduation.toString();
        memeHolding.claimableGradTokens = allocation.floor().toString();
      }
    }

    // update daily points
    const allDailyPoints = memeHolding.dailyPoints;
    if (allDailyPoints) {
      for (let dayTimeStr in allDailyPoints) {
        const dayTime = parseInt(dayTimeStr);
        if (dayTime >= thisDate) {
          continue;
        }

        const dailyPoints = meme.dailyPoints.find((dailyAllocation) => {
          return dailyAllocation.date === dayTime;
        });

        const totalDailyPoints = HP(dailyPoints?.totalDailyPoints);
        if (totalDailyPoints.lte(0)) {
          continue;
        }

        const playerDailyPoints = HP(allDailyPoints[dayTime]);
        if (playerDailyPoints.lte(0)) {
          continue;
        }

        const dailyPointShare = playerDailyPoints.div(totalDailyPoints);
        const newAllocation = dailyPointShare.gt(1)
          ? // should not happen
            totalDailyTokenAllocation
          : totalDailyTokenAllocation.mul(dailyPointShare);

        memeHolding.claimableDailyTokens = HP(memeHolding.claimableDailyTokens)
          .add(newAllocation)
          .floor()
          .toString();

        memeHolding.convertedDailyPoints = HP(memeHolding.convertedDailyPoints)
          .add(playerDailyPoints)
          .floor()
          .toString();

        delete allDailyPoints[dayTime];
      }
    }

    // setting the point amount to the remaining amount of daily points (should only correspond to current day)
    memeHolding.pointAmount = getTotalDailyPoints(memeHolding).toString();
  });
}

export function initiateDailyTokenClaim(
  state: MutableState,
  memeId: string,
  time: number,
) {
  const memeHoldings = state.trading.offchainTokens[memeId];
  if (!memeHoldings) {
    throw new Error(`Offchain meme holdings missing, memeId: ${memeId}`);
  }

  if (isDailyTokenBeingClaimed(time, memeHoldings)) {
    throw new Error(`Daily tokens already being claimed, memeId: ${memeId}`);
  }

  memeHoldings.dailyPointsClaimOpStartTime = time;
}

export function initiateGradPointClaim(
  state: MutableState,
  memeId: string,
  time: number,
) {
  const memeHoldings = state.trading.offchainTokens[memeId];
  if (!memeHoldings) {
    throw new Error(`Offchain meme holdings missing, memeId: ${memeId}`);
  }

  if (isDailyTokenBeingClaimed(time, memeHoldings)) {
    throw new Error(
      `Graduation points already being claimed, memeId: ${memeId}`,
    );
  }

  memeHoldings.gradPointsClaimOpStartTime = time;
}

export function finalizeGraduationClaim(
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  payload: {
    memeId: string;
    memeName: string;
    ticker: string;
    walletAddress: string;
    tokenAmount: string;
    success: boolean;
  },
) {
  const wallet = state.trading.onchain.wallets[payload.walletAddress];
  if (!wallet) {
    return;
  }

  const memeHoldingsStatus = wallet.memeHoldings[payload.memeId];
  if (!memeHoldingsStatus) {
    return;
  }

  delete memeHoldingsStatus.graduationClaimTime;

  api.sendAnalyticsEvents([
    {
      eventType: 'token_claim_complete',
      eventProperties: {
        memecard_name: payload.memeName,
        cardID: payload.memeId,
        ticker: payload.ticker,
        // points_redeemed: N/A,
        type: 'graduateClaimFromJettons',
        amount_received: payload.tokenAmount,
        txn_success: payload.success,
      },
    },
  ]);
}

export function finalizePointClaim(
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  payload: {
    memeId: string;
    memeName: string;
    ticker: string;
    tokenAmount: string;
    success: boolean;
  },
) {
  const memeId = payload.memeId;
  const memeHoldings = state.trading.offchainTokens[memeId];

  let tokenAmount = payload.tokenAmount;

  const matchesGradClaim = tokenAmount === memeHoldings.claimableGradTokens;
  const matchesDailyClaim = tokenAmount === memeHoldings.claimableDailyTokens;

  const claimingGradPoints =
    memeHoldings.gradPointsClaimOpStartTime !== undefined;

  if (claimingGradPoints && (matchesGradClaim || !matchesDailyClaim)) {
    // expecting a graduation claim using points

    delete memeHoldings.gradPointsClaimOpStartTime;

    api.sendAnalyticsEvents([
      {
        eventType: 'token_claim_complete',
        eventProperties: {
          memecard_name: payload.memeName,
          cardID: payload.memeId,
          ticker: payload.ticker,
          points_redeemed: memeHoldings.convertedGradPoints,
          type: 'graduateClaimFromPoints',
          amount_received: payload.tokenAmount,
          txn_success: payload.success,
        },
      },
    ]);

    if (!payload.success) {
      // failed to claim the token
      // claimableGradTokens are not debited
      return;
    }

    if (matchesGradClaim) {
      // matches token amount
      memeHoldings.claimableGradTokens = '0';
      return;
    }

    console.error(
      `Token amount claimed from graduation points does not reflect player state. tx token amount: ${tokenAmount}, claimable tokens: ${memeHoldings.claimableGradTokens}, meme id: ${memeId}`,
    );
  }

  const claimingDailyPoints =
    memeHoldings.dailyPointsClaimOpStartTime !== undefined;

  if (!claimingDailyPoints) {
    // @note: this can happen when multiple watchers trigger this message
    return;
  }

  delete memeHoldings.dailyPointsClaimOpStartTime;

  const claimableDailyTokens = memeHoldings.claimableDailyTokens;

  if (!claimableDailyTokens || HP(claimableDailyTokens).lte(0)) {
    console.error(
      `No claimable daily tokens found on player state. claimable daily tokens: ${claimableDailyTokens} , meme id: ${memeId}`,
    );
    return;
  }

  // claimableDailyTokens exists and greater than 0

  if (HP(tokenAmount).gt(claimableDailyTokens)) {
    console.error(
      `Token amount claimed from daily points does not reflect player state. tx token amount: ${tokenAmount}, claimable tokens: ${claimableDailyTokens}, meme id: ${memeId}`,
    );

    tokenAmount = claimableDailyTokens;
  }

  // tokenAmount is smaller or equal to claimableDailyTokens

  const ratioOfTokensClaimed = HP(tokenAmount).div(claimableDailyTokens);

  const convertedDailyPoints = HP(memeHoldings.convertedDailyPoints);
  const dailyPointsUsed = convertedDailyPoints
    .mul(ratioOfTokensClaimed)
    .round();

  api.sendAnalyticsEvents([
    {
      eventType: 'token_claim_complete',
      eventProperties: {
        memecard_name: payload.memeName,
        cardID: payload.memeId,
        ticker: payload.ticker,
        points_redeemed: dailyPointsUsed,
        type: 'dailyClaim',
        amount_received: payload.tokenAmount,
        txn_success: payload.success,
      },
    },
  ]);

  if (!payload.success) {
    // failed to claim the token
    // claimableDailyTokens are not debited
    return;
  }

  memeHoldings.claimableDailyTokens = HP(memeHoldings.claimableDailyTokens)
    .minus(tokenAmount)
    .toString();

  memeHoldings.convertedDailyPoints = HP(memeHoldings.convertedDailyPoints)
    .minus(dailyPointsUsed)
    .toString();
}

export async function getTxWithHash(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  txHash: string,
) {
  // example of failed tx
  // txHash = '8e06a8a5dc46977519d648e968827448e91695d6772268d45027e584bc40dd28';
  // example of successful tx
  // txHash = '7df819699dc8d19d55035974d47dd2c95a3e4eefa6197fd4dd89b2aa17b9323d';

  const response = await api.fetch({
    url: `https://preview.toncenter.com/api/v3/events?msg_hash=${txHash}`,
    headers: {},
    method: 'GET',
  });

  const data = JSON.parse(response.body);
  return getTxResponseStatus(data);
}

async function getWalletTokenAmount(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  walletAddress: string,
  tokenAddress: string,
) {
  const encodedWalletAddress = encodeURIComponent(walletAddress);
  const encodedTokenAddress = encodeURIComponent(tokenAddress);
  const response = await api.fetch({
    url: `https://tonapi.io/v2/accounts/${encodedWalletAddress}/jettons/${encodedTokenAddress}`,
    headers: {},
    method: 'GET',
  });

  interface Response {
    events: [{ actions: [{ success: boolean }] }];
  }

  const data: Response = JSON.parse(response.body);
  return {
    data,
  };
}

async function getWalletAddressContent(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  walletAddress: string,
) {
  const encodedWalletAddress = encodeURIComponent(walletAddress);
  const response = await api.fetch({
    // url: `https://tonapi.io/v2/accounts/${encodedWalletAddress}/jettons?currencies=ton,usd,rub&supported_extensions=custom_payload`,
    url: `https://tonapi.io/v2/accounts/${encodedWalletAddress}/jettons`,
    headers: {},
    method: 'GET',
  });

  interface Response {
    events: [{ actions: [{ success: boolean }] }];
  }

  const data: Response = JSON.parse(response.body);
  return {
    data,
  };
}

const getTonToUSD = async (
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  try {
    const response = await api.fetch({
      url: 'https://walletbot.me/api/v1/transfers/price_for_fiat/?crypto_currency=TON&local_currency=USD&amount=1',
      headers: {},
      method: 'GET',
    });

    const data = JSON.parse(response.body);
    return data.rate as number;
  } catch {
    // return a historical rate
    return 5.5;
  }
};

type TokenHolder = {
  address: string;
  owner: {
    address: string;
    name: string;
    is_scam: boolean;
    icon: string;
    is_wallet: boolean;
  };
  balance: string;
};

async function getTokenHolders(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  tokenAddress: string,
  limit: number,
  offset: number = 0,
) {
  const encodedTokenAddress = encodeURIComponent(tokenAddress);
  const response = await api.fetch({
    url: `https://tonapi.io/v2/jettons/${encodedTokenAddress}/holders?limit=${limit}&offset=${offset}`,
    headers: {},
    method: 'GET',
  });

  // Adjust the interface if the response shape is different
  interface HolderResponse {
    total: number;
    addresses: TokenHolder[];
    events?: Array<{
      actions?: Array<{
        success: boolean;
      }>;
    }>;
  }

  const data: HolderResponse = JSON.parse(response.body);

  // Attempt to derive "success" from events/actions if needed
  const actions = data?.events?.[0]?.actions ?? [];
  const success = actions.length === 0 || actions.every((a) => a.success);

  return {
    holders: data.addresses ?? [],
    success,
  };
}

async function processHolders(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  tokenAddress: string,
  process: (tokenHolder: TokenHolder) => void,
) {
  let offset = 0;
  const limit = 1000;

  while (true) {
    const result = await retry(
      () => getTokenHolders(api, tokenAddress, limit, offset),
      {
        attempts: 3,
        // the retry needs a delay to avoid hitting limit rates
        backoff: { linear: { delay: 2000 } },
      },
    );

    // If we couldn't fetch at all (very unlikely, but just in case)
    if (!result) {
      break;
    }

    // If the API indicates it wasn't successful or no more data, break out
    if (!result.success || result.holders.length === 0) {
      break;
    }

    // Process each holder
    const holders = result.holders || [];
    for (const holder of holders) {
      process(holder);
    }

    // If we received fewer holders than our limit, we're done
    if (holders.length < limit) {
      break;
    }

    // Otherwise, move to the next batch
    offset += limit;
  }
}

// async function getTxData(api, txHash: string) {
//   // @todo: user api to perform the fetch if code reactivated
//   // const url = `https://tonapi.io/v2/jettons/${txHash}`;
//   // @note: this endpoint works but does not seem to provide the data we need!
//   const url = `https://tonapi.io/v2/blockchain/transactions/${txHash}`;
//   const response = await fetch(url);

//   interface Response {
//     events: [{ actions: [{ success: boolean }] }];
//   }

//   const data: Response = await response.json();
//   const actions = data?.events?.[0]?.actions ?? [];
//   return {
//     data,
//     actions: actions.length,
//     success: actions.every((a) => a.success),
//   };
// }

const confirmationEarlyErrorMsg = 'Tx cannot be confirmed yet';
async function confirmTx(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  txHash: string,
) {
  const res = await getTxWithHash(api, txHash);

  // Observed the buy action has 6 transactions but in theory after the first 3 we can consider it success
  if (res.retry) {
    throw new Error(confirmationEarlyErrorMsg);
  }

  return res;
}

const finalizePointClaims = (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  watcherOpts: WatcherOpts,
  memeDetails: MemeDetails,
  pointClaimTxs: DexTxData[],
) => {
  return pointClaimTxs.map(async (pointClaimTx) => {
    const receiver = pointClaimTx.receiver;
    if (!receiver) {
      return;
    }

    const walletUser = await findWalletUser(
      api,
      watcherOpts.userProfile,
      receiver,
    );
    if (!walletUser.playerFound) {
      // this should never happen
      console.error(
        `Non player was able to claim daily tokens. wallet address: ${receiver}, meme contract address: ${pointClaimTx.tokenAddress}`,
      );
      return;
    }

    if (walletUser.userId === state.id) {
      finalizePointClaim(state, api, {
        memeId: watcherOpts.memeId,
        memeName: memeDetails.name,
        ticker: memeDetails.ticker,
        tokenAmount: pointClaimTx.tokenAmount.toString(),
        success: pointClaimTx.status === 'success',
      });
    } else {
      api.scheduledActions.schedule.finalizePointClaim({
        targetUserId: walletUser.userId,
        notificationId: `finalizePointClaim.${pointClaimTx.hash}`,
        args: {
          memeId: watcherOpts.memeId,
          memeName: memeDetails.name,
          ticker: memeDetails.ticker,
          tokenAmount: pointClaimTx.tokenAmount.toString(),
          success: pointClaimTx.status === 'success',
        },
        delayInMS: 0,
      });
    }
  });
};

const finalizeGraduationClaims = (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  watcherOpts: WatcherOpts,
  memeDetails: MemeDetails,
  graduationClaims: DexTxData[],
) => {
  return graduationClaims.map(async (graduationClaim) => {
    const receiver = graduationClaim.receiver;
    if (!receiver) {
      return;
    }

    const walletUser = await findWalletUser(
      api,
      watcherOpts.userProfile,
      receiver,
    );
    if (!walletUser.playerFound) {
      // this should never happen
      console.error(
        `Non player was able to perform a graduation claim. wallet address: ${receiver}, meme contract address: ${graduationClaim.tokenAddress}`,
      );
      return;
    }

    if (walletUser.userId === state.id) {
      finalizeGraduationClaim(state, api, {
        memeId: watcherOpts.memeId,
        memeName: memeDetails.name,
        ticker: memeDetails.ticker,
        walletAddress: walletUser.walletAddress,
        tokenAmount: graduationClaim.tokenAmount.toString(),
        success: graduationClaim.status === 'success',
      });
    } else {
      api.scheduledActions.schedule.finalizeGraduationClaim({
        targetUserId: walletUser.userId,
        notificationId: `finalizeGraduationClaim.${graduationClaim.hash}`,
        args: {
          memeId: watcherOpts.memeId,
          memeName: memeDetails.name,
          ticker: memeDetails.ticker,
          walletAddress: walletUser.walletAddress,
          tokenAmount: graduationClaim.tokenAmount.toString(),
          success: graduationClaim.status === 'success',
        },
        delayInMS: 0,
      });
    }
  });
};

export const updateOnchainHoldings = (
  state: MutableState,
  args: {
    memeId: string;
    walletAddress: string;
    jettonContractAddress?: string;
    jettonTokenAmount?: string;
    dexContractAddress?: string;
    dexTokenAmount?: string;
  },
) => {
  const walletAddress = args.walletAddress;
  const wallet = getWallet(state, walletAddress);

  const memeHoldings = wallet.memeHoldings;

  const jettonTokenAmount = args.jettonTokenAmount;
  const dexTokenAmount = args.dexTokenAmount;
  const tokenAmount = HP(jettonTokenAmount).add(dexTokenAmount);

  let memeHoldingsStatus = memeHoldings[args.memeId];
  if (tokenAmount.lte(0)) {
    if (memeHoldingsStatus) {
      delete memeHoldings[args.memeId];
    }

    // nothing to do, player has no token
    return;
  }

  if (!memeHoldingsStatus) {
    memeHoldingsStatus = memeHoldings[args.memeId] = {
      tokenAmount: '0',
    };
  }

  memeHoldingsStatus.jettonContractAddress = args.jettonContractAddress;
  memeHoldingsStatus.jettonTokenAmount = jettonTokenAmount;

  memeHoldingsStatus.dexContractAddress = args.dexContractAddress;
  memeHoldingsStatus.dexTokenAmount = dexTokenAmount;

  memeHoldingsStatus.tokenAmount = tokenAmount.toString();
};

type JettonTxResult = {
  outcome: TxOutcome;
  tx: JettonTx;
  txHash: string;
};

type DexTxResult = {
  outcome: TxOutcome;
  tx: OnchainTx;
  txHash: string;
};

const updateAllHolders = async (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  memeId: string,
  jettonData: {
    tokenAddress: string;
    txs: JettonTxResult[];
  },
  dexData:
    | {
        tokenAddress: string;
        txs: DexTxResult[];
      }
    | undefined,
) => {
  // make list of users who performed new txs
  const holdingUpdatesMap: Record<string, Holding> = {};

  // the list of new txs is used only to determine who performed txs not how much they own
  const allNewTxs = dexData
    ? jettonData.txs.concat(dexData.txs)
    : jettonData.txs;
  allNewTxs.forEach((txResult) => {
    const tx = txResult.tx;
    if (txResult.outcome !== 'success') {
      return;
    }

    const walletAddress = tx.walletAddress;
    holdingUpdatesMap[walletAddress] = {
      walletAddress,
      tokenAmount: '0', // is filled later with current holdings
      userId: tx.userId,
      userName: tx.userName,
      userImage: tx.userImage,
    };
  });

  interface Holder {
    jettonTokenAmount?: string;
    dexTokenAmount?: string;
    tokenAmount: string;
  }

  // compute holdings for all users
  const holders: Record<string, Holder> = {};

  await processHolders(api, jettonData.tokenAddress, (tokenHolder) => {
    // @note: is this necessary? note that ton numbers are stored as BigInt while we store them as HP strings
    const jettonTokenAmount = HP(BigInt(tokenHolder.balance)).toString();
    holders[tokenHolder.owner.address] = {
      jettonTokenAmount,
      tokenAmount: jettonTokenAmount,
    };
  });

  if (dexData) {
    await processHolders(api, dexData.tokenAddress, (tokenHolder) => {
      // @note: is this necessary? note that ton numbers are stored as BigInt while we store them as HP strings
      const dexTokenAmount = HP(BigInt(tokenHolder.balance)).toString();
      const holder = holders[tokenHolder.address];
      if (holder) {
        holder.dexTokenAmount = dexTokenAmount;
        holder.tokenAmount = HP(dexTokenAmount)
          .add(holder.jettonTokenAmount)
          .toString();
      } else {
        holders[tokenHolder.owner.address] = {
          dexTokenAmount,
          tokenAmount: dexTokenAmount,
        };
      }
    });
  }

  let holderCount = 0;
  let sumOfHoldingsSquare = HP(0);
  let tokenSupply = HP(0);

  for (let holderAddress in holders) {
    const holdings = holders[holderAddress];
    const tokenAmount = holdings.tokenAmount;

    tokenSupply = tokenSupply.add(tokenAmount);
    sumOfHoldingsSquare = sumOfHoldingsSquare.add(
      HP(tokenAmount).mul(tokenAmount),
    );
    holderCount += 1;

    // update holding stats for users who performed new txs
    const userHoldingUpdate = holdingUpdatesMap[holderAddress];
    if (userHoldingUpdate) {
      userHoldingUpdate.tokenAmount = tokenAmount;
      userHoldingUpdate.jettonTokenAmount = holdings.jettonTokenAmount;
      userHoldingUpdate.dexTokenAmount = holdings.dexTokenAmount;
    }
  }

  api.sharedStates.tradingMeme.postMessage.updateHoldingStats(memeId, {
    holderCount,
    tokenSupply: tokenSupply.toString(),
    sumOfHoldingsSquare: sumOfHoldingsSquare.toString(),
  });

  const holdingUpdates = Object.values(holdingUpdatesMap);
  holdingUpdates.forEach((holdingUpdate) => {
    const userId = holdingUpdate.userId;
    if (!userId) {
      // transaction does not involve a wallet with a known user
      return;
    }

    const updatedHoldingStatus = {
      memeId,
      walletAddress: holdingUpdate.walletAddress,
      jettonContractAddress: jettonData.tokenAddress,
      jettonTokenAmount: holdingUpdate.jettonTokenAmount,
      dexContractAddress: dexData?.tokenAddress,
      dexTokenAmount: holdingUpdate.dexTokenAmount,
    };

    const isSelf = state.id === userId;
    if (isSelf) {
      updateOnchainHoldings(state, updatedHoldingStatus);
    } else {
      api.postMessage.updateOnchainHoldings(userId, updatedHoldingStatus);
    }
  });

  api.sharedStates.onchainHolders.postMessage.updateHolders(
    getOnchainHoldersStateId(memeId),
    {
      holdingUpdates,
    },
  );

  // track transactions on amplitude
  const tonToUSDRateRequest = getTonToUSD(api);
  const meme = await api.sharedStates.tradingMeme.fetch(memeId);
  const tonToUSD = await tonToUSDRateRequest;

  // at this point "meme" should always exists
  if (meme) {
    const memeState = meme.global;

    allNewTxs.forEach((txResult) => {
      const tx = txResult.tx;
      if (!tx.userId) {
        // tx not made by known user, cannot associate with a user id
        return;
      }

      const userHolding = holdingUpdatesMap[tx.walletAddress];
      const tokenAmountHeld = HP(userHolding?.tokenAmount);

      if (tx.txType === 'buy' || tx.txType === 'dexBuy') {
        const tokenAmountBought = tx.tokenAmount;
        const supplyBeforeTx = HP(memeState.tokenSupply).minus(
          tokenAmountBought,
        );
        const tonAmountInvested = calculateMintTonPrice(
          supplyBeforeTx,
          HP(tokenAmountBought),
          true,
        );
        const marketCapTon = getOnchainMarketCap(memeState.tokenPrice);

        const txSuccess = txResult.outcome === 'success';
        const tokenAmountHeldBefore = txSuccess
          ? tokenAmountHeld.minus(tokenAmountBought)
          : tokenAmountHeld;

        api.sendAnalyticsEvents([
          {
            userId: tx.userId,
            eventType: 'memecard_trade_complete',
            eventProperties: {
              transaction_type: 'buy',
              card_name: memeState.details.name,
              cardID: memeId,
              ticker: memeState.details.ticker,
              previous_owned: tokenAmountHeldBefore.toString(),
              current_owned: tokenAmountHeld.toString(),
              transaction_usd_value: tonAmountInvested.mul(tonToUSD).toString(),
              amount_invested: tonAmountInvested,
              amount_bought: tokenAmountBought,
              memecard_price: HP(tonAmountInvested)
                .div(tokenAmountBought)
                .toString(),
              total_holders: memeState.holderCount - 1,
              total_tokens_circulating: memeState.tokenSupply,
              market_cap_ton: marketCapTon,
              market_cap_usd: HP(marketCapTon).mul(tonToUSD).toString(),
              txn_success: txSuccess,
              tx_hash: txResult.txHash,
            },
          },
        ]);
      }

      if (tx.txType === 'sell' || tx.txType === 'dexSell') {
        const tokenAmountSold = tx.tokenAmount;
        const supplyBeforeTx = HP(memeState.tokenSupply).add(tokenAmountSold);
        const tonAmountDivested = calculateMintTonPrice(
          supplyBeforeTx,
          HP(tokenAmountSold),
          false,
        );
        const marketCapTon = getOnchainMarketCap(memeState.tokenPrice);

        const txSuccess = txResult.outcome === 'success';
        const tokenAmountHeldBefore = txSuccess
          ? tokenAmountHeld.add(tokenAmountSold)
          : tokenAmountHeld;

        api.sendAnalyticsEvents([
          {
            userId: tx.userId,
            eventType: 'memecard_trade_complete',
            eventProperties: {
              transaction_type: 'sell',
              card_name: memeState.details.name,
              cardID: memeId,
              ticker: memeState.details.ticker,
              previous_owned: tokenAmountHeldBefore,
              current_owned: tokenAmountHeld.toString(),
              transaction_usd_value: tonAmountDivested.mul(tonToUSD).toString(),
              amount_divested: tonAmountDivested,
              amount_bought: tokenAmountSold,
              memecard_price: HP(tonAmountDivested)
                .div(tokenAmountSold)
                .toString(),
              total_holders: memeState.holderCount - 1,
              total_tokens_circulating: memeState.tokenSupply,
              market_cap_ton: marketCapTon,
              market_cap_usd: HP(marketCapTon).mul(tonToUSD).toString(),
              txn_success: txSuccess,
              tx_hash: txResult.txHash,
            },
          },
        ]);
      }
    });
  }
};

const saveJettonTxs = async (
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  watcherOpts: WatcherOpts,
  memeDetails: MemeDetails,
  tokenAddress: string,
  newLastTxLogicalTime: string,
  lastTxLogicalTime: string,
  confirmedTxs: JettonTxData[],
): Promise<JettonTxResult[]> => {
  const onchainTxs = confirmedTxs.map(async (confirmedTx) => {
    // in most cases users will be the ones adding their tx data to the meme state
    let userId;
    let userName;
    let userImage;
    let walletAddress;

    const sender = confirmedTx.sender;
    if (sender) {
      const txUserProfile = await findWalletUser(
        api,
        watcherOpts.userProfile,
        sender,
      );

      userId = txUserProfile.userId;
      userName = txUserProfile.userName;
      userImage = txUserProfile.userImage;
      walletAddress = sender;
    } else if (confirmedTx.receiver) {
      // "receiver" should only be present on "deploy" tx
      walletAddress = confirmedTx.receiver;
      userId = '';
    }

    return {
      outcome: confirmedTx.status,
      txHash: confirmedTx.hash,
      tx: {
        // information obtained from tx
        txType: confirmedTx.type,
        createdAt: confirmedTx.createdAt,
        tokenAmount: confirmedTx.tokenAmount?.toString(),
        // information obtained from user state
        walletAddress,
        userId,
        userName,
        userImage,
      },
    } as JettonTxResult;
  });

  const jettonTxs = await Promise.all(onchainTxs);

  // console.error('SAVE JETTON TXS', {
  //   jettonTxs,
  //   // the "firstTxLogicalTime" is for the shared state to confirm that those transactions should be applied
  //   // confirmation is made by matching the new "firstTxLogicalTime" with the existing "lastTxLogicalTime"
  //   firstTxLogicalTime: lastTxLogicalTime,
  //   // the "lastTxLogicalTime" is for the next watcher iteration to use
  //   lastTxLogicalTime: newLastTxLogicalTime,
  // })

  api.sharedStates.tradingMeme.postMessage.addJettonTxs(watcherOpts.memeId, {
    jettonTxs: jettonTxs
      .filter((jettonTx) => jettonTx.outcome === 'success')
      .map((jettonTx) => jettonTx.tx),
    // the "firstTxLogicalTime" is for the shared state to confirm that those transactions should be applied
    // confirmation is made by matching the new "firstTxLogicalTime" with the existing "lastTxLogicalTime"
    firstJettonTxLogicalTime: lastTxLogicalTime,
    // the "lastTxLogicalTime" is for the next watcher iteration to use
    lastJettonTxLogicalTime: newLastTxLogicalTime,
  });

  await api.flushMessages();

  const meme = await api.sharedStates.tradingMeme.fetch(watcherOpts.memeId);
  if (!meme) {
    // this should not happen, meme state was retrieved earlier
    throw new Error(
      `runTxWatcher: could not retrieve meme ${watcherOpts.memeId}`,
    );
  }

  const memeState = meme.global;
  const updatedLastTxLogicalTime = memeState.lastJettonTxLogicalTime;
  if (updatedLastTxLogicalTime === newLastTxLogicalTime) {
    // all good, the user was responsible for updating the meme state
    return jettonTxs;
  }

  if (BigInt(updatedLastTxLogicalTime) > BigInt(newLastTxLogicalTime)) {
    // another watcher was able to save more transactions
    return [];
  }

  // another watcher was able to save fewer transactions
  // strip out transactions already processed by other watcher and rerun
  confirmedTxs = confirmedTxs.filter((confirmedTx) => {
    // @to-confirm: if transaction logical time differs by less than 50_000_000 then they must be from the same tx
    // return BigInt(confirmedTx.logicalTime) > (BigInt(updatedLastTxLogicalTime) + BigInt(50_000_000));
    return BigInt(confirmedTx.logicalTime) > BigInt(updatedLastTxLogicalTime);
  });

  return saveJettonTxs(
    api,
    watcherOpts,
    memeDetails,
    tokenAddress,
    updatedLastTxLogicalTime,
    lastTxLogicalTime,
    confirmedTxs,
  );
};

const saveDexTxs = async (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  watcherOpts: WatcherOpts,
  memeDetails: MemeDetails,
  tokenAddress: string,
  newLastTxLogicalTime: string,
  lastTxLogicalTime: string,
  confirmedTxs: DexTxData[],
): Promise<DexTxResult[]> => {
  const convertTx = async (
    confirmedTx: DexTxData,
    userWalletAddress: string,
    tonAmount: string,
  ) => {
    const txUserProfile = await findWalletUser(
      api,
      watcherOpts.userProfile,
      userWalletAddress,
    );

    return {
      outcome: confirmedTx.status,
      txHash: confirmedTx.hash,
      tx: {
        txType: confirmedTx.type,
        createdAt: confirmedTx.createdAt,
        tokenAmount: confirmedTx.tokenAmount.toString(),
        currencyAmount: tonAmount,
        // information obtained from user state
        walletAddress: txUserProfile.walletAddress,
        userId: txUserProfile.userId,
        userName: txUserProfile.userName,
        userImage: txUserProfile.userImage,
      },
    } as DexTxResult;
  };

  const pointClaimTxs: DexTxData[] = [];
  const graduationClaimTxs: DexTxData[] = [];

  const memeId = watcherOpts.memeId;

  // two "onchain" transactions are created for each confirmed tx, one for the buy and one for the sell
  const onchainTxs = confirmedTxs.reduce((onchainTxs, confirmedTx) => {
    if (
      confirmedTx.type === 'dexDeploy' ||
      confirmedTx.type === 'pointClaim' ||
      confirmedTx.type === 'graduationClaim'
    ) {
      if (confirmedTx.type === 'pointClaim') {
        pointClaimTxs.push(confirmedTx);
      }

      if (confirmedTx.type === 'graduationClaim') {
        graduationClaimTxs.push(confirmedTx);
      }

      const receiver = confirmedTx.receiver;
      if (receiver) {
        onchainTxs.push(convertTx(confirmedTx, receiver, '0'));
      } else {
        console.error(
          `No receiver found on "claim" transaction. tx hash: ${confirmedTx.hash}, meme id: ${memeId}, meme contract address: ${tokenAddress}`,
        );
      }
    }

    if (confirmedTx.type === 'dexBuy') {
      const buyer = confirmedTx.buyer;
      const tonAmount = fromNano(confirmedTx.tonAmount ?? 0);
      if (buyer && tonAmount) {
        onchainTxs.push(convertTx(confirmedTx, buyer, tonAmount));
      } else {
        if (!buyer) {
          console.error(
            `No buyer found on "dexBuy" transaction. tx hash: ${confirmedTx.hash}, meme id: ${memeId}, meme contract address: ${tokenAddress}`,
          );
        }
        if (!tonAmount) {
          console.error(
            `No tonAmount found on "dexBuy" transaction. tx hash: ${confirmedTx.hash}, meme id: ${memeId}, meme contract address: ${tokenAddress}`,
          );
        }
      }
    }

    if (confirmedTx.type === 'dexSell') {
      const seller = confirmedTx.seller;
      const tonAmount = fromNano(confirmedTx.tonAmount ?? 0);
      if (seller && tonAmount) {
        onchainTxs.push(convertTx(confirmedTx, seller, tonAmount));
      } else {
        if (!seller) {
          console.error(
            `No seller found on "dexBuy" transaction. tx hash: ${confirmedTx.hash}, meme id: ${memeId}, meme contract address: ${tokenAddress}`,
          );
        }
        if (!tonAmount) {
          console.error(
            `No tonAmount found on "dexBuy" transaction. tx hash: ${confirmedTx.hash}, meme id: ${memeId}, meme contract address: ${tokenAddress}`,
          );
        }
      }
    }

    return onchainTxs;
  }, [] as Promise<DexTxResult>[]);

  const pointClaimsFinalization = finalizePointClaims(
    state,
    api,
    watcherOpts,
    memeDetails,
    pointClaimTxs,
  );

  const graduationClaimsFinalization = finalizeGraduationClaims(
    state,
    api,
    watcherOpts,
    memeDetails,
    graduationClaimTxs,
  );

  await Promise.all(pointClaimsFinalization);
  await Promise.all(graduationClaimsFinalization);

  const dexTxs = await Promise.all(onchainTxs);

  api.sharedStates.tradingMeme.postMessage.addDexTxs(watcherOpts.memeId, {
    dexTxs: dexTxs
      .filter((dexTx) => dexTx.outcome === 'success')
      .map((dexTx) => dexTx.tx),
    // the "firstTxLogicalTime" is for the shared state to confirm that those transactions should be applied
    // confirmation is made by matching the new "firstTxLogicalTime" with the existing "lastTxLogicalTime"
    firstDexTxLogicalTime: lastTxLogicalTime,
    // the "lastTxLogicalTime" is for the next watcher iteration to use
    lastDexTxLogicalTime: newLastTxLogicalTime,
  });

  await api.flushMessages();

  const meme = await api.sharedStates.tradingMeme.fetch(watcherOpts.memeId);
  if (!meme) {
    // this should not happen, meme state was retrieved earlier
    throw new Error(
      `runTxWatcher: could not retrieve meme ${watcherOpts.memeId}`,
    );
  }

  const memeState = meme.global;
  const updatedLastTxLogicalTime = memeState.lastDexTxLogicalTime;
  if (updatedLastTxLogicalTime === newLastTxLogicalTime) {
    // all good, the user was responsible for updating the meme state
    return dexTxs;
  }

  if (BigInt(updatedLastTxLogicalTime) > BigInt(newLastTxLogicalTime)) {
    // another watcher was able to save more transactions
    return [];
  }

  // another watcher was able to save fewer transactions
  // strip out transactions already processed by other watcher and rerun
  confirmedTxs = confirmedTxs.filter((confirmedTx) => {
    return BigInt(confirmedTx.logicalTime) > BigInt(updatedLastTxLogicalTime);
  });

  return saveDexTxs(
    state,
    api,
    watcherOpts,
    memeDetails,
    tokenAddress,
    updatedLastTxLogicalTime,
    lastTxLogicalTime,
    confirmedTxs,
  );
};

export const runGivenTxWatcher = async (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  watcherOpts: WatcherOpts,
  memeDetails: MemeDetails,
  tokenType: 'jetton' | 'dex',
  tokenAddressNormalized: string,
  lastTxLogicalTime: string,
  liquidityPoolAddress?: string,
): Promise<{
  tokenAddress: string;
  txs: (JettonTxResult | DexTxResult)[];
}> => {
  const runDexWatcher = tokenType === 'dex';
  const txWatcher = new TransactionWatcher(
    tokenAddressNormalized,
    runDexWatcher,
  );
  const txData = await txWatcher.run({ toLogicalTime: lastTxLogicalTime });
  // buy and sell dex txs
  if (runDexWatcher) {
    if (liquidityPoolAddress) {
      const txSwapWatcher = new TransactionWatcher(
        liquidityPoolAddress,
        runDexWatcher,
      );
      const swapTxs = await txSwapWatcher.run({
        toLogicalTime: lastTxLogicalTime,
      });
      console.log('runGivenTxWatcher', { swapTxs, lastTxLogicalTime });
      // @TODO: fix the 'any'
      swapTxs.forEach((tx) => {
        txData.push(tx as any);
      });
    } else {
      console.warn(`missing liquidity pool for '${tokenAddressNormalized}'`, {
        memeDetails,
      });
    }
  }

  const tokenAddress = txWatcher.address.toRawString();
  if (txData.length === 0) {
    // all good, no new transaction to parse
    return {
      tokenAddress,
      txs: [],
    };
  }

  const txsOfInterest = txData.reduce((txsOfInterest, tx) => {
    // filter out unwanted transactions
    if (!tx) {
      return txsOfInterest;
    }

    const txOfInterest = tx as JettonTxData | DexTxData;
    if (!isValidTxType(txOfInterest.type)) {
      // tx not confirmed yet
      return txsOfInterest;
    }

    txsOfInterest.push(txOfInterest);
    return txsOfInterest;
  }, [] as (JettonTxData | DexTxData)[]);

  // order txs in chronological order from oldest to newest
  txsOfInterest.sort((a, b) => {
    const bigA = BigInt(a.logicalTime);
    const bigB = BigInt(b.logicalTime);

    if (bigA > bigB) return 1;
    if (bigA < bigB) return -1;
    return 0;
  });

  // the lastTxLogicalTime will be set to latest tx with no earlier transaction still being processed
  let newLastTxLogicalTime = lastTxLogicalTime;

  // @todo: record tx hashes
  // const txHashes: { txOutcome: TxOutcome; txHash: string }[] = [];
  const confirmedTxs: (JettonTxData | DexTxData)[] = [];
  for (let i = 0; i < txsOfInterest.length; i += 1) {
    const txOfInterest = txsOfInterest[i];
    if (txOfInterest.status === TxStatus.Processing) {
      // tx not confirmed yet, skip any transaction coming after this one
      // they will be included by another run of the watcher (either by this user or someone else)
      break;
    }

    if (BigInt(txOfInterest.logicalTime) <= BigInt(lastTxLogicalTime)) {
      const errorMsgPart1 = `Inconsistency detected: new onchain tx idenfitied with logical time earlier than last recorded logical time.`;
      const errorMsgPart2 = `MemeId: ${watcherOpts.memeId}, tx logical time: ${txOfInterest.logicalTime}, previous last tx logicial time: ${lastTxLogicalTime}`;
      throw new Error(`${errorMsgPart1}\n${errorMsgPart2}`);
    }

    // @todo: record tx hashes
    // if (txOfInterest.hash && txOfInterest.status) {
    //   txHashes.push({
    //     txOutcome: txOfInterest.status,
    //     txHash: txOfInterest.hash,
    //   });
    // }

    confirmedTxs.push(txOfInterest);

    // save all transactions up to that point
    newLastTxLogicalTime = txOfInterest.logicalTime;
  }

  // @todo: record tx hashes
  // @note: saving on the onchainTxs shared state does not work for some reason
  // const contractType = isGraduated ? 'dex' : 'jetton';
  // const recordTxRequests = txHashes.map((txHash) => {
  //   return retry(() => saveTx(api, memeId, contractType, txHash), { attempts: 1 })
  // });
  // await Promise.all(recordTxRequests);

  if (confirmedTxs.length === 0) {
    return {
      tokenAddress,
      txs: [],
    };
  }

  if (runDexWatcher) {
    const dexTxs = await saveDexTxs(
      state,
      api,
      watcherOpts,
      memeDetails,
      tokenAddress,
      newLastTxLogicalTime,
      lastTxLogicalTime,
      confirmedTxs as DexTxData[],
    );

    return {
      tokenAddress,
      txs: dexTxs,
    };
  } else {
    const jettonTxs = await saveJettonTxs(
      api,
      watcherOpts,
      memeDetails,
      tokenAddress,
      newLastTxLogicalTime,
      lastTxLogicalTime,
      confirmedTxs as JettonTxData[],
    );

    return {
      tokenAddress,
      txs: jettonTxs,
    };
  }
};

export const runTxWatcher = async (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  watcherOpts: WatcherOpts,
) => {
  const memeId = watcherOpts.memeId;
  const meme = await api.sharedStates.tradingMeme.fetch(memeId);
  if (!meme) {
    throw new Error(`runTxWatcher: could not find meme ${memeId}`);
  }

  const now = api.date.now();
  if (now - meme.global.lastOnchainTxUpdateTime < 5 * SEC_IN_MS) {
    // let's do one iteration of the watcher every 5s max
    // this should not be called nearly as often but it is to avoid concurrent requests
    return;
  }

  api.sharedStates.tradingMeme.postMessage.updateLastOnchainTxUpdateTime(
    memeId,
    {},
  );

  const memeState = meme.global;

  const isGraduated = memeState.isGraduated;
  const jettonContractAddress = memeState.jettonContractAddress;
  const dexContractAddress = memeState.dexContractAddress;
  const lastJettonTxLogicalTime = memeState.lastJettonTxLogicalTime || '0';
  const lastDexTxLogicalTime = memeState.lastDexTxLogicalTime || '0';
  const liquidityPoolAddress = meme.global.poolContractAddress;

  // to test given wallet address
  // const isGraduated = true;
  // const jettonContractAddress =
  //   '0:0898819fd9c2f4adb76ac616966274d2c661426a2c25d17357c63696eb3defa5';
  // const dexContractAddress =
  //   // '0:65073830ad92fa3d69d9028e443f7662ebacf5ca648dd75721ba47b80b29ec8f';
  //   // '0:2668922a2ac9140de8598cb4fe4900c258ad4acd8b34a38335fe278e976b6e86';
  //   // '0:65073830ad92fa3d69d9028e443f7662ebacf5ca648dd75721ba47b80b29ec8f';
  //   '0:9e251bf64b1993d000c4ddfd1d624cc3db9ace3552cbcf75dfbb90979dadd0f1';
  // const lastJettonTxLogicalTime = '0';
  // const lastDexTxLogicalTime = '0';

  // const jettonData = {
  //   tokenAddress: jettonContractAddress,
  //   txs: [],
  // };

  const jettonData = await runGivenTxWatcher(
    state,
    api,
    watcherOpts,
    memeState.details,
    'jetton',
    jettonContractAddress,
    lastJettonTxLogicalTime,
  );

  let dexData;
  if (isGraduated && dexContractAddress) {
    console.log('runTxWatcher', {
      isGraduated,
      dexContractAddress,
      liquidityPoolAddress,
    });
    dexData = (await runGivenTxWatcher(
      state,
      api,
      watcherOpts,
      memeState.details,
      'dex',
      dexContractAddress,
      lastDexTxLogicalTime,
      liquidityPoolAddress,
    )) as { tokenAddress: string; txs: DexTxResult[] };

    dexData.txs.forEach((dexTx) => {
      if (dexTx.tx.txType === 'dexDeploy' && dexTx.outcome === 'success') {
        api.sendAnalyticsEvents([
          {
            userId: memeState.details.creatorId,
            eventType: 'token_graduated',
            eventProperties: {
              memecard_name: memeState.details.name,
              cardID: memeId,
              ticker: memeState.details.ticker,
              reward_amount: creatorGraduationTonReward,
            },
          },
        ]);
      }
    });
  }

  await updateAllHolders(state, api, memeId, jettonData, dexData);
};

export const runTxConfirmation = async (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  const confirmedMemeWallets: Record<string, string> = {};

  const now = api.date.now();

  const wallets = state.trading.onchain.wallets;
  for (let walletAddress in wallets) {
    const wallet = wallets[walletAddress];
    const unconfirmedTxs = wallet.unconfirmedTxs;
    const remainingUnconfirmedTxs = [];

    // @note: the transaction confirmations should run sequentially to avoid limit rates
    for (let i = 0; i < unconfirmedTxs.length; i += 1) {
      const unconfirmedTx = unconfirmedTxs[i];
      try {
        await confirmTx(api, unconfirmedTx.txHash);

        // at this point the tx has completed (idenfitied as either failed or succeeded)
        // the meme state is ready to be updated
        confirmedMemeWallets[unconfirmedTx.memeId] = walletAddress;
      } catch (e) {
        if ((e as Error).message !== confirmationEarlyErrorMsg) {
          console.error(
            `Failed to verify transaction: ${(e as Error).message}`,
          );
        }

        const timeSinceCreation = now - unconfirmedTx.createdAt;
        if (timeSinceCreation > 24 * HOUR_IN_MS) {
          // give up on tx
          continue;
        }

        const retryDelay = (timeSinceCreation - minTxVerificationDelayMs) / 2;
        unconfirmedTx.verifDelayMs = Math.round(
          Math.max(3 * SEC_IN_MS, retryDelay),
        );

        remainingUnconfirmedTxs.push(unconfirmedTx);
      }
    }

    wallet.unconfirmedTxs = remainingUnconfirmedTxs;
  }

  for (let memeId in confirmedMemeWallets) {
    await runTxWatcher(state, api, {
      memeId: memeId,
      userProfile: {
        userId: state.id,
        userName: state.profile.name,
        userImage: state.profile.photo,
        walletAddress: confirmedMemeWallets[memeId],
      },
    });
  }

  return Object.keys(confirmedMemeWallets);
};

export const runTxConfirmationAndReschedule = async (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  await runTxConfirmation(state, api);

  const retryDelay = getTxVerificationRetryDelay(state);
  if (!isFinite(retryDelay)) {
    return;
  }

  api.scheduledActions.schedule.runTxConfirmation({
    args: {},
    notificationId: 'runTxConfirmation',
    delayInMS: retryDelay,
  });
};

export type WalletHoldings = {
  balances: WalletJetton[];
};

const updatePlayerSharedStateHoldings = (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  walletAddress: string,
  memeId: string,
  tokenAmountHeldNow: string,
  tokenAmountHeldBefore: string,
) => {
  if (tokenAmountHeldBefore === tokenAmountHeldNow) {
    return;
  }

  api.sharedStates.tradingMeme.postMessage.updateTokenAmountHeld(
    memeId.toString(),
    {
      tokenAmountHeldNow,
      tokenAmountHeldBefore,
    },
  );

  api.sharedStates.onchainHolders.postMessage.updateHolders(
    getOnchainHoldersStateId(memeId),
    {
      holdingUpdates: [
        {
          userId: state.id,
          userName: state.profile.name,
          userImage: state.profile.photo,
          walletAddress,
          tokenAmount: tokenAmountHeldNow,
        },
      ],
    },
  );

  // debug code
  // {
  //   const holdersState = await api.sharedStates.onchainHolders.fetch(
  //     getOnchainHoldersStateId(memeId),
  //   );
  //   console.error(
  //     'holders state (after)',
  //     getOnchainHoldersStateId(memeId),
  //     holdersState,
  //   );
  // }
};

const updateNonPlayerSharedStateHoldings = async (
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  walletAddress: string,
  tokenAddress: string,
  memeId: string,
  tokenAmountDiff: string,
) => {
  if (HP(tokenAmountDiff).eq(0)) {
    return;
  }

  // fetch user holdings
  const tokenHoldings = await getWalletTokenAmount(
    api,
    walletAddress,
    tokenAddress,
  );

  // compute holding diff
  const tokenAmountHeldNow = HP(
    (tokenHoldings.data as unknown as { balance: string }).balance,
  );
  const tokenAmountHeldBefore = tokenAmountHeldNow.minus(tokenAmountDiff);

  if (tokenAmountHeldBefore.lt(0)) {
    // this could happen if:
    // - a watcher computes an incomplete tokenAmountDiff
    // - or if the transaction data (from ton api) is not perfectly in sync with wallet content (from ton api)
    throw new Error(
      `Inconsistent holdings. wallet: ${walletAddress}, memeId: ${memeId}`,
    );
  }

  api.sharedStates.tradingMeme.postMessage.updateTokenAmountHeld(
    memeId.toString(),
    {
      tokenAmountHeldNow: tokenAmountHeldNow.toString(),
      tokenAmountHeldBefore: tokenAmountHeldNow.toString(),
    },
  );

  api.sharedStates.onchainHolders.postMessage.updateHolders(
    getOnchainHoldersStateId(memeId),
    {
      holdingUpdates: [
        {
          // note an identifiable user
          userId: '',
          // userName: state.profile.name,
          // userImage: state.profile.photo,
          walletAddress,
          tokenAmount: tokenAmountHeldNow.toString(),
        },
      ],
    },
  );

  // debug code
  // {
  //   const holdersState = await api.sharedStates.onchainHolders.fetch(
  //     getOnchainHoldersStateId(memeId),
  //   );
  //   console.error(
  //     'holders state (after)',
  //     getOnchainHoldersStateId(memeId),
  //     holdersState,
  //   );
  // }
};

// function parsePercentage(percentageStr: string) {
//   // percentageStr under format "0.00%"
//   const percentage = parseFloat(percentageStr.replace('%', ''));
//   // return a multiplicative ratio: 0 = 0%, 1 = 100%, 0.5 = 50%
//   return percentage / 100;
// }

// function getPriceFigures(priceData: PriceData) {
//   return {
//     usd: {
//       price: priceData.prices.USD,
//       diff24h: parsePercentage(priceData.diff_24h.USD),
//       diff7d: parsePercentage(priceData.diff_7d.USD),
//       diff30d: parsePercentage(priceData.diff_30d.USD),
//     },
//     // ton: {
//     //   price: priceData.prices.TON,
//     //   diff24h: parsePercentage(priceData.diff_24h.TON),
//     //   diff7d: parsePercentage(priceData.diff_7d.TON),
//     //   diff30d: parsePercentage(priceData.diff_30d.TON),
//     // },
//   };
// }

export const updateWalletHoldings = async (
  state: MutableState,
  walletAddress: string,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  try {
    const walletContent = await retry(
      () => getWalletAddressContent(api, walletAddress),
      {
        attempts: 3,
      },
    );

    const walletStatus = getWallet(state, walletAddress);
    const memeHoldingsStatuses = walletStatus.memeHoldings;
    const tokenBalances: Record<string, WalletJetton> = {};

    function updateMemeHoldingStatus(
      memeId: string,
      memeHoldingsStatus: WalletMemeHoldings,
    ) {
      const jettonAddress = memeHoldingsStatus.jettonContractAddress;
      const dexAddress = memeHoldingsStatus.dexContractAddress;

      const jettonTokenAmount = jettonAddress
        ? tokenBalances[jettonAddress]?.balance
        : undefined;
      const dexTokenAmount = dexAddress
        ? tokenBalances[dexAddress]?.balance
        : undefined;

      if (
        memeHoldingsStatus.jettonTokenAmount === jettonTokenAmount &&
        memeHoldingsStatus.dexTokenAmount === dexTokenAmount
      ) {
        // amount unchanged, no need to update player/meme states
        return;
      }

      // update amount of tokens held on meme state
      const tokenAmountHeldNow = HP(jettonTokenAmount)
        .add(dexTokenAmount)
        .toString();
      const tokenAmountHeldBefore = memeHoldingsStatus.tokenAmount;
      if (tokenAmountHeldNow !== tokenAmountHeldBefore) {
        updatePlayerSharedStateHoldings(
          state,
          api,
          walletAddress,
          memeId,
          tokenAmountHeldNow,
          tokenAmountHeldBefore,
        );
      }

      if (HP(tokenAmountHeldNow).lte(0)) {
        delete memeHoldingsStatuses[memeId];
        return;
      }

      // update amount of tokens held on player state
      if (dexAddress) {
        memeHoldingsStatus.dexContractAddress = dexAddress;
        memeHoldingsStatus.dexTokenAmount = dexTokenAmount;
      }

      if (jettonAddress) {
        memeHoldingsStatus.jettonContractAddress = jettonAddress;
        memeHoldingsStatus.jettonTokenAmount = jettonTokenAmount;
      }

      memeHoldingsStatus.tokenAmount = tokenAmountHeldNow;

      // meme handled
      delete tokenBalances[memeId];
    }

    const walletBalances = (walletContent.data as unknown as WalletHoldings)
      .balances;

    if (walletBalances.length === 0) {
      // has the effect of clearing all holdings
      for (let memeId in memeHoldingsStatuses) {
        updateMemeHoldingStatus(memeId, memeHoldingsStatuses[memeId]);
      }
      return [];
    }

    const jettonAddresses: string[] = [];
    const unidentifiedAddresses: string[] = [];
    walletBalances.forEach((balance) => {
      const jetton = balance.jetton;
      if (!jetton.address) {
        return;
      }

      const address = Address.normalize(jetton.address);
      if (jetton.symbol?.match(/^gemz_/)) {
        jettonAddresses.push(address);
      } else {
        unidentifiedAddresses.push(address);
      }

      tokenBalances[address] = balance;
    });

    const memes = (
      await api.sharedStates.tradingMeme.search({
        // we do not know whether the token address corresponds to a jetton or dex
        where: [
          {
            jettonContractAddress: {
              isAnyOf: jettonAddresses,
            },
          },
          {
            dexContractAddress: {
              isAnyOf: unidentifiedAddresses,
            },
          },
        ],
        limit: 10_000,
      })
    ).results;

    const gemzDexTokens: Record<string, boolean> = {};
    memes.forEach((meme) => {
      if (meme.dexContractAddress) {
        gemzDexTokens[meme.dexContractAddress] = true;
      }

      let memeHoldingsStatus = memeHoldingsStatuses[meme.id];
      if (memeHoldingsStatus) {
        // contract addresses need updating
        memeHoldingsStatus.jettonContractAddress = meme.jettonContractAddress;
        memeHoldingsStatus.dexContractAddress = meme.dexContractAddress;
      } else {
        memeHoldingsStatus = memeHoldingsStatuses[meme.id] = {
          jettonContractAddress: meme.jettonContractAddress,
          dexContractAddress: meme.dexContractAddress,
          tokenAmount: '0',
        };
      }

      updateMemeHoldingStatus(meme.id, memeHoldingsStatus);
    });

    walletStatus.lastHoldingCheck = api.date.now();

    state.trading.onchain.wallets[walletAddress] = walletStatus;

    // make list of non-gemz tokens
    const nonGemzTokens: WalletJetton[] = [];
    unidentifiedAddresses.forEach((unidentifiedAddress) => {
      const jetton = tokenBalances[unidentifiedAddress];
      if (!jetton || BigInt(jetton.balance) <= BigInt(0)) {
        return;
      }

      if (gemzDexTokens[unidentifiedAddress]) {
        return;
      }

      nonGemzTokens.push(jetton);
    });

    return nonGemzTokens;
  } catch (error) {
    console.error('Failed to update wallet holdings: ', error);

    return [];
  }
};
