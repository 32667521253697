import {
  getMyOffchainTokenPointAmount,
  getOnchainCurvePrice,
  Meme,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { TradingTokenListing } from '../../../replicant/features/tradingMeme/types';
import { MIN_IN_MS, SEC_IN_MS } from '../../../replicant/utils/time';
import { Optional } from '../../types';
import {
  SearchFilters,
  GetMemeStrategy,
  MemeFilters,
  UserMemeFilters,
} from './types';
import { TradingController } from './TradingController';
import { OwnedOffchainMeme } from '../../../replicant/features/game/player.schema';
import { analytics } from '@play-co/gcinstant';
import { delay } from '@play-co/replicant/lib/core/utils/AsyncUtils';
import { sendUserMemeGift, shareDeeplink } from '../../sharing';
import { TransactionSuccess } from '../UIController/UITypes';
import { TokenFactory } from './MemeFactory';
import { cai, isTiktokEnabled } from '../../utils';
import { MemeListController } from './MemeListsController';
import { SearchableMemeList } from './SearchableMemeList';
import { UserMemeList } from './UserMemeList';
import { ProfileEvents } from '../ProfileController';
import { BusinessController } from '../BusinessController';
import { MemeFeedAdController, MemeFeedAds } from './MemeFeedAds';
import { tests } from '../../../replicant/ruleset';
import { stage } from '../../../replicant/features/game/game.config';
import { getRandomElementFromArray } from '../../../replicant/utils/objects';
import ftueMemeSelection from '../../../replicant/features/powerups/airtable/ftueMemeSelection';
import { HP } from '../../../replicant/lib/HighPrecision';
import {
  memeSearchCount,
  memeTxUpdateMinDelay,
  onchainCurveConfig,
} from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';

interface SetCurrentOpts {
  tokenId?: string;
  filter?: MemeFilters;
}

export type FeedItemConfig =
  | {
      type: 'meme';
      item: TradingTokenListing;
    }
  | {
      type: 'ad';
      item: MemeFeedAdController;
    };

export type MemeOverviewSourceCategory = 'navigation' | 'share';
export type MemeOverviewSourceName =
  | MemeFilters
  | 'swipe'
  | 'update'
  | 'profile-list'
  | 'feed-list'
  | 'holder-list'
  | 'feed'
  | 'share'
  | 'back'
  | 'creation';

export type UserMemeGiftStatus = {
  alreadyClaimed: boolean;
  cannotFindUserState: boolean;
  expired: boolean;
  consolation: number;
  points: string;
};

export type GiftEntryData = {
  senderId: string;
  senderName: string;
  tokenId: string;
  shareTime: number;
  token?: Meme;
  subFeature: string;
};

interface MemeOverviewTrackingOpts {
  sourceCategory: MemeOverviewSourceCategory;
  sourceName: MemeOverviewSourceName;
  swipe?: 'up' | 'down';
  positionInFeed?: number;
}

const maxCacheValidity = 2 * MIN_IN_MS;
interface CacheControl {
  id: string;
  timestamp: number;
  data?: Record<string, any>;
}

export enum MemesEvents {
  OnReady = 'OnReady',
  OnUpdate = 'OnUpdate',
  // When the token is selected; via navigation or carousel
  OnTokenSelected = 'OnTokenSelected',
  // When are are updating the tx, i.e mode | amount | etc
  OnCurrentTokenUpdate = 'OnCurrentTokenUpdate',
  //
  OnListingUpdate = 'OnListingUpdate',
  OnFilterChange = 'OnFilterChange',

  // Trading
  TradingOnTxUpdate = 'TradingOnTxUpdate',
  TradingOnNotification = 'TradingOnNotification',
  TradingOnPortfolioUpdate = 'TradingOnPortfolioUpdate',
  TradingOnPriceUpdate = 'TradingOnPriceUpdate',

  // Token
  TokenClaimUpdate = 'TokenClaimUpdate',
}

export class MemesController extends BusinessController<MemesEvents> {
  private sendEventProxy = (evt: MemesEvents) => {
    this.sendEvents(evt);
  };

  private cache = {
    memes: {} as Record<string, CacheControl>,
  };

  private memes: Record<string, Meme> = {};

  private memeFetchRequests: Record<string, Promise<Meme | undefined>> = {};

  private memeTxWatcherTriggerTimes: Record<string, number> = {};

  private memeSources: Record<string, MemeOverviewSourceCategory> = {};

  private currentMemeId?: string;

  private searchString = '';

  private referrerTokenId = '';

  private currentSlideIndex = 0;

  private _userMemeGiftStatus: UserMemeGiftStatus = {
    alreadyClaimed: false,
    cannotFindUserState: false,
    expired: false,
    consolation: 0,
    points: '0',
  };

  public memeFeedAds: MemeFeedAds = new MemeFeedAds();

  private lists = {
    market: new MemeListController<SearchFilters>(this.app, {
      id: 'market',
      sendEvent: this.sendEventProxy,
      filters: ['Hot', 'New', 'Top'],
      defaultFilter: 'Hot',
      MemeListClass: SearchableMemeList,
      prefetch: 'waitFirst',
    }),
    userMemes: new MemeListController<UserMemeFilters>(this.app, {
      id: 'myMemes',
      sendEvent: this.sendEventProxy,
      filters: ['Created', 'Owned', 'Farming'],
      defaultFilter: 'Created',
      MemeListClass: UserMemeList,
      prefetch: 'async',
    }),
    myMemes: new MemeListController<UserMemeFilters>(this.app, {
      id: 'userMemes',
      sendEvent: this.sendEventProxy,
      filters: ['Created', 'Owned', 'Farming'],
      defaultFilter: 'Created',
      MemeListClass: UserMemeList,
    }),
  };

  private recentlyCreatedMeme?: Meme;

  private selectedList: keyof typeof this.lists = 'market';

  public get market() {
    return this.lists.market;
  }
  public get userMemes() {
    return this.lists.userMemes;
  }
  public get myMemes() {
    return this.lists.myMemes;
  }

  private _giftEntryData?: GiftEntryData;

  public get userMemeGift() {
    return {
      ...this._userMemeGiftStatus,
      ...this._giftEntryData,
    };
  }

  public setGiftEntryData = async (data: GiftEntryData) => {
    const token = await this.getMeme(data.tokenId, 'forceFetch');
    this._giftEntryData = { ...data, token };
    console.log('setGiftEntryData: complete', {
      _giftEntryData: this._giftEntryData,
    });
  };

  public sendGift = async (
    token: Meme,
    feature:
      | 'meme_gift_back'
      | 'ftue_share_gate'
      | 'sidebar_gift'
      | 'meme_claim'
      | 'meme_creation'
      | 'meme_tx'
      | 'meme_details',
  ) => {
    const shareTime = await this.app.invoke.generateUserMemeGift({
      memeId: token.id,
    });

    try {
      await sendUserMemeGift({
        memeId: token.id,
        tickerName: `$${token.ticker}`,
        shareTime,
        feature,
      });
    } catch (e) {
      console.error(e);
      this.app.track('SendGiftFailed', {
        memeId: token.id,
        shareTime,
      });
    }
  };

  public setUserMemeGiftStatus = (
    userMemeGiftStatus?: Partial<UserMemeGiftStatus>,
  ) => {
    this._userMemeGiftStatus = {
      alreadyClaimed: userMemeGiftStatus?.alreadyClaimed ?? false,
      cannotFindUserState: userMemeGiftStatus?.cannotFindUserState ?? false,
      expired: userMemeGiftStatus?.expired ?? false,
      consolation: userMemeGiftStatus?.consolation ?? 0,
      points: userMemeGiftStatus?.points ?? '0',
    };
  };

  public trading = new TradingController(this.app);

  public factory = new TokenFactory(this.app);

  public get currentMeme() {
    // Set all initial values to Optional<Type> or with its default
    const value = {
      meme: undefined as Optional<Meme>,
      listing: undefined as Optional<TradingTokenListing>,
      isOwner: false,
      offchainHoldings: undefined as Optional<OwnedOffchainMeme>,
    };
    // If not token is selected then return initial value
    if (!this.currentMemeId) {
      return value;
    }
    // Overwrite initial values and return
    value.meme = this.memes[this.currentMemeId];
    const item = this.currentList.getItem(this.currentMemeId);
    value.listing = item;
    value.isOwner = value.meme?.creatorId === this.app.state.id;
    value.offchainHoldings =
      this.app.state.trading.offchainTokens[this.currentMemeId];
    return value;
  }

  public setCurrentSlideIndex = (slideIndex: number) => {
    this.currentSlideIndex = slideIndex;
  };

  public get slideIndex() {
    return this.currentSlideIndex;
  }

  public get currentFilter() {
    return this.currentList.filter;
  }

  public get currentList() {
    const list = this.lists[this.selectedList];

    let listing = list.listing;
    if (
      list.id === 'market' &&
      list.filter === 'New' &&
      this.recentlyCreatedMeme
    ) {
      listing = [
        {
          creatorId: this.recentlyCreatedMeme.creatorId,
          creatorName: this.recentlyCreatedMeme.creatorName,
          creatorImage: this.recentlyCreatedMeme.creatorImage || '',
          image: this.recentlyCreatedMeme.image,
          name: this.recentlyCreatedMeme.name,
          ticker: this.recentlyCreatedMeme.ticker,
          shares: 0,
          lastTx: {
            createdAt: Date.now(),
            currencyAmount: '0',
            txType: 'deploy',
            walletAddress: this.app.ton.walletAddress ?? '',
            userId: this.app.playerId,
            tokenAmount: '0',
          },
          priceChange: {
            last1hour: 0,
            last24hours: 0,
          },
          offchainTokenId: this.recentlyCreatedMeme.id,
          marketCap: HP(0),
        },
        ...listing,
      ];
    }
    // console.log('currentList', {
    //   listing,
    //   list,
    //   recentlyCreatedMeme: this.recentlyCreatedMeme,
    // });

    return {
      listing: [...list.listing],
      paginate: list.paginate,
      getItem: list.getItem,
      filter: list.filter,
      firstItem: list.firstItem,
      refresh: list.refresh,
      getSearchTermLength: list.getSearchTermLength,
      search: list.search,
      setFilter: list.setFilter,
      isEndOfList: list.isEndOfList,
      getItemRenderZone: list.getItemRenderZone,
    };
    // return {
    //   ...list,
    // }
  }

  public get myMemesCount() {
    return Object.keys(this.app.state.trading.offchainTokens).length;
  }

  public init = async () => {
    if (!this.app.replicant) {
      console.warn(
        `MemesController: Trying to 'init' without replicant, abort.`,
      );
      return;
    }

    this.memeFeedAds.init();

    await this.trading.init({ sendEvtProxy: this.sendEventProxy });

    // No need to pre init 'userMemes'
    await this.market.init();
    // Select the first item in market
    this.setCurrent({});
    // Await until our profile is fetched to init my memes
    this.app.profile.isReady.then(() => {
      this.myMemes.init();
      this.userMemes.init();
    });

    this.app.profile.addEventListener(ProfileEvents.OnUpdate, () => {
      if (this.app.profile.current?.isSelf === false) {
        this.userMemes.refresh();
      }
    });

    this.app.views.TiktokPage.onVisibilityChange((isShowing) => {
      if (isShowing) {
        this.currentList.refresh();
      }
    });

    this.addEventListener(MemesEvents.OnListingUpdate, () => {
      if (this.recentlyCreatedMeme && this.currentList.filter === 'New') {
        const hasListing = this.currentList.listing.find(
          (x) => x.offchainTokenId === this.recentlyCreatedMeme?.id,
        );
        if (hasListing) {
          this.recentlyCreatedMeme = undefined;
        }
      }
    });

    this.onInitComplete();
    this.sendEventProxy(MemesEvents.OnReady);
  };

  public setReferredMeme = (tokenId: string) => {
    this.referrerTokenId = tokenId;
  };

  public getReferredTokenId = () => {
    return this.referrerTokenId;
  };

  private getListFirstItem = () => {
    return this.currentList.firstItem;
  };

  /**
   * @param list
   * @returns false if 'selectedList' has not changed value; true if it did
   */
  private setSelectedList = (list: typeof this.selectedList) => {
    if (this.selectedList === list) {
      return false;
    }
    this.selectedList = list;
    return true;
  };
  /**
   * @param nextFilter
   * @returns false if 'selectedList' and filter have not changed value; true if it did
   */
  private computeSelectedList = ({ tokenId, filter }: SetCurrentOpts) => {
    let newSelectedList = this.selectedList;
    if (filter) {
      if (this.market.getIsMyFilter(filter)) {
        newSelectedList = 'market';
      } else if (this.app.profile.current?.isSelf) {
        newSelectedList = 'myMemes';
      } else {
        newSelectedList = 'userMemes';
      }
    }
    // If we changed the selected list then it's dirty
    if (this.setSelectedList(newSelectedList)) {
      return true;
    }
    // Otherwise
    const isNotTheSameFilter = this.currentFilter !== filter;
    const isNotTheSameTokenId = this.currentMemeId !== tokenId;
    // if the filter or the token id has changed then it's dirty
    return isNotTheSameFilter || isNotTheSameTokenId;
  };

  // To be used by navigation and the carousel
  public setCurrent = async (
    opts: SetCurrentOpts,
    trackingOpts?: MemeOverviewTrackingOpts | undefined,
  ) => {
    const isDirty = this.computeSelectedList(opts);
    if (!isDirty) {
      return;
    }

    const { tokenId, filter } = opts;
    if (filter) {
      await this.currentList.setFilter(filter);
      if (isDirty) {
        this.sendEventProxy(MemesEvents.OnFilterChange);
      }
      const nextTokenId = tokenId ?? this.getListFirstItem()?.offchainTokenId;
      if (nextTokenId) {
        await this.setMeme(nextTokenId, trackingOpts);
      }
    } else {
      if (tokenId || !isTiktokEnabled()) {
        await this.setMeme(tokenId, trackingOpts);
      }
    }

    // @TODO: what's this for again???
    // if (newToken) {
    //   this.trading.onCurrentTokenChange(await this.getMeme())
    // }
    this.sendEvents(MemesEvents.OnTokenSelected);
  };

  /**
   * TEMP (this should be done with events)
   */
  public onTokenCreated = (meme: Meme) => {
    this.myMemes.refreshTargetList('Created');
    this.app.profile.refresh();
    this.recentlyCreatedMeme = meme;

    setTimeout(() => {
      this.market.refresh();
    }, 300);
  };

  public onTokenBuyOrSell = (tokenId: string) => {
    this.myMemes.refreshTargetList('Owned');
  };

  public shareOffchainToken = async (
    screen_location: string,
    props: TransactionSuccess | undefined,
    isTiktok: Optional<'isTiktok'> = undefined,
    skipShare = false,
  ) => {
    if (!props || !props.memeId) {
      return;
    }

    if (isTiktok) {
      this.app.replCollector.updateShare(props.memeId);
    }

    const myToken = this.app.state.trading.offchainTokens[props.memeId];
    const tokenBalance = myToken ? HP(myToken.pointAmount).toNumber() : 0;

    this.app.track('memecard_share', {
      screen_location,
      memecard_name: props.memeName,
      token_balance: tokenBalance,
      cardID: props.memeId,
      action: props.mode,
    });

    await analytics.flush();

    // leave time to flush (just in case)
    await delay(500);

    return shareDeeplink('TradingTokenPage', {
      messageOpts: {
        title: props?.memeName as unknown as string,
        text: props?.memeDescription as unknown as string,
      },
      deeplinkOpts: this.app.nav.getDeeplinkOpts('TradingTokenPage'),
      skipShare,
    });
  };

  public searchToken = (searchTerm: string) => {
    this.searchString = searchTerm;
    this.currentList.search(this.searchString);
  };

  public getMemeState = (tokenId = this.currentMeme.meme?.id) => {
    if (!tokenId) {
      return undefined;
    }
    return this.app.state.trading.offchainTokens[tokenId];
  };

  /**
   *
   * @param tokenId (optional) defaults to currentMemeId
   * @param strategy (`fetch`|`forceFetch`|`fetchAndUpdate`|`cacheOnly`) defaults to `cacheOnly`
   *
   * @strategy `fetch` - awaits for the token to be fetched then return it (respects cache ttl)
   *
   * @strategy `forceFetch` - aawaits for the token to be fetched then return it (bypass cache ttl)
   *
   * @strategy `fetchAndUpdate` (use for current token) - send a fetch request which will send an event once updated and return cached token (respects cache ttl)
   *
   * @strategy `cacheOnly` - get the current cached value
   */
  public getMeme = async (
    memeId = this.currentMemeId,
    strategy: GetMemeStrategy = 'cacheOnly',
  ) => {
    if (!memeId) {
      return undefined;
    }
    const cachedToken = this.memes[memeId];
    switch (strategy) {
      case 'cacheOnly':
        return cachedToken;
      case 'fetchAndUpdate':
        this.fetchAndUpdateMeme(memeId);
        return cachedToken;
      case 'fetch':
        await this.fetchAndUpdateMeme(memeId);
        return this.memes[memeId];
      case 'forceFetch':
        await this.fetchAndUpdateMeme(memeId, 'forceRefetch');
        return this.memes[memeId];
      default:
        return undefined;
    }
  };

  private trackOffchainTokenOverview = async (
    token: Meme,
    opts: MemeOverviewTrackingOpts,
  ) => {
    const introSource = this.memeSources[token.id];

    const firstVisit = introSource === undefined;
    if (firstVisit) {
      this.memeSources[token.id] = opts.sourceCategory;
    }

    const pointPrice = HP(token.pointPrice).toNumber();

    this.app.track('memeoffchainToken_overview_page', {
      first: firstVisit,
      memecard_intro_source: introSource ?? opts.sourceCategory,
      meme_overview_count: Object.keys(this.memeSources).length,
      swipe: opts.swipe,
      cardID: this.currentMemeId,
      memecard_ticker: token.ticker,
      memecard_name: token.name,
      memecard_creator: token.creatorId,
      current_price: pointPrice,
      current_owned: HP(
        getMyOffchainTokenPointAmount(this.app.state, token.id),
      ).toNumber(),
      total_holders: token.holderCount,
      source_name: opts.sourceName,
      position: opts.positionInFeed,
      search_term_length: this.currentList.getSearchTermLength(),
    });
  };

  private setMeme = async (
    memeId?: string,
    trackingOpts?: MemeOverviewTrackingOpts,
  ) => {
    this.currentMemeId = memeId;
    // Allow undefined so we can support non tiktok
    if (!memeId) {
      return;
    }

    const fetchTokenRequest = this.fetchAndUpdateMeme(memeId);

    if (trackingOpts) {
      fetchTokenRequest.then((offchainToken) => {
        if (!offchainToken) {
          return;
        }

        this.trackOffchainTokenOverview(offchainToken, trackingOpts);
      });
    }

    if (!this.currentMeme.meme) {
      await fetchTokenRequest;
    }

    // any client can trigger an update on latest onchain transactions
    this.runMemeTxWatcher();

    // No need to send update event here; 'fetchAndUpdateMeme' will take care of it
  };

  public refreshMemes = (memeIds: string[]) => {
    memeIds.forEach((memeId) => {
      this.createFetchAndUpdateRequest(memeId);
    });
  };

  private runMemeTxWatcher = async () => {
    const meme = this.currentMeme.meme;
    if (!meme) {
      return;
    }

    const now = this.app.now();

    const timeSinceLastTxUpdate = now - meme.lastOnchainTxUpdateTime;
    if (meme.isMinted) {
      if (timeSinceLastTxUpdate < memeTxUpdateMinDelay) {
        return;
      }
    } else {
      // not minted,
      if (timeSinceLastTxUpdate < 30 * MIN_IN_MS) {
        return;
      }

      // check for transaction to determine if meme was minted
      // the player who minted the meme might have closed their app during the transaction confirmation
      // leading to the meme being minted but not without triggering the watcher
    }

    const lastTriggerTime = this.memeTxWatcherTriggerTimes[meme.id] ?? 0;
    const timeSinceLastTrigger = now - lastTriggerTime;
    if (timeSinceLastTrigger < 120 * SEC_IN_MS) {
      return;
    }

    this.memeTxWatcherTriggerTimes[meme.id] = now;

    await this.app.invoke.runTxWatcherAsync({
      memeId: meme.id,
      creator: {
        userId: meme.creatorId,
        userName: meme.creatorName,
        userImage: meme.creatorImage,
        walletAddress: meme.creatorWalletAddress,
      },
    });
  };

  private updateMemeSupply = async (meme: Meme) => {
    // fetch supply
    const tokenSupply = await this.app.ton.getJettonSupply(
      meme.jettonContractAddress,
    );
    meme.tokenSupply = tokenSupply.toString();

    // check for graduation
    if (tokenSupply >= onchainCurveConfig.graduationSupplyThreshold) {
      this.app.ui.addGraduateMeme(meme.id);
      this.app.ton.triggerGraduation(meme.id).then(async () => {
        // wait for meme state to update
        await new Promise((resolve) => setTimeout(resolve, 5000));

        // refetch the meme after it was given enough time to update on replicant side
        await this.createFetchAndUpdateRequest(meme.id);
      });
    } else {
      this.app.ui.removeGraduateMeme(meme.id);
    }
  };

  private createFetchAndUpdateRequest = async (
    memeId: string,
  ): Promise<Meme | undefined> => {
    const meme = await this.app.asyncGetters.getMeme({
      memeId,
    });

    if (!meme) {
      return;
    }

    cai('createFetchAndUpdateRequest', { meme });

    if (meme.isMinted) {
      const marketCapUsdRequest = this.app.ton.getTonToUSD(meme.marketCapTon);

      if (!meme.isGraduated) {
        // @todo: find a way not to await in order to make UI more responsive?
        await this.updateMemeSupply(meme);
      } else {
        this.app.ui.removeGraduateMeme(meme.id);
      }

      const marketCapUsd = await marketCapUsdRequest;
      meme.marketCapUsd = marketCapUsd.toString();
    }

    // Update request cache
    this.cache.memes[memeId] = {
      id: memeId,
      timestamp: this.app.now(),
    };
    // Update token value
    this.memes[memeId] = meme;

    return meme;
  };

  private fetchAndUpdateMeme = async (
    memeId: string,
    forceRefetch: Optional<'forceRefetch'> = undefined,
  ) => {
    const cacheNotExpired = !this.isCacheExpired(memeId);
    const skipRefetch = cacheNotExpired && !forceRefetch;

    if (skipRefetch) {
      return this.memes[memeId];
    }

    let memeFetchAndUpdateRequest = this.memeFetchRequests[memeId];
    if (forceRefetch || !memeFetchAndUpdateRequest) {
      // avoid multiple simultaneous requests
      memeFetchAndUpdateRequest = this.createFetchAndUpdateRequest(memeId);
      this.memeFetchRequests[memeId] = memeFetchAndUpdateRequest;

      memeFetchAndUpdateRequest.finally(() => {
        // delete immediately after fetch is complete
        // note that the meme cache will take over if necessary
        delete this.memeFetchRequests[memeId];
      });
    }

    const meme = await memeFetchAndUpdateRequest;
    return meme;
  };

  private isCacheExpired = (memeId: string) => {
    const cache = this.cache.memes[memeId];
    // If we don't have cache then flag as expired so we set a new one
    if (!cache) {
      return true;
    }

    const now = this.app.now();

    const ttl = cache.timestamp + maxCacheValidity;

    const isExpired = ttl <= now;

    return isExpired;
  };

  public curateFeedWithAds = (
    memeItems: TradingTokenListing[],
  ): FeedItemConfig[] => {
    let feedItems: FeedItemConfig[] = [];

    let carouselIdx = 0;
    for (let i = 0; i < memeItems.length; i += 1) {
      while (this.memeFeedAds.isFeedItemAnAd(this.currentFilter, carouselIdx)) {
        feedItems[carouselIdx] = {
          type: 'ad',
          item: this.memeFeedAds.getMemeAdController(
            this.currentFilter,
            carouselIdx - i,
          ),
        };
        carouselIdx += 1;
      }

      feedItems[carouselIdx] = {
        type: 'meme',
        item: memeItems[i],
      };
      carouselIdx += 1;
    }

    return feedItems;
  };

  public getFtueShareInitialMeme = async () => {
    if (stage !== 'prod') {
      const hotMeme = await this.app.asyncGetters.getHotMeme({
        field: 'availableAt',
        unwantedTokenIds: [],
      });

      return hotMeme?.id;
    }

    return getRandomElementFromArray(ftueMemeSelection as string[]);
  };
}
