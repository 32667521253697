import './FriendsPage.scss';
import { Page } from '../Page';
import { useEffect } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { FriendsCard } from './FriendsCard';
import { assets } from '../../../assets/assets';
import { getFriendReferralRewards } from '../../../replicant/features/game/game.getters';
import { ButtonInvite } from '../../shared/ButtonInvite/ButtonInvite';
import { useTranslation } from 'react-i18next';
import { League } from '../../../replicant/features/game/ruleset/league';
import { formatPrice } from '../../../replicant/utils/numbers';
import { tests } from '../../../replicant/ruleset';

export const FriendsPage = () => {
  useAppUpdates({
    id: 'app',
    listener: app.views.Friends.attachEventListener(),
  });

  const { t } = useTranslation();

  const { data, loading, visible, fetch } = app.views.Friends;

  // @todo: remove (POST SEASON 2 MIGRATION)
  // useEffect(() => {
  //   if (visible) fetch();
  // }, [visible]);

  // const ruleset = app.replicant?.state.ruleset;
  // const bucketId = ruleset.abTests[tests.TEST_TIKTOK_ONLY]?.bucketId;

  // const onOpenDetails = () => {
  //   if (bucketId === 'control') {
  //     app.ui.drawer.show({ id: 'friendsDetails' });
  //   }
  // };

  const numOfFriends = data?.friendCount ?? 0;

  const reward = getFriendReferralRewards(app.state, app.now(), League.league1);

  return (
    <Page id="friends" visible={visible}>
      <div className="friends-header">
        {/* header image */}
        <div className="friends-header-image">
          <img src={assets.header_friends} width={300 / 2} height={280 / 2} />
        </div>
        {/* header title */}
        <div className="friends-header-title">
          {numOfFriends === 1
            ? t('friends_count_one')
            : t('friends_count', { count: numOfFriends })}
        </div>
        {/* header subtitle */}
        <div className="friends-header-subtitle">{t('friends_subtitle')}</div>
      </div>

      <div
        className="friends-cta"
        // @todo: remove (POST SEASON 2 MIGRATION)
        // onClick={onOpenDetails}
      >
        {/* --- */}
        <div className="friend-cta-item">
          <div className="icon-1">
            <img src={assets.coin} />
          </div>
          <div className="info-1">
            <div className="title">{t('friends_info_title')}</div>
            <div className="info-2">
              <div className="icon-2">
                <img src={assets.coin} />
              </div>
              <div className="info-3">
                <span>
                  {t('friends_info_subtitle', {
                    amount: formatPrice(reward.regular),
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
        <div className="separator"></div>
        {/* --- */}
        <div className="friend-cta-item">
          <div className="icon-1">
            <img src={assets.telegram_premium} />
          </div>
          <div className="info-1">
            <div className="title">{t('friends_info_premium_title')}</div>
            <div className="info-2">
              <div className="icon-2">
                <img src={assets.coin} />
              </div>
              <div className="info-3">
                {t('friends_info_premium_subtitle', {
                  amount: formatPrice(reward.premium),
                })}
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
        {/* @todo: remove (POST SEASON 2 MIGRATION) */}
        {/* {bucketId === 'control' && (
          <div className="opendetails">{t('friends_open_details')}</div>
        )} */}
      </div>

      <div className="footer-buttons">
        <ButtonInvite
          caption={t('friends_invite_button')}
          feature={'friend'}
          subFeature="friend_page"
        />
      </div>
      <div className="team-list friends">
        {loading && <p className="loading-label">{t('loading')}</p>}
        {!loading &&
          (data?.friends || []).map((friend, i) => (
            <FriendsCard key={friend.username + i} {...friend} />
          ))}
      </div>
    </Page>
  );
};
