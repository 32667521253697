import { assets } from '../../assets/assets';
import { constructFollowingSubStateId } from '../../replicant/features/followings/followings.getters';
import { maxFollowingsCount } from '../../replicant/features/followings/followings.ruleset';
import { resolveProfilePicture } from '../../replicant/features/game/game.getters';
import {
  OwnedOffchainMeme,
  UnconfirmedTx,
  WalletMemeHoldings,
} from '../../replicant/features/game/player.schema';
import {
  computeOnchainPortfolioValue,
  getOnchainProfitLoss,
  getTMGFarmingListing,
  getOnchainTradingVolume,
  getRoiSinceThen,
  getMyOnchainMemeIds,
  getMyOffchainMemeIds,
  getWalletHoldings,
  calculateMintTonPrice,
  getPriceBackThen,
  getSupplyBackThen,
  getOnchainHoldingValueNowAndThen,
  getPendingTxs,
} from '../../replicant/features/tradingMeme/tradingMeme.getters';
import { TradingSearchResult } from '../../replicant/features/tradingMeme/tradingMeme.properties';
import {
  TMGFarmingStatus,
  WalletJetton,
} from '../../replicant/features/tradingMeme/types';
import { SliceConfig } from '../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { State } from '../../replicant/schema';
import {
  getOffchainMemeHoldCount,
  getOffchainMemeHoldingStats,
} from '../memeUtils';
import { shareDeeplink } from '../sharing';
import { Fn } from '../types';
import { cai, waitFor } from '../utils';
import { BusinessController } from './BusinessController';
import { TonEvents } from '../TonProvider/TonController';
import { HP } from '../../replicant/lib/HighPrecision';
import { HOUR_IN_MS } from '../../replicant/utils/time';

import { t } from 'i18next';

export type FarmingSearchResult = TradingSearchResult & {
  state?: TMGFarmingStatus;
  progress?: {
    hoursLeft: number;
    minutesLeft: number;
  };
  points?: number;
};
type ProfileData = {
  userId: string;
  state: State;
  walletAddress: string | undefined;
  ownedMemes: {
    created: TradingSearchResult[];
    ownedTokens: TradingSearchResult[];
    ownedPoints: TradingSearchResult[];
  };
};

export enum ProfileEvents {
  OnUpdate = 'OnUpdate',
}

export class ProfileController extends BusinessController<ProfileEvents> {
  // @TODO: Do we want to cache, if yes for how long, any of this data we want to update more often?
  private profileCaches: Record<string, ProfileData> = {};

  private currentUserId?: string;

  private tokenFarmCache: Record<string, TradingSearchResult> = {};

  private swappableTokens: WalletJetton[] = [];

  private updateWalletHoldingRequest: Record<
    string,
    Promise<WalletJetton[]> | undefined
  > = {};

  // Internal use
  private get currentProfile() {
    if (!this.currentUserId) {
      return undefined;
    }
    return this.profileCaches[this.currentUserId];
  }

  private initialFollowing = false;
  private optmisticFollowing = false;

  private followLoading = false;
  get updatingFollow() {
    return this.followLoading;
  }

  get current() {
    if (!this.currentProfile) {
      return undefined;
    }

    if (!this.currentProfile.state) {
      return undefined;
    }

    const { profile, followingsCount, followersCount, balance } =
      this.currentProfile.state;

    const { ownedMemes, userId } = this.currentProfile;

    return {
      id: userId,
      name: profile.name,
      picture: resolveProfilePicture(userId, profile.photo),
      followings: this.followings,
      following: followingsCount,
      followers: followersCount,
      tokensCreated: ownedMemes.created || [],
      tokensOwned: ownedMemes.ownedTokens || [],
      pointsOwned: ownedMemes.ownedPoints || [],
      balance,
      portfolio: {
        value: this.getPortfolioValue() || 0,
        diff: this.getPortfolioRoi() || 0,
        profitLoss: this.getProfitLoss() || 0,
        tradingVolume: this.getTradingvolume() || 0,
      },
      isSelf: this.isSelf(),
      isFollowing: this.optmisticFollowing,
      tokensFarming: this.getTokensFarming(),
    };
  }

  private followDebounce?: NodeJS.Timeout;

  private initialProfileResolver!: Fn<void>;
  private initialProfilePromise = new Promise((resolve) => {
    this.initialProfileResolver = resolve;
  });

  private followings: string[] = [];

  public init = async () => {
    this.profileCaches = {} as Record<string, ProfileData>;

    await this.setProfile(this.app.state.id);
    this.initialProfileResolver();

    this.onInitComplete();

    this.app.ton.addEventListener(TonEvents.OnWalletConnectUpdate, () => {
      if (this.isSelf()) {
        this.updateWalletHoldings();
        this.refreshUser();
      }
    });
  };

  public getSwappableTokens = () => {
    if (this.current?.isSelf) {
      return this.swappableTokens;
    }

    return [];
  };

  private getWalletAddress = (profile: ProfileData) => {
    if (profile.userId === this.app.state.id) {
      return this.app.ton.walletAddress;
    }

    return profile.state.lastConnectedWallet;
  };

  public isSelf = () => {
    return this.currentUserId === this.app.state.id;
  };

  public updateWalletHoldings = async () => {
    const walletAddress = this.app.ton.walletAddress;
    if (!walletAddress) {
      return;
    }

    if (this.updateWalletHoldingRequest[walletAddress]) {
      return;
    }

    this.updateWalletHoldingRequest[walletAddress] =
      this.app.invoke.updateWalletHoldingsAsync({
        walletAddress,
      });

    const nonGemzTokens = await this.updateWalletHoldingRequest[walletAddress];

    delete this.updateWalletHoldingRequest[walletAddress];

    if (nonGemzTokens.length > 0) {
      // preload list of swappable tokens
      const allSwappableTokens = await this.app.ton.getSwappableTokens();

      this.swappableTokens = nonGemzTokens.filter((token) => {
        const tokenAddress = this.app.ton.getFriendlyAddress(
          token.jetton.address,
        );
        if (allSwappableTokens[tokenAddress]) {
          return true;
        }

        return false;
      });
    }
  };

  private fetchMemeHoldings = async (profile: ProfileData) => {
    const walletAddress = this.getWalletAddress(profile);
    const playerState = profile.state;

    const onchainMemeIds = getMyOnchainMemeIds(playerState, walletAddress);
    const pendingMemeIds = this.getPendingTxs().map((tx) => tx.memeId);
    const offchainMemeIds = getMyOffchainMemeIds(playerState);

    const ownedMemeIds = onchainMemeIds
      .concat(offchainMemeIds)
      .concat(pendingMemeIds);

    cai('fetchMemeHoldings', {
      offchainMemeIds,
      onchainMemeIds,
      pendingMemeIds,
    });

    const memes = await this.app.asyncGetters.getMyMemes({
      userId: profile.userId,
      createdMemes: playerState.trading.createdMemes,
      ownedMemeIds,
    });

    const onchainHoldings = getWalletHoldings(playerState, walletAddress);

    const offchainHoldings = playerState.trading.offchainTokens;

    const ownedTokens = memes.owned.filter(
      (meme) =>
        onchainHoldings[meme.id] !== undefined ||
        pendingMemeIds.includes(meme.id),
    );

    cai('fetchMemeHoldings', {
      memes,
      onchainHoldings,
      offchainHoldings,
      ownedTokens,
    });

    profile.ownedMemes = {
      created: memes.created,
      ownedTokens,
      ownedPoints: memes.owned.filter(
        (meme) => offchainHoldings[meme.id] !== undefined,
      ),
    };
  };

  private fetchProfileData = async (
    refresh = false,
    userId = this.currentUserId,
  ) => {
    if (!userId) {
      return;
    }

    let profile = this.profileCaches[userId];
    if (!refresh && profile) {
      // the last connected wallet can change for the user (not for other players)
      if (profile.walletAddress !== this.getWalletAddress(profile)) {
        await this.fetchMemeHoldings(profile);
      }
      return;
    }

    // @note: follower feature disabled for now
    // const followingsSubStateId = constructFollowingSubStateId(userId);
    // const followingsRequest = this.app.asyncGetters.getFollowings({
    //   followingsSubStateId,
    // });

    let playerState;
    let walletAddress;
    if (userId === this.app.state.id) {
      playerState = this.app.state;
      walletAddress = this.app.ton.walletAddress;
    } else {
      const playerStateRequest = this.app.fetchPlayerState(userId);

      playerState = await playerStateRequest;
      if (!playerState) {
        return;
      }

      walletAddress = playerState.lastConnectedWallet;
    }

    profile = this.profileCaches[userId] = {
      userId,
      state: playerState,
      walletAddress,
      ownedMemes: {
        created: [],
        ownedTokens: [],
        ownedPoints: [],
      },
    };

    await this.fetchMemeHoldings(profile);

    // @note: follower feature disabled for now
    // this.followings = (await followingsRequest) ?? [];

    // @todo: fixme
    // this.initialFollowing = this.followings.includes(userId);
    // this.optmisticFollowing = this.initialFollowing;

    await waitFor(10);
  };

  getPortfolioPricePoints = (sliceConfig: SliceConfig | null) => {
    if (!this.currentUserId) {
      return [];
    }

    // const playerData = this.profileCaches[this.currentUserId];
    // return getPortfolioPricePoints(
    //   playerData.state,
    //   sliceConfig,
    //   playerData.ownedMemes.all,
    // );
    return [];
  };

  getOffchainMemeHoldCount = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    return getOffchainMemeHoldCount(playerData.state);
  };

  getPortfolioValue = (userId = this.currentUserId) => {
    if (!userId) {
      return 0;
    }

    const playerData = this.profileCaches[userId];
    const portfolioValue = computeOnchainPortfolioValue(
      playerData.state,
      playerData.walletAddress,
      playerData.ownedMemes.ownedTokens,
      this.app.ton.getTonToUSDSync('1'),
    );
    return portfolioValue;
  };

  getPortfolioRoi = (userId = this.currentUserId) => {
    if (!userId) {
      return 0;
    }

    const playerData = this.profileCaches[userId];

    return getRoiSinceThen(
      playerData.state,
      playerData.walletAddress,
      playerData.ownedMemes.ownedTokens,
      this.app.ton.getTonToUSDSync('1'),
      this.app.now() - 24 * HOUR_IN_MS,
    );
  };

  getProfitLoss = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    const profitLoss = getOnchainProfitLoss(
      playerData.state,
      playerData.walletAddress,
      playerData.ownedMemes.ownedTokens,
      this.app.ton.getTonToUSDSync('1'),
      this.app.now() - 24 * HOUR_IN_MS,
    );
    return profitLoss;
  };

  getTradingvolume = () => {
    if (!this.currentUserId) {
      return;
    }

    // const playerData = this.profileCaches[this.currentUserId]
    // const tradingVolume = getOnchainTradingVolume(
    //   playerData.state,
    //   playerData.walletAddress,
    // );
    // return tradingVolume.toNumber();
    return 0;
  };

  getOnchainMemeHoldingStats = (memeId: string) => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    const onchainHoldings = getWalletHoldings(
      playerData.state,
      playerData.walletAddress,
    );

    const memeHoldings = onchainHoldings[memeId];
    if (!memeHoldings) {
      return;
    }

    // console.warn('>>> ownedOffchainMeme', ownedOffchainMeme);

    // const playerData = this.profileCaches[this.currentUserId]
    const memeStatus = playerData.ownedMemes.ownedTokens.find((meme) => {
      return meme.id === memeId;
    });

    if (!memeStatus) {
      return;
    }

    const tonToUsdRate = this.app.ton.getTonToUSDSync('1');
    const time24hAgo = this.app.now() - 24 * HOUR_IN_MS;
    const tokenAmount = memeHoldings.tokenAmount;

    if (memeStatus.dexListingTime > 0) {
      const valuationTon = HP(memeStatus.tokenPrice)
        .mul(tokenAmount)
        .toNumber();
      const price24hAgo = getPriceBackThen(
        memeStatus.pastTokenPrices ?? [],
        time24hAgo,
      );
      const valuationTon24hAgo = price24hAgo.mul(tokenAmount).toNumber();
      return {
        valuation: valuationTon * tonToUsdRate,
        roi: (valuationTon - valuationTon24hAgo) * tonToUsdRate,
        tokenAmount: HP(tokenAmount).toNumber(),
      };
    }

    const { valuationTonNow, valuationTonThen } =
      getOnchainHoldingValueNowAndThen(memeStatus, tokenAmount, time24hAgo);

    // console.error('\n\n\n*******')
    // console.error('INDIV VALUATION!', memeStatus.profile.name)
    // console.error('memeStatus.tokenPrice!', memeStatus.tokenSupply)
    // console.error('tokenAmount!', tokenAmount)
    // console.error('valuationTonNow!', valuationTonNow)
    // console.error('valuationTonThen!', valuationTonThen)
    // console.error('roi = ', valuationTonNow / valuationTonThen - 1)

    return {
      valuation: valuationTonNow * tonToUsdRate,
      roi: valuationTonNow / valuationTonThen - 1,
      tokenAmount: HP(tokenAmount).toNumber(),
    };
  };

  getPlayerState = () => {
    if (!this.currentUserId) {
      return;
    }

    const profile = this.profileCaches[this.currentUserId];
    if (!profile) {
      return;
    }

    return profile.state;
  };

  getOffchainMemeHolding = (memeId: string) => {
    if (!this.currentUserId) {
      return;
    }

    const profile = this.profileCaches[this.currentUserId];
    if (!profile) {
      return;
    }

    return profile.state.trading.offchainTokens[memeId];
  };

  getOffchainMemeHoldingStats = (
    memeId: string,
    ownedOffchainMeme: OwnedOffchainMeme | undefined,
  ) => {
    if (!this.currentUserId) {
      return;
    }

    // console.warn('>>> ownedOffchainMeme', ownedOffchainMeme);

    const playerData = this.profileCaches[this.currentUserId];
    return getOffchainMemeHoldingStats(
      memeId,
      playerData.ownedMemes.ownedPoints,
      ownedOffchainMeme,
    );
  };

  refreshUser = async () => {
    if (this.currentUserId === this.app.state.id) {
      this.refresh();
    }
  };

  refresh = async () => {
    this.prefetchFarmingData();
    await this.fetchProfileData(true);
    this.sendEvents(ProfileEvents.OnUpdate);
  };

  // To be used only for navigation from NavController or privately
  setProfile = async (userId: string) => {
    // if (this.currentUserId === userId) {
    //   return Promise.resolve();
    // }
    this.currentUserId = userId;
    this.prefetchFarmingData();
    await this.fetchProfileData(true);
    this.sendEvents(ProfileEvents.OnUpdate);
  };

  getNormalizedWalletAddress = () => {
    if (!this.currentUserId) {
      return '';
    }

    const playerData = this.profileCaches[this.currentUserId];
    return this.app.ton.normalizeAddress(playerData.walletAddress);
  };

  toggleFollow = () => {
    clearInterval(this.followDebounce);
    // There's no follow for self
    if (this.current?.isSelf) {
      return;
    }

    if (this.app.state.followingsCount >= maxFollowingsCount) {
      // @todo: notify user that max followings count has been reached
      this.app.ui.drawer.show({
        id: 'generic',
        opts: {
          title: 'Follows limit reached',
          subtitle:
            'You have reached the maximum limit of follows. Please unfollow someone and try again.',
          buttons: [
            {
              cta: 'Okay',
              onClick: () => this.app.ui.drawer.close(),
            },
          ],
        },
      });
      return;
    }

    this.optmisticFollowing = !this.optmisticFollowing;
    this.followLoading = true;
    this.sendEvents(ProfileEvents.OnUpdate);

    // Debounce for a second to prevent unnecessary requests
    this.followDebounce = setTimeout(async () => {
      if (
        this.initialFollowing !== this.optmisticFollowing &&
        this.currentUserId
      ) {
        if (this.optmisticFollowing) {
          this.followUser(this.currentUserId);
        } else {
          this.unfollowUser(this.currentUserId);
        }
      }
    }, 1000);
  };

  share = () => {
    if (!this.current) {
      return;
    }
    this.app.track('Profile_share', {
      name: this.current.name,
    });

    shareDeeplink('ProfilePage', {
      messageOpts: {
        title: t('player_profile_share_title'),
        text: t('player_profile_share_body', {
          userName: this.current.name,
          userCreatedTokenAmount: this.current.tokensCreated.length,
        }),
      },
      deeplinkOpts: this.app.nav.getDeeplinkOpts('ProfilePage'),
    });
  };

  // @note: follow related code disabled when onchain feature added
  // getFollows = async () => {
  //   if (!this.currentProfile) {
  //     return {
  //       following: [],
  //       followers: [],
  //     };
  //   }
  //   const followingsSubStateId = this.currentProfile.state.followingsSubStateId;
  //   const response = await Promise.all([
  //     followingsSubStateId
  //       ? this.app.asyncGetters.getFollowingProfiles({
  //           followingsSubStateId,
  //         })
  //       : [],
  //     this.app.asyncGetters.getFollowerProfiles({}),
  //   ]);

  //   const [following, followers] = response;

  //   // Also fetch and pass along all possible token information because we need to know their supply for stats
  //   const allMemeIds = [...following, ...followers].reduce((res, cur) => {
  //     for (const meme of cur.memes) {
  //       if (!res.includes(meme.id)) {
  //         res.push(meme.id);
  //       }
  //     }
  //     return res;
  //   }, [] as string[]);

  //   const tokens = await this.app.asyncGetters.getMemesFromOpenSearch({
  //     memeIds: allMemeIds,
  //   });

  //   return {
  //     following,
  //     followers,
  //     tokens,
  //   };
  // };

  private updateFollow = async (key: 'follow' | 'unfollow', userId: string) => {
    const error = await this.app.invoke.asyncUpdateFollow({
      [key]: userId,
    });

    if (error) {
      this.app.ui.drawer.show({
        id: 'generic',
        opts: {
          title: 'Something went wrong!',
          subtitle: error.errorMessage,
        },
      });
      this.followLoading = false;
      this.sendEvents(ProfileEvents.OnUpdate);
      return;
    }

    setTimeout(async () => {
      // update own profile
      this.fetchProfileData(true, this.app.state.id);
      // update current viewing profile
      await this.fetchProfileData(true);
      this.followLoading = false;
      this.sendEvents(ProfileEvents.OnUpdate);
    }, 500);
  };

  followUser = (userId: string) => {
    if (userId === this.app.state.id) {
      return;
    }
    return this.updateFollow('follow', userId);
  };

  unfollowUser = (userId: string) => {
    if (userId === this.app.state.id) {
      return;
    }
    return this.updateFollow('unfollow', userId);
  };

  private prefetchFarmingData = async () => {
    const farming = getTMGFarmingListing(this.app.state, this.app.now());

    const farmIds = farming.map((f) => f.tokenId);

    // If it's cached, skip
    const idsToFetch = farmIds.filter((id) => !this.tokenFarmCache[id]);

    // All data already in cache
    if (idsToFetch.length <= 0) {
      return;
    }

    const tokens = await this.app.asyncGetters.getMemesFromOpenSearch({
      memeIds: idsToFetch,
    });

    // Cache response
    tokens.forEach((t) => {
      this.tokenFarmCache[t.id] = t;
    });

    if (tokens.length > 0) {
      this.sendEvents(ProfileEvents.OnUpdate);
    }
  };

  /**
   * @TODO: This should probably come from the farming list
   */
  private getTokensFarming = () => {
    const farming = getTMGFarmingListing(this.app.state, this.app.now());

    return farming.map((farm) => ({
      ...farm,
      ...this.tokenFarmCache[farm.tokenId],
    })) as FarmingSearchResult[];
  };

  getFarmingData = (tokenId: string) => {
    this.prefetchFarmingData();
    return this.getTokensFarming().find((f) => f.id === tokenId);
  };

  getPendingTxs = () => {
    if (!this.currentProfile) {
      return [];
    }

    if (this.currentProfile.userId !== this.app.state.id) {
      // only showing pending transactions for current player!
      // the reason is that we do not want to constantly refresh other player states
      return [];
    }

    // return getPendingTxs(this.currentProfile.state);
    return getPendingTxs(this.app.state);
  };
}
