import { OwnedOffchainMeme } from '../../../replicant/features/game/player.schema';
import {
  CanBuyOpts,
  CanSellOpts,
  getBuyPointTxEstimate,
  getBuyTxEstimate,
  getCanUserSell,
  getCoinAmountForPointSell,
  getIsPointsBuyTxValid,
  getIsPointsSellTxValid,
  getMyOffchainMemeIds,
  getTxWithinEstimate,
  getWalletHoldings,
  getGraduationClaimAmount,
  Meme,
  getMemeHolding,
  isDailyTokenBeingClaimed,
  canClaimGraduationPoints,
  isGradTokensFromPointsBeingClaimed,
  getJettonBalance,
  getBuyTxAmount,
  getSellTxAmount,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { TradingSearchResult } from '../../../replicant/features/tradingMeme/tradingMeme.properties';
import {
  claimLockDuration,
  MemeNotifDelay,
  onchainCurveConfig,
  SliceConfig,
  txConfig,
  txFees,
} from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import {
  CurrencyType,
  TradingTokenHolder,
  TxType,
  WalletJetton,
} from '../../../replicant/features/tradingMeme/types';
import { ErrorCode } from '../../../replicant/response';
import { MIN_IN_MS, SEC_IN_MS } from '../../../replicant/utils/time';
import { captureGenericError } from '../../sentry';
import { Optional, WithProxy } from '../../types';
import { cai, getPct } from '../../utils';
import {
  getOffchainMemeHoldCount,
  getOffchainMemeHoldingStats,
  getOnchainTokenPricePoints,
  getPortfolioPricePoints,
} from '../../memeUtils';
import { MemesEvents } from './MemesController';
import { BusinessController } from '../BusinessController';
import { HighPrecision, HP } from '../../../replicant/lib/HighPrecision';
import { fromNano, toNano } from '@ton/core';
import { State } from '../../../replicant/schema';
import { timeLog } from '../../utils/timeLog';
import { ClaimType } from '../../TonProvider/types';
import { WALLET_TIMEOUT_ERROR } from '../../TonProvider/consts';
import { t } from 'i18next';

interface Tx {
  txType: TxType;
  receive: HighPrecision;
  send: HighPrecision;
  isValid: boolean;
  isNonGemzSwap?: boolean;
}

const CARD_REFETCH_COOLDOWN_MS = 15;
const PORTFOLIO_REFETCH_COOLDOWN = MIN_IN_MS * 2; // @TODO: Check how often it should be
const HOT_CATEGORY_REFRESH_COOLDOWN = SEC_IN_MS * 2;
const NEW_CATEGORY_REFRESH_COOLDOWN = MIN_IN_MS * 1;
const TOP_CATEGORY_REFRESH_COOLDOWN = MIN_IN_MS * 5;

const LIST_FETCH_COOLDOWN = Math.min(
  HOT_CATEGORY_REFRESH_COOLDOWN,
  NEW_CATEGORY_REFRESH_COOLDOWN,
  TOP_CATEGORY_REFRESH_COOLDOWN,
);

interface TxBundle {
  meme: Meme;
  tokenAmount: bigint;
  tonAmount: string;
  submit?: Promise<() => Promise<string>>;
}

export class TradingController extends BusinessController<
  MemesEvents,
  WithProxy<MemesEvents>
> {
  // Check if repli
  // Record<UserId, ImageUrl | null>; null if we have attempted to find a phone but there's none

  private notifScheduler: NodeJS.Timeout | null = null;

  private memeFetchedAtLeastOnce: boolean = false;

  private selectedSwappableToken: WalletJetton = {
    // dummy token
    balance: '0',
    jetton: {
      address: '',
      name: '',
      symbol: '',
      decimals: 0,
      image: '',
      memeId: '',
      verification: '',
    },
    wallet_address: {
      address: '',
      is_scam: false,
      is_wallet: false,
    },
  };

  private swappingNonGemzToken: boolean = false;

  get isNonGemzSwap() {
    return this.swappingNonGemzToken;
  }

  get swappableToken() {
    return this.selectedSwappableToken;
  }

  get notification() {
    return this.state.notification;
  }

  get isLoading() {
    return this.state.loading;
  }

  get purchaseMode() {
    return this.state.purchaseMode;
  }

  get isBuy() {
    return this.purchaseMode === 'buy';
  }

  get portfolioValue() {
    return this.state.portfolioValue;
  }

  get tx() {
    const token = this.app.memes.currentMeme.meme;

    const formData = this.app.memes.factory.createNewTokenFormData;

    // console.log('tx', {
    //   formData,
    //   tx: this.state.tx,
    //   currency: this.txCurrency,
    // });

    if ((!token && !formData) || !this.state.tx) {
      return undefined;
    }

    const canSell = token
      ? getCanUserSell(this.app.state, token.id, HP(0))
      : false;

    let finalTonAmount: string | undefined = undefined;
    let slippage: string | undefined = undefined;
    let fee: string | undefined = undefined;

    if (this.txCurrency === 'tokens' && !this.txMeme?.dexContractAddress) {
      const amount = this.state.tx.send.toString();
      const isBuy = this.state.purchaseMode === 'buy';
      const txAmount = isBuy ? getBuyTxAmount(amount) : getSellTxAmount(amount);
      finalTonAmount = txAmount.tonAmount;
      slippage = txAmount.slippage;
      fee = txAmount.fee;
    }

    return {
      ...this.state.tx,
      maxSlippage: this.app.state.trading.maxSlippage,
      canSell,
      finalTonAmount,
      slippage,
      fee,
    };
  }

  get txCurrency() {
    return this.state.txCurrency;
  }

  get isTxCurrencyTokens() {
    return this.txCurrency === 'tokens';
  }

  get txMeme() {
    return this.app.memes.currentMeme.meme;
  }

  get txMemeTicker() {
    return this.app.memes.currentMeme.meme?.ticker || '';
  }

  get txMemeImage() {
    return this.app.memes.currentMeme.meme?.image || '';
  }

  get isTxMinted() {
    return this.txMeme?.isMinted ?? false;
  }

  get txJettonContractAddress() {
    return this.app.memes.currentMeme.meme?.jettonContractAddress;
  }

  get txDexContractAddress() {
    return this.app.memes.currentMeme.meme?.dexContractAddress;
  }

  get isTxDexListed() {
    return Boolean(this.txDexContractAddress);
  }

  get isTxJettonListed() {
    return this.isTxMinted && Boolean(this.txJettonContractAddress);
  }

  public async getTxSummary() {
    if (!this.state.tx) {
      return;
    }

    if (this.state.tx.isNonGemzSwap) {
      this.state.tx.receive = await this.getEstimatedTokensToTon();
      return {
        ...this.state.tx,
        shareOfTheSupply: '-',
        sending: this.state.tx.send.toString(),
        receiving: this.state.tx.receive.toString(),
      };
    }

    const meme = this.app.memes.currentMeme.meme;

    if (!meme) {
      return;
    }

    const {
      tx: { txType, receive, send },
    } = this.state;

    // const finalTonAmount = this.tx?.finalTonAmount;

    // default to 'sell'
    const supply =
      this.txCurrency === 'tokens' ? meme.tokenSupply : meme.pointSupply;
    let txAmount = send;
    let finalSupply = HP(supply);

    if (txType === 'buy') {
      txAmount =
        this.txCurrency === 'tokens'
          ? await this.getEstimatedTonToTokens()
          : receive;

      this.state.tx.receive = this.txCurrency === 'tokens' ? txAmount : receive;

      // we want to display how
      finalSupply = HP(supply).add(txAmount);
    } else if (this.txCurrency === 'tokens') {
      this.state.tx.receive = await this.getEstimatedTokensToTon();
      // finalSupply already correct: i.e we want to display ratio of current supply being sold
    }

    // const buySend = txType === 'buy' && finalTonAmount ? finalTonAmount : send;

    return {
      ...this.state.tx,
      shareOfTheSupply: finalSupply.gt(0)
        ? getPct(txAmount.toNumber(), finalSupply.toNumber())
        : '-',
      sending: send.toString(),
      receiving: this.state.tx.receive.toString(),
      // sending: buySend.toString(),
      // receiving: txReceive.toString(),
    };
  }

  get timeUntilNextOffchainTokenRefresh() {
    return CARD_REFETCH_COOLDOWN_MS - this.offchainTokenRefreshCount;
  }

  private offchainTokenRefresher?: NodeJS.Timeout;
  private offchainTokenRefreshCount = 0;

  private listingFetcher?: NodeJS.Timeout;

  private buyTxBundler?: Promise<TxBundle>;

  // @TODO: not public
  public state = {
    loading: false,
    txCurrency: 'tokens' as CurrencyType,
    purchaseMode: undefined as Optional<TxType>,
    tx: undefined as Optional<Tx>,
    notifCount: -1, // -1 count as inactive notification
    notification: undefined as Optional<TradingSearchResult>,
    pastNotificationIds: [] as string[],
    ownedOffchainTokensFetchTimestamp: 0,
    portfolioValue: -1,
  };

  private sendEvent!: (evt: MemesEvents) => void;

  // @TODO: Move the event listeners to MemesController
  public init = async ({ sendEvtProxy }: WithProxy<MemesEvents>) => {
    this.sendEvent = sendEvtProxy;
    /**
     * When we are showing the card page, start the refresh counter and whenever its up
     * refresh selected card data and restart counter
     */
    this.app.views.TradingTokenPage.onVisibilityChange((isShowing) => {
      this.onTokenChange(isShowing);
    });

    this.app.views.TradingPage.onVisibilityChange((isShowing) => {
      clearInterval(this.listingFetcher);
      if (isShowing) {
        this.app.memes.setCurrent({ tokenId: undefined });
        // this.state.selectedOffchainToken = undefined;

        let lastTopRefresh = Date.now();
        let lastNewRefresh = Date.now();
        let lastHotRefresh = Date.now();
        this.listingFetcher = setInterval(() => {
          const now = Date.now();

          if (this.app.memes.currentFilter === 'Hot') {
            if (now - lastTopRefresh > HOT_CATEGORY_REFRESH_COOLDOWN) {
              this.app.memes.currentList.refresh();
              lastHotRefresh = now;
            }
          } else if (this.app.memes.currentFilter === 'Top') {
            if (now - lastTopRefresh > TOP_CATEGORY_REFRESH_COOLDOWN) {
              this.app.memes.currentList.refresh();
              lastTopRefresh = now;
            }
          } else if (this.app.memes.currentFilter === 'New') {
            if (now - lastNewRefresh > NEW_CATEGORY_REFRESH_COOLDOWN) {
              this.app.memes.currentList.refresh();
              lastNewRefresh = now;
            }
          }
        }, LIST_FETCH_COOLDOWN);
      }
    });

    // todo: this causes issues with the tapping game
    // todo: disabling it for now until we can find a correct solution
    // todo: the goal is to refresh the token data, without re-rendering the tapping game

    // if (getMyOffchainMemeIds(this.app.state).length > 0) {
    //   // avoid async call if no token
    //   // trigger a series of snapshots
    //   this.app.invoke.asyncTakePortfolioSnapshots();
    // }

    // Reset amount when we change tokens
    this.app.memes.addEventListener(MemesEvents.OnTokenSelected, () => {
      this.updateAmount(HP(0));
    });

    this.onInitComplete();
  };

  /**
   * To be used by MemesController only!
   */
  public onTokenChange = (isShowing: boolean) => {
    clearInterval(this.offchainTokenRefresher);
    if (isShowing) {
      this.offchainTokenRefresher = setInterval(() => {
        if (this.offchainTokenRefreshCount === CARD_REFETCH_COOLDOWN_MS) {
          this.offchainTokenRefreshCount = 0;
          this.app.memes.getMeme(undefined, 'fetchAndUpdate');
        } else {
          this.offchainTokenRefreshCount++;
        }
        // this.sendEvent(TradingEvents.OnRefreshTimeUpdate);
      }, SEC_IN_MS);
    } else {
      this.offchainTokenRefreshCount = 0;
    }
  };

  private bundleBuyTx = async (
    meme: Meme,
    currencyAmount: HighPrecision,
  ): Promise<TxBundle> => {
    const tonAmount = currencyAmount.toString();
    let tokenAmount = BigInt(0);

    timeLog.start('bundleBuyTx:getTokenAmount');
    if (this.isTxDexListed) {
      cai('bundleBuyTx:getDexTokensToTon');
      const tokenAmountStr = await this.app.ton.getDexTonToTokens({
        meme: meme,
        tokenAmount: HP(0),
        tonAmount,
      });

      return {
        meme,
        tokenAmount: BigInt(tokenAmountStr),
        tonAmount,
      };
    } else if (this.isTxJettonListed) {
      cai('bundleBuyTx:getBuyTokensPerTon');
      tokenAmount = await this.app.ton.getBuyTokensPerTon(
        meme.jettonContractAddress,
        tonAmount,
      );
    } else {
      cai('bundleBuyTx:getListingTokens');
      tokenAmount = await this.app.ton.getListingTokens(tonAmount);
    }
    timeLog.end('bundleBuyTx:getTokenAmount');
    const submit = this.app.ton.buyToken({
      tonAmount,
      tokenAmount: HP(tokenAmount.toString()),
      meme,
    });

    return {
      meme,
      tokenAmount,
      tonAmount,
      submit,
    };
  };

  private bundleTx = async () => {
    this.buyTxBundler = undefined;
    const { meme: token } = this.app.memes.currentMeme;
    cai('bundleTx:1', { token, tx: this.state.tx, currency: this.txCurrency });
    if (!token || !this.state.tx || this.txCurrency === 'points') {
      return;
    }

    const { send: amount } = this.state.tx;
    let amountValue = amount;
    if (!(amount instanceof HighPrecision)) {
      amountValue = HP(amount);
    }

    const { meme } = this.app.memes.currentMeme;
    cai('bundleTx:2', { meme });
    if (!meme) {
      return;
    }

    let balanceHP = HP(this.app.ton.balance);
    if (!this.isBuy) {
      const memeHolding = getMemeHolding(
        this.app.state,
        this.app.ton.walletAddress,
        meme.id,
      );
      const tokenAmountHeld = HP(memeHolding?.tokenAmount);
      balanceHP = tokenAmountHeld;
    }

    cai('bundleTx:3', {
      balanceHP: balanceHP.toString(),
      amountValue: amountValue.toString(),
      hasBalance: balanceHP.lt(amountValue),
    });
    if (balanceHP.lt(amountValue)) {
      return;
    }

    cai('bundleTx:4', { isBuy: this.isBuy });

    if (this.isBuy) {
      this.buyTxBundler = this.bundleBuyTx(meme, amountValue);
    } else {
      cai('bundle sell tx');
      this.buyTxBundler = new Promise((resolve) => {
        const submit = this.app.ton.sellToken(
          { ...meme, description: meme.description.description },
          amountValue.toString(),
        );
        cai('bundle sell tx', { submit });
        return resolve({
          meme,
          tokenAmount: BigInt(amountValue.toString()),
          tonAmount: '0',
          submit,
        });
      });
      cai('bundle sell tx 2');
    }

    cai('bundleTx:final', this.bundleBuyTx);
  };

  public goToTxConfirmation = async () => {
    if (!this.tx) {
      return;
    }

    timeLog.start('goToTxConfirmation:getMeme');
    await this.app.memes.getMeme(undefined, 'fetch');
    timeLog.end('goToTxConfirmation:getMeme');

    timeLog.start('goToTxConfirmation:bundleTx');
    await this.bundleTx();
    timeLog.end('goToTxConfirmation:bundleTx');

    this.app.ui.drawer.show({
      id: 'drawerTradingTransactionConfirm',
      hideClose: false,
    });
  };

  public onCurrentTokenChange = (
    offchainToken: Meme,
    isRefresh?: 'isRefresh',
  ) => {
    const amount = isRefresh ? this.state.tx?.send : HP(0);
    this.updateTx(offchainToken, amount);
    // this.sendEvent(TradingEvents.OnSelectedOffchainTokenUpdate);
  };

  public setPurchaseMode = (txType: TxType) => {
    if (this.state.purchaseMode === txType) {
      return;
    }
    this.state.purchaseMode = txType;
    const { meme: token } = this.app.memes.currentMeme;
    if (token) {
      this.updateTx(token);
      this.sendEvent(MemesEvents.TradingOnTxUpdate);
    }
  };

  public updateAmount = (value: HighPrecision) => {
    if (!this.state.tx) {
      return;
    }

    const { createNewTokenFormData } = this.app.memes.factory;
    if (createNewTokenFormData) {
      this.updateTx(
        {
          id: 'not_used_for_buy',
          pointSupply: HP(0).toString(),
        },
        value,
      );
      this.sendEvent(MemesEvents.TradingOnTxUpdate);
      return;
    }

    if (this.swappingNonGemzToken) {
      this.updateSwapTx(value);
      this.sendEvent(MemesEvents.TradingOnTxUpdate);
      return;
    }

    const { meme: token } = this.app.memes.currentMeme;
    if (token) {
      this.updateTx(token, value);
      this.sendEvent(MemesEvents.TradingOnTxUpdate);
    }
  };

  public setCurrency = (currencyType: CurrencyType) => {
    this.state.txCurrency = currencyType;
    console.log({ txCurrency: this.state.txCurrency });
    this.updateAmount(HP(0));
    if (currencyType === 'tokens') {
      this.app.ton.getBalance(); // TODO: This needs to be connected to app.state.balance for buy/sell UI to work
    }
  };

  public submitTransaction = async () => {
    cai('submitTransaction', { time: Date.now() });
    const { meme: token } = this.app.memes.currentMeme;
    if (!token || !this.state.tx) {
      return;
    }

    const { send, receive, isNonGemzSwap } = this.state.tx;

    let sendValue = send;
    if (!(send instanceof HighPrecision)) {
      sendValue = HP(send);
    }

    let receiveValue = receive;
    if (!(receive instanceof HighPrecision)) {
      receiveValue = HP(receive);
    }

    const response = await (async () => {
      if (this.isBuy && !isNonGemzSwap) {
        if (this.txCurrency === 'tokens') {
          return this.buyOnchainToken({ currencyAmount: sendValue });
        }

        return this.buyOffchainToken({
          currencyAmount: sendValue,
          pointAmountEstimate: receive,
          driftPct: txConfig.defaultMaxSlippage.toNumber(),
        });
      }

      if (this.txCurrency === 'tokens' || isNonGemzSwap) {
        return this.sellOnchainToken(sendValue, receiveValue);
      }

      return this.sellOffchainToken({
        pointAmount: sendValue,
        currencyAmountEstimate: receive,
        driftPct: txConfig.defaultMaxSlippage.toNumber(),
      });
    })()
      .then((res) => {
        this.app.profile.refreshUser();
        this.app.memes.getMeme(undefined, 'fetchAndUpdate');
        return res;
      })
      .catch((err: any) => {
        const { message } = err;
        if (!message) {
          return;
        }
        if (message === WALLET_TIMEOUT_ERROR) {
          this.app.ui.showError({
            title: t('wallet_timeout_error_title'),
            message: t('wallet_timeout_error_message'),
          });
          return;
        }
        this.app.ui.showError({ message });
      });

    return response;
  };

  private sellOnchainToken = async (
    tokenAmount: HighPrecision,
    tonAmount: HighPrecision,
  ) => {
    timeLog.start('sellOnchainToken:waitForBundler');
    const bundle = await this.buyTxBundler;
    timeLog.end('sellOnchainToken:waitForBundler', { bundle });
    if (!bundle) {
      return;
    }

    let txHash: string;
    let memeId;
    let memeName;
    let memeTicker;
    let memeDescription;
    let memeImage;

    if (this.tx?.isNonGemzSwap) {
      memeName = this.swappableToken.jetton.name;
      memeTicker = this.swappableToken.jetton.symbol;
      memeImage = this.swappableToken.jetton.image;

      txHash = await this.app.ton.sellDexToken({
        dexContractAddress: this.app.ton.getFriendlyAddress(
          this.swappableToken.jetton.address,
        ),
        tokenAmount,
        tonAmount: HP(0).toString(),
        isNonGemz: true,
      });
    } else {
      const { meme } = bundle;

      memeId = meme.id;
      memeName = meme.name;
      memeTicker = meme.ticker;
      meme.description.description;
      memeImage = meme.image;

      const memeHolding = getMemeHolding(
        this.app.state,
        this.app.ton.walletAddress,
        meme.id,
      );
      const tokenAmountHeld = HP(memeHolding?.tokenAmount);

      cai('sellOnchainToken', { memeHolding, dex: this.isTxDexListed });

      if (this.isTxDexListed) {
        txHash = await this.app.ton.sellDexToken({
          memeId: meme!.id,
          dexContractAddress: meme!.dexContractAddress!,
          tokenAmount,
          tonAmount: HP(0).toString(),
        });
      } else {
        timeLog.start('buyOnchainToken:bundleSubmit');
        const submitTx = await bundle.submit;
        timeLog.end('buyOnchainToken:bundleSubmit');
        if (!submitTx) {
          return;
        }

        timeLog.start('buyOnchainToken:submitTx');
        txHash = await submitTx();
        timeLog.end('buyOnchainToken:submitTx', { txHash });
      }

      this.app.track('memecard_trade_start', {
        transaction_type: 'sell',
        card_name: meme.name,
        cardID: meme.id,
        ticker: meme.ticker,
        previous_owned: tokenAmountHeld.toString(),
        // current_owned: tokenAmountHeld.add(tokenAmount).toString(),
        amount_divested: tokenAmount.toString(),
        // amount_bought: tokenAmount.toString(),
        // memecard_price: HP(tonAmount).div(tokenAmount).toString(),
        total_holders: meme.holderCount - (tokenAmountHeld.gt(0) ? 1 : 0),
        total_tokens_circulating: meme.tokenSupply,
        market_cap_ton: meme.marketCapTon,
        market_cap_usd: meme.marketCapUsd,
        tx_hash: txHash,
      });
    }

    // carles: triggering SELL transaction success drawer
    this.app.ui.drawer.show({
      id: 'drawerTradingTransactionSuccess',
      onClose: () => {
        this.app.ui.confetti.hide();
      },
      props: {
        transactionSuccess: {
          mode: 'sell',
          txCurrency: 'tokens',
          memeId,
          memeName,
          memeTicker,
          memeDescription,
          memeImage,
          txAmount: tonAmount.toString(),
        },
      },
    });
  };

  private buyOnchainToken = async ({
    currencyAmount,
  }: Pick<CanBuyOpts, 'currencyAmount'>) => {
    timeLog.start('buyOnchainToken:waitBundler');
    const bundle = await this.buyTxBundler;
    timeLog.end('buyOnchainToken:waitBundler', { bundle });
    if (!bundle) {
      // @TODO: Throw some error?
      return;
    }

    const { meme, tonAmount, tokenAmount } = bundle;

    const memeHolding = getMemeHolding(
      this.app.state,
      this.app.ton.walletAddress,
      meme.id,
    );
    const tokenAmountHeld = HP(memeHolding?.tokenAmount);

    let txHash;
    if (this.isTxDexListed) {
      txHash = await this.app.ton.buyDexToken({
        memeId: meme!.id,
        dexContractAddress: meme!.dexContractAddress!,
        tokenAmount: HP(tokenAmount.toString()),
        tonAmount: tonAmount,
      });
    } else {
      if (!bundle.submit) {
        // @TODO: Throw error?
        return;
      }
      timeLog.start('buyOnchainToken:bundleSubmit');
      const submitTx = await bundle.submit;
      timeLog.end('buyOnchainToken:bundleSubmit');

      timeLog.start('buyOnchainToken:submitTx');
      txHash = await submitTx();
      timeLog.end('buyOnchainToken:submitTx', { txHash });
    }

    const nextSupply = HP(meme.tokenSupply).add(HP(tokenAmount.toString()));
    if (nextSupply.gte(onchainCurveConfig.graduationSupplyThreshold)) {
      this.app.ui.addGraduateMeme(meme.id);
    }

    this.app.track('memecard_trade_start', {
      transaction_type: 'buy',
      card_name: meme.name,
      cardID: meme.id,
      ticker: meme.ticker,
      previous_owned: tokenAmountHeld.toString(),
      // current_owned: tokenAmountHeld.add(tokenAmount).toString(),
      amount_invested: tonAmount,
      // amount_bought: tokenAmount.toString(),
      // memecard_price: HP(tonAmount).div(tokenAmount).toString(),
      total_holders: meme.holderCount - (tokenAmountHeld.gt(0) ? 1 : 0),
      total_tokens_circulating: meme.tokenSupply,
      market_cap_ton: meme.marketCapTon,
      market_cap_usd: meme.marketCapUsd,
      tx_hash: txHash,
    });

    // txn_success (bool) this tracks whether the purchase went through in the blockchain

    // carles: triggering BUY transaction success drawer
    this.app.ui.drawer.show({
      id: 'drawerTradingTransactionSuccess',
      onClose: () => {
        this.app.ui.confetti.hide();
      },
      props: {
        transactionSuccess: {
          mode: 'buy',
          txCurrency: 'tokens',
          memeId: meme.id,
          memeName: meme.name,
          memeTicker: meme.ticker,
          memeDescription: meme.description.description,
          memeImage: meme.image,
          txAmount: tokenAmount.toString(),
        },
      },
    });

    // todo carles: response is always undefined here
    // todo carles: ideally, buyToken would need to return a Promise with response.error or response.data
    // todo carles: then from here, we would call uiController method to open success drawer
    // todo(carles): for now, implemented triggering success drawer from TonController onchain buy and sell methods
    console.warn('>>> onBuyOnChainToken txHash', txHash);

    return Boolean(txHash);
  };

  private buyOffchainToken = async ({
    currencyAmount,
    pointAmountEstimate,
  }: CanBuyOpts) => {
    if (this.app.playerBalance < currencyAmount.toNumber()) {
      return;
    }

    const { meme: token } = this.app.memes.currentMeme;

    if (!token) {
      return;
    }

    const memeId = token.id;

    this.app.ui.showSpinner();

    // Grab the current estimate and make sure we don't have some old ass estimate still
    let latestPointAmountEstimate =
      await this.app.asyncGetters.getBuyOffchainMemeEstimate({
        offchainTokenId: memeId,
        currencyAmount: currencyAmount.toString(),
      });

    const withinEstimate =
      latestPointAmountEstimate &&
      getTxWithinEstimate(
        HP(latestPointAmountEstimate),
        pointAmountEstimate,
        txConfig.clientSlippage,
      );

    if (!withinEstimate) {
      // Update transaction to refetch latest estimate
      const updatedToken = await this.app.memes.getMeme(memeId, 'forceFetch');
      // Update transaction to refetch latest estimate
      if (this.tx?.send && updatedToken) {
        this.updateTx(updatedToken, this.tx.send);
      }

      return this.app.ui.showError({
        message: `The estimate has changed, new estimate ${latestPointAmountEstimate}`,
      });
    }

    const currentOwnedPointAmount = HP(
      this.app.state.trading.offchainTokens[memeId]?.pointAmount || 0,
    );

    try {
      const response = await this.app.invoke.buyOffchainToken({
        memeId,
        currencyAmount: currencyAmount.toString(),
        pointAmountEstimate: pointAmountEstimate.toString(),
      });

      if (response.error) {
        // @TODO: Make this into a function
        if (response.code === ErrorCode.CUSTOM_SENTRY_TRACK) {
          captureGenericError(ErrorCode.CUSTOM_SENTRY_TRACK, response.data);
        }

        // If we are receiving a handled error most likely we have stale data, reload current item
        // await this.refreshSelectedOffchainToken();
        await this.app.memes.getMeme(undefined, 'forceFetch');

        throw new Error(response.error);
      } else if (response.data) {
        // Update selected offchainToken

        await this.app.memes.getMeme(memeId, 'forceFetch');

        const { meme } = this.app.memes.currentMeme;

        if (meme) {
          const pointAmount = HP(
            this.app.state.trading.offchainTokens[memeId]!.pointAmount,
          );
          const amount_bought = pointAmount.minus(currentOwnedPointAmount ?? 0);

          this.app.track('memeoffchainToken_buy', {
            offchainToken_name: meme.name,
            offchainTokenID: memeId,
            previous_owned: currentOwnedPointAmount?.toString() ?? false,
            current_owned:
              this.app.state.trading.offchainTokens[memeId]!.pointAmount,
            amount_invested: currencyAmount.toString(),
            amount_bought: amount_bought.toString(),
            memeoffchainToken_price: currencyAmount
              .div(amount_bought)
              .toString(),
            total_holders: meme.holderCount - 1, // exclude self
          });

          // this.app.invoke.asyncTakePortfolioSnapshots();
          this.refetchPortfolio('forceUpdate');
          this.app.ui.onMemeTxSuccess({
            meme: meme,
            memeId,
            memeImage: meme.image,
            memeName: meme.name,
            memeTicker: meme.ticker,
            memeDescription: meme.description.description,
            txAmount: amount_bought.toNumber(),
            txCurrency: 'points',
            txType: 'buy',
          });
          this.updateAmount(HP(0));
        }
      }

      return response;
    } catch (e: any) {
      this.app.ui.showError({
        message: e.message,
      });
    }
  };

  private sellOffchainToken = async ({
    pointAmount,
    currencyAmountEstimate,
  }: CanSellOpts) => {
    const { meme } = this.app.memes.currentMeme;

    if (!meme) {
      return;
    }

    const memeId = meme.id;

    const currentOwnedPointAmount = HP(
      this.app.state.trading.offchainTokens[memeId]?.pointAmount ?? 0,
    );

    if (currentOwnedPointAmount.lt(pointAmount)) {
      return this.app.ui.showError({
        message: `Not enough tokens to sell`,
      });
    }

    this.app.ui.showSpinner();

    let latestCurrencyAmountEstimate =
      await this.app.asyncGetters.getSellOffchainMemeEstimate({
        offchainTokenId: memeId,
        pointAmount: pointAmount.toString(),
      });

    if (!latestCurrencyAmountEstimate) {
      return this.app.ui.showError({
        message: `Could not estimate selling price`,
      });
    }

    const withinEstimate = getTxWithinEstimate(
      HP(latestCurrencyAmountEstimate),
      currencyAmountEstimate,
      txConfig.clientSlippage,
    );

    if (!withinEstimate) {
      const updatedToken = await this.app.memes.getMeme(memeId, 'forceFetch');
      // Update transaction to refetch latest estimate
      if (this.tx?.send && updatedToken) {
        this.updateTx(updatedToken, this.tx.send);
      }

      return this.app.ui.showError({
        message: `The estimate has changed, new estimate ${latestCurrencyAmountEstimate}`,
      });
    }

    try {
      const response = await this.app.invoke.sellOffchainToken({
        memeId,
        pointAmount: pointAmount.toString(),
        currencyAmountEstimate: currencyAmountEstimate.toString(),
      });

      if (response.error) {
        // @TODO: Make this into a function
        if (response.code === ErrorCode.CUSTOM_SENTRY_TRACK) {
          captureGenericError(ErrorCode.CUSTOM_SENTRY_TRACK, response.data);
        }
        // If we are receiving a handled error most likely we have stale data, reload current item
        // await this.refreshSelectedOffchainToken();
        await this.app.memes.getMeme(memeId, 'forceFetch');

        throw new Error(response.error);
      } else if (response.data) {
        if (!response.data.token) {
          this.app.memes.setCurrent({ tokenId: undefined });
          return response;
        }
        const updatedMeme = await this.app.memes.getMeme(memeId, 'forceFetch');
        if (updatedMeme) {
          const amount_divested = response.data.amount_divested;

          this.app.track('memeoffchainToken_sell', {
            offchainToken_name: updatedMeme.name,
            offchainTokenID: updatedMeme.id,
            previous_owned: currentOwnedPointAmount?.toString() ?? false,
            current_owned:
              this.app.state.trading.offchainTokens[updatedMeme.id]
                ?.pointAmount ?? 0,
            amount_divested,
            amount_sold: pointAmount.toString(),
            memeoffchainToken_price: HP(amount_divested)
              .div(pointAmount)
              .toString(),
            total_holders: updatedMeme.holderCount - 1, // exclude self
          });
          // this.app.invoke.asyncTakePortfolioSnapshots();
          this.refetchPortfolio('forceUpdate');
          this.app.ui.onMemeTxSuccess({
            meme: updatedMeme,
            memeId: updatedMeme.id,
            memeImage: updatedMeme.image,
            memeName: updatedMeme.name,
            memeTicker: updatedMeme.ticker,
            memeDescription: updatedMeme.description.description,
            txAmount: amount_divested,
            txCurrency: 'points',
            txType: 'sell',
          });
        }
        this.updateAmount(HP(0));
      }
      return response;
    } catch (e: any) {
      this.app.ui.showError({
        message: e.message,
      });
    }
  };

  private updateTx = (
    selectedMeme: Pick<Meme, 'pointSupply' | 'id'>,
    amount?: HighPrecision,
  ) => {
    if (!this.purchaseMode) {
      return;
    }

    if (this.purchaseMode === 'buy') {
      const buyTx = {
        currencyAmount: amount ?? HP(0),
        currentSupply: HP(selectedMeme.pointSupply),
      };

      const pointsAmount = getBuyTxEstimate(buyTx);
      const tokensAmount = amount ?? HP('0');

      const receive =
        this.txCurrency === 'points' ? pointsAmount : tokensAmount;

      this.state.tx = {
        txType: this.purchaseMode,
        receive,
        send: buyTx.currencyAmount,
        isValid:
          this.txCurrency === 'points'
            ? getIsPointsBuyTxValid(this.app.state, buyTx)
            : true,
      };
    } else {
      // selling
      const sellTx = {
        pointAmount: amount ?? HP(0),
        currentSupply: HP(selectedMeme.pointSupply),
      };

      const pointAmount = getCoinAmountForPointSell(
        sellTx.currentSupply,
        sellTx.pointAmount,
      );

      const tokenAmount = amount ?? HP(0);
      const receive = this.txCurrency === 'points' ? pointAmount : tokenAmount;

      this.state.tx = {
        txType: this.purchaseMode,
        receive,
        send: sellTx.pointAmount,
        isValid:
          this.txCurrency === 'points'
            ? getIsPointsSellTxValid(this.app.state, selectedMeme.id, sellTx)
            : true,
      };
    }
  };

  private updateSwapTx = (amount?: HighPrecision) => {
    amount = amount ?? HP(0);

    this.state.tx = {
      txType: 'sell',
      send: amount,
      // keep previously until updated
      receive: this.state.tx?.receive ?? HP(0),
      isValid: true,
      isNonGemzSwap: true,
    };
  };

  private getNotification = async () => {
    // alternating between new offchainTokens and last transaction
    const field =
      this.state.notifCount % 2 === 0 ? 'lastTx.createdAt' : 'availableAt';
    this.state.notifCount += 1;

    const notification = await this.app.asyncGetters.getHotMeme({
      field,
      unwantedTokenIds: this.state.pastNotificationIds,
    });

    if (this.notifScheduler === null) {
      // was deactivated during the fetch
      return;
    }

    this.state.notification = notification;

    if (notification !== undefined) {
      this.state.pastNotificationIds.push(notification.id);

      // The notification will loop through at least 16 notifications
      // in theory updates to cards should happen so frequently that loops wont happen
      // if not enough items exists, then notifications do not even loop
      const minNotifLoop = 16;
      if (this.state.pastNotificationIds.length > minNotifLoop) {
        this.state.pastNotificationIds = this.state.pastNotificationIds.slice(
          this.state.pastNotificationIds.length - minNotifLoop,
        );
      }
    }

    this.sendEvent(MemesEvents.TradingOnTxUpdate);

    const timeVariation = 1.0;
    const delay =
      MemeNotifDelay * (1 - timeVariation / 2 + timeVariation * Math.random());
    this.notifScheduler = setTimeout(() => this.getNotification(), delay);
  };

  private scheduleNotifications = () => {
    this.state.notifCount = 0;
    this.notifScheduler = setTimeout(
      () => this.getNotification(),
      MemeNotifDelay,
    );
  };

  public setNotificationActivity = (active: boolean) => {
    if (active) {
      if (this.notifScheduler !== null) {
        // already active
        return;
      }

      this.scheduleNotifications();
      return;
    }

    if (this.notifScheduler !== null) {
      // active, deactivate
      this.state.notifCount = -1;
      clearTimeout(this.notifScheduler);
      this.notifScheduler = null;
    }
  };

  public setMaxSlippage = (value: number) => {
    this.app.invoke.setMaxSlippage({ value });
    this.sendEvent(MemesEvents.TradingOnTxUpdate);
  };

  public refetchPortfolio = async (
    forceUpdate: Optional<'forceUpdate'> = undefined,
  ) => {
    if (forceUpdate || !this.memeFetchedAtLeastOnce) {
      // await this.app.memes.myMemes.refreshTargetList('Owned');
      this.app.profile.updateWalletHoldings();

      this.memeFetchedAtLeastOnce = true;
    }

    const portfolioValueBefore = this.state.portfolioValue;
    const portfolioValue = this.app.profile.getPortfolioValue(
      this.app.state.id,
    );

    const hasChanged = portfolioValueBefore !== portfolioValue;

    this.state.portfolioValue = portfolioValue;

    if (hasChanged) {
      this.sendEvent(MemesEvents.TradingOnPortfolioUpdate);
    }
  };

  public onTokenCreated = () => {
    this.state.purchaseMode = 'buy';
    this.updateTx({
      id: 'not_used_for_buy',
      pointSupply: HP(0).toString(),
    });
  };

  // ================================================
  // Public Getter Methods
  // ================================================

  public getHolders = async (): Promise<TradingTokenHolder[]> => {
    const currentMeme = this.app.memes.currentMeme.meme;
    if (!currentMeme) {
      return [];
    }

    const holders = await this.app.asyncGetters.getTopHolders({
      memeId: currentMeme.id,
    });

    // using player state to display player's holder status in tab,
    // this fixes update
    const userHolder: TradingTokenHolder[] = [];
    const memeHoldings = getWalletHoldings(
      this.app.state,
      this.app.ton.walletAddress,
    );
    const ownedToken = memeHoldings[currentMeme.id];
    if (ownedToken && HP(ownedToken.tokenAmount).gt(0)) {
      userHolder.push({
        userId: this.app.state.id,
        tokenAmount: HP(ownedToken.tokenAmount),
        tokenMarketShare: getPct(
          HP(ownedToken.tokenAmount).toNumber(),
          HP(currentMeme.tokenSupply).toNumber(),
        ),
        image: this.app.state.profile.photo,
        name: this.app.state.profile.name,
        type:
          this.app.state.id === currentMeme.creatorId ? 'creator' : 'normal',
      });
    }

    const otherTokenHolders = holders
      .filter((holder) => {
        return holder.userId !== this.app.state.id;
      })
      .map((holder) => {
        const tokenAmount = HP(holder.tokenAmount);

        return {
          userId: holder.userId,
          tokenAmount: tokenAmount,
          tokenMarketShare: getPct(
            tokenAmount.toNumber(),
            HP(currentMeme.tokenSupply).toNumber(),
          ),
          image: holder.userImage,
          name: holder.userName,
          // type: holder.isCreator ? 'creator' : 'normal',
        };
      }) as TradingTokenHolder[];

    return userHolder.concat(otherTokenHolders);
  };

  public getTxFeePercentage = () => {
    if (!this.state.tx) {
      return -1;
    }
    return txConfig.fee;
  };

  /**
   *
   * @returns Integer
   */
  public getTxFee = () => {
    if (!this.state.tx) {
      return {
        feePercentage: 0,
        feeAmount: 0,
      };
    }

    const { txType, send, receive } = this.state.tx;
    const isBuy = txType === 'buy';

    const price = isBuy ? send : receive;

    const feePercentage = txConfig.fee;
    const basePrice = price.div(1 + feePercentage);
    const txFee = Math.ceil(price.minus(basePrice).toNumber());

    return {
      feePercentage: feePercentage,
      feeAmount: txFee,
    };
  };

  public getOnchainTokenPricePoints = (
    meme: Meme,
    sliceConfig: SliceConfig | null,
  ) => {
    // console.error('meme', meme)
    // const listing = this.listingByCategory[this.listingCategory]?.listing || [];
    // const memeSearch = listing.find((token) => {
    //   return token.offchainTokenId === meme.id;
    // })
    // console.error('meme search res', memeSearch)
    const pricePoints = getOnchainTokenPricePoints(meme, sliceConfig);
    return pricePoints;
  };

  public getPortfolioPricePoints = async (sliceConfig: SliceConfig | null) => {
    await this.app.memes.myMemes.refreshTargetList('Owned');

    return getPortfolioPricePoints(
      this.app.state,
      sliceConfig,
      this.app.memes.myMemes.getList('Owned').getSearchResults(),
    );
  };

  public getOffchainMemeHoldCount = () => {
    return getOffchainMemeHoldCount(this.app.state);
  };

  public getPortfolioRoi = () => {
    return this.app.profile.getPortfolioRoi(this.app.state.id);
  };

  public getOffchainMemeHoldingStats = (
    offchainTokenId: string,
    ownedOffchainMeme: OwnedOffchainMeme,
  ) => {
    return getOffchainMemeHoldingStats(
      offchainTokenId,
      this.app.memes.myMemes.getList('Owned').getSearchResults(),
      ownedOffchainMeme,
    );
  };

  private getBuyAmountReceived = () => {
    if (this.isTxCurrencyTokens) {
      return this.getEstimatedTonToTokens();
    }
    return this.getTxReceive();
  };

  private getSellAmountReceived = () => {
    if (this.txCurrency === 'tokens') {
      return this.getEstimatedTokensToTon();
    }
    return this.getTxReceive();
  };

  private getEstimatedTonToTokens = async () => {
    const sendStr = this.tx?.send.toString();
    if (!sendStr) {
      return HP(0);
    }

    const hasDexContract = this.txMeme && this.isTxDexListed;
    if (hasDexContract) {
      const tokensReceived = await this.app.ton.getDexTonToTokens({
        meme: this.txMeme!,
        tokenAmount: HP(0),
        tonAmount: sendStr,
      });
      return HP(tokensReceived);
    }

    const hasJettonOnchain = this.isTxMinted && this.txJettonContractAddress;
    if (hasJettonOnchain) {
      const tokensReceived = await this.app.ton.getEstimatedTonToTokens(
        this.txJettonContractAddress,
        sendStr,
      );
      return HP(tokensReceived.toString());
    } else {
      const tokensReceived = this.app.ton.getEstimatedListingTokens(sendStr);
      return HP(tokensReceived.toString());
    }
  };

  private getEstimatedTokensToTon = async () => {
    const sendStr = this.tx?.send.toString();
    if (this.tx?.isNonGemzSwap) {
      const sendInteger = Math.floor(Number(sendStr));
      const sendBigInt = BigInt(sendInteger);

      const tonReceived = await this.app.ton.getDexTokensToTon({
        dexContractAddress: this.app.ton.getFriendlyAddress(
          this.swappableToken.jetton.address,
        ),
        tokenAmount: HP(sendBigInt),
        tonAmount: HP(0).toString(),
      });
      return HP(fromNano(tonReceived));
    }

    const meme = this.app.memes.currentMeme.meme;
    if (!meme) {
      return HP(0);
    }

    const isMinted = meme.isMinted;
    const contractAddress = meme.jettonContractAddress;
    if (!isMinted || !contractAddress || !sendStr) {
      return HP(0);
    }

    // Convert to integer by removing decimal places before converting to BigInt
    const sendInteger = Math.floor(Number(sendStr));
    const sendBigInt = BigInt(sendInteger);

    const hasDexContract = this.txMeme && this.isTxDexListed;
    if (hasDexContract) {
      const tonReceived = await this.app.ton.getDexTokensToTon({
        memeId: this.txMeme!.id,
        dexContractAddress: this.txMeme!.dexContractAddress!,
        tokenAmount: HP(sendBigInt),
        tonAmount: HP(0).toString(),
      });
      return HP(fromNano(tonReceived));
    }

    const tonReceived = await this.app.ton.getEstimatedTokensToTon(
      contractAddress,
      sendBigInt,
    );
    return HP(fromNano(tonReceived));
  };

  private convertTonToUsd = async (ton: string | bigint) => {
    const hpTonPerTokens = HP(fromNano(ton));
    const usdValue = await this.app.ton.getTonToUSD(hpTonPerTokens.toString());
    const roundedValue = Math.floor(Number(usdValue) * 100) / 100;
    return HP(roundedValue.toString());
  };

  private getTxReceive = () => {
    return this.tx?.receive ?? HP(0);
  };

  public getTxEstimate = async () => {
    if (!this.tx) {
      return HP(0);
    }

    if (this.tx.txType === 'buy') {
      return this.getBuyAmountReceived();
    }

    return this.getSellAmountReceived();
  };

  public async getBalance(memeId: string) {
    const isPointsCurrency = this.txCurrency === 'points';
    const isBuyTransaction = this.tx?.txType === 'buy';
    console.log('getMemeBalance1', { isPointsCurrency, isBuyTransaction });
    if (isPointsCurrency) {
      return isBuyTransaction
        ? this.app.state.balance
        : this.app.memes.currentMeme.offchainHoldings?.pointAmount ?? 0;
    }

    // Token currency case
    return isBuyTransaction
      ? this.app.ton.getBalance()
      : this.app.ton.getMemeBalance(memeId);
  }

  public getGraduationClaimAmount(
    isMemeGraduationComplete: boolean,
    memeId: string,
  ) {
    if (!isMemeGraduationComplete) {
      return;
    }

    const state = this.app.state;
    const walletAddress = this.app.ton.walletAddress;
    if (!walletAddress) {
      return;
    }

    const wallet = state.trading.onchain.wallets[walletAddress];
    if (!wallet) {
      return;
    }

    const memeHoldingsStatus = wallet.memeHoldings[memeId];
    if (!memeHoldingsStatus) {
      return;
    }

    const graduationClaimTime = memeHoldingsStatus.graduationClaimTime;
    if (graduationClaimTime) {
      if (this.app.now() - graduationClaimTime < claimLockDuration) {
        // already claiming
        return;
      }

      // reset the graduation claim time to allow the player to try again
      // note that this should not be necessary and this is just in case there is a bug in the watcher's logic
      this.app.invoke.setGraduationClaimTime({
        memeId,
        walletAddress,
        reset: true,
      });
    }

    return memeHoldingsStatus.jettonTokenAmount;
  }

  // @TODO (refactor): Not sure if "claim" belongs in Trading, I think it would be a job for MemesController
  public async claimTokens(memeId: string, memeName: string, ticker: string) {
    try {
      const walletAddress = this.app.ton.walletAddress;
      if (!walletAddress) {
        throw new Error('Wallet not connected');
      }

      // re-fetch meme
      const meme = await this.app.memes.getMeme(memeId, 'fetch');
      if (!meme) {
        throw new Error(`Meme ${memeId} not found`);
      }

      this.app.ui.addClaimingMeme(meme.id);

      let graduationClaimAmount = getGraduationClaimAmount(
        meme.isGraduated,
        this.app.state,
        memeId,
        walletAddress,
      );

      let tokenAmountToClaim;
      let convertedPoints;
      let claimType: ClaimType;
      if (HP(graduationClaimAmount).gt(0)) {
        claimType = 'graduateClaimFromJettons';
        tokenAmountToClaim = graduationClaimAmount;

        this.app.invoke.setGraduationClaimTime({
          walletAddress,
          memeId,
          reset: false,
        });
      } else {
        const memeHolding = this.app.state.trading.offchainTokens[memeId];
        if (canClaimGraduationPoints(this.app.now(), memeHolding)) {
          if (isGradTokensFromPointsBeingClaimed(this.app.now(), memeHolding)) {
            this.app.ui.removeClaimingMeme(meme.id);
            throw new Error(
              `Daily tokens already being claimed, memeId: ${memeId}`,
            );
          }

          claimType = 'graduateClaimFromPoints';
          tokenAmountToClaim = memeHolding.claimableGradTokens;
          convertedPoints = memeHolding.convertedGradPoints;

          if (HP(tokenAmountToClaim).lte(0)) {
            this.app.ui.removeClaimingMeme(meme.id);
            throw new Error(`No token to claim, memeId: ${memeId}`);
          }

          this.app.invoke.initiateGradPointClaim({
            memeId,
          });
        } else {
          if (isDailyTokenBeingClaimed(this.app.now(), memeHolding)) {
            this.app.ui.removeClaimingMeme(meme.id);
            throw new Error(
              `Daily tokens already being claimed, memeId: ${memeId}`,
            );
          }

          claimType = 'dailyClaim';
          tokenAmountToClaim = memeHolding?.claimableDailyTokens;
          convertedPoints = memeHolding.convertedDailyPoints;

          if (HP(tokenAmountToClaim).lte(0)) {
            this.app.ui.removeClaimingMeme(meme.id);
            throw new Error(`No token to claim, memeId: ${memeId}`);
          }

          this.app.invoke.initiateDailyTokenClaim({
            memeId,
          });
        }
      }

      this.sendEvents(MemesEvents.TokenClaimUpdate);
      this.app.track('token_claim_start', {
        memecard_name: memeName,
        cardID: memeId,
        ticker: ticker,
        points_redeemed: convertedPoints,
        amount_received: tokenAmountToClaim,
        type: claimType,
      });

      const { asyncConfirm } = await this.app.ton.claimDexToken(
        {
          id: memeId,
          name: meme.name,
          description: meme.description.description,
          ticker: meme.ticker,
          image: meme.image,
        },
        this.app.state.id,
        claimType,
      );
      asyncConfirm.then(() => {
        this.app.ui.removeClaimingMeme(meme.id);
      });

      this.app.track('token_claim_confirm', {
        memecard_name: memeName,
        cardID: memeId,
        ticker: ticker,
        points_redeemed: convertedPoints,
        amount_received: tokenAmountToClaim,
        type: claimType,
      });

      // show claim success drawer + confetti
      this.app.ui.confetti.show();
      this.app.ui.drawer.show({
        id: 'drawerTradingClaimSuccess',
        onClose: () => this.app.ui.confetti.hide(),
        props: { meme: meme, claimedTokens: tokenAmountToClaim },
      });
      // ---
    } catch (error) {
      this.app.ui.showError({
        message: (error as Error).message,
      });
    }
  }

  public initiateSwap = async (swappableToken: WalletJetton) => {
    this.setCurrency('tokens');
    this.selectedSwappableToken = swappableToken;
    this.swappingNonGemzToken = true;
    this.state.purchaseMode = 'sell';

    this.updateSwapTx(getJettonBalance(swappableToken));

    this.app.ui.drawer.show({
      id: 'drawerTiktokSwap',
      hideClose: true,
      onClose: () => {
        this.endSwap();
      },
    });
  };

  public endSwap = () => {
    this.swappingNonGemzToken = false;
  };
}
