import { useTranslation } from 'react-i18next';
import { assets } from '../../assets/assets';
import './LocalizedTooltip.scss';

export const LocalizedTooltip = ({
  i18nKey,
  onClose,
}: {
  i18nKey: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-generic">
      <div
        className="tooltip-generic-close"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <img src={assets.button_x_dark} width={20} height={20} />
      </div>

      <div className="tooltip-tail" />

      <div className="tooltip-generic-content">
        <div className="tooltip-generic-label">{t(i18nKey)}</div>
      </div>
    </div>
  );
};
