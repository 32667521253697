import './ProgressBar.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { motion } from 'motion/react';

interface ProgresBarProps {
  progress: number;
  caption?: string;
  height?: number;
  color?: string;
  onComplete?: () => void;
}

export const ProgressBar = ({
  progress,
  caption,
  height,
  color,
  onComplete,
}: ProgresBarProps) => {
  const { t } = useTranslation();

  height = height || 10;
  color = color || 'white';

  if (progress > 100) progress = 100;

  // progress
  const [percentageComplete, setPercentageComplete] = useState(progress);

  useMemo(() => {
    setPercentageComplete(progress);
    // console.warn('>>> ProgressBar', progress);
  }, [progress]);

  // render

  return (
    <div className="progress-bar">
      <div className="progress-bar-bg" style={{ height: `${height}px` }} />
      <motion.div
        className={`progress-bar-value ${color}`}
        style={{ height: `${height}px` }}
        //
        animate={{ width: `${percentageComplete}%` }}
        transition={{ duration: 0.25, delay: 0 }}
        onAnimationComplete={() => {
          if (progress >= 100) {
            onComplete && onComplete();
          }
        }}
      ></motion.div>
    </div>
  );
};

// ===================================================================================
